import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import i18n from './components/i18n/Translates/Translates';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PwaProvider} from "./components/pwa-context/PwaContext";
import DeviceCheckProvider from "./components/device-check-provider/DeviceCheckProvider";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18n}>
          <Router>
              <PwaProvider>
                  <DeviceCheckProvider>
                      <App />
                  </DeviceCheckProvider>
              </PwaProvider>
          </Router>
      </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
