import {useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {Line} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import getSymbolFromCurrency from "currency-symbol-map";
import {useTranslation} from "react-i18next";

import DashboardClient from "../../../../components/api/client/account/dashboard/Dashboard";
import Loader from "../../../../components/loader/Loader";
import {FireNotification} from "../../../../components/notification/Notification";

let requestsState = [];

const Dashboard = () => {
    const navigate = useNavigate();
    const dashboardRequest = 'dashboardRequest';
    const [loader, setLoader] = useState(Loader({
        fullScreen: false,
        targetClass: 'DashboardData',
    }));
    const [title, setTitle] = useState('Dashboard');
    const [message, setMessage] = useState('');
    const [chartHasData, setChartHasData] = useState(false);
    const chartRef = useRef(null);
    const [chartCurrencySymbol, setChartCurrencySymbol] = useState('');
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });
    const { t } = useTranslation();

    const chartOptions = {
        scales: {
            y: {
                suggestedMin: 0,
                beginAtZero: true,
                ticks: {
                    callback: function (value, index, values) {
                        return `${chartCurrencySymbol} ${value}`;
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }

                        return `${label} ${chartCurrencySymbol} ${context.formattedValue}`;
                    },
                },
            },
        },
    };

    useEffect(() => {
        setTitle(t('title.office.dashboard'));

        if (!requestsState[dashboardRequest]) {
            requestsState[dashboardRequest] = true;
            DashboardClient()
                .then((response) => {
                    if (response.data.result && response.data.result === true) {
                        if (response.data.statistic) {
                            setChartHasData(true);
                            setChartCurrencySymbol(getSymbolFromCurrency(response.data.statistic.currency));
                            setChartData({
                                labels: response.data.statistic.periods,
                                datasets: response.data.statistic.items,
                            });
                        }
                    } else {
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    }
                })
                .finally(() => {
                    setLoader(null);
                    requestsState[dashboardRequest] = false;
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (chartRef.current && chartRef.current.chartInstance) {
            const chartInstance = chartRef.current.chartInstance;

            chartInstance.destroy();

            chartRef.current.chartInstance = new Chart(chartRef.current, {
                type: 'line',
                data: chartData,
            });
        }
    }, [chartData]);

    useEffect(() => {
        if (message) {
            FireNotification(message, false);
            setMessage('');
        }
    }, [message]);

    return (
        <div className="DashboardWrapper" style={{position: "relative"}}>
            <h2 className="TabTitle">{title}</h2>
            <br/>
            <div className="DashboardData">
                {loader && loader}
                <h3 style={{color: "white"}}>{t('office.dashboard.by.qr.subtitle')}</h3>
                {!chartHasData && <div className="NoData">{t('office.dashboard.no.data')}</div>}
                {chartHasData && (
                    <div style={{margin: '0 10px'}}>
                        <Line ref={chartRef} data={chartData} options={chartOptions}/>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Dashboard;
