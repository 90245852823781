export const BranchType = {
    Other: 1,
};

const TypeLabels = {
    [BranchType.Other]: 'office.branches.type.other.label',
};

export function GetBranchTypeList() {
    return Object.values(BranchType);
}

export function IsBranchType(value) {
    return Object.values(BranchType).includes(value);
}

export function GetBranchTypeLabel(value) {
    return TypeLabels[value] || null;
}
