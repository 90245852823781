import Button from "../../button/Button";

import "./PromoBanner.css";

const PromoBanner = ({
    imageUrl,
    title,
    description,
    isNative,
    isCustom,
    onClick,
}) => {
    return (
        <div className="PbWrapper">
            {isCustom && (
                <div className="PbCustomSection">
                    <div className="PbCustomBody">
                        <a href="/" onClick={onClick}>
                            <img src={imageUrl} className="PbCustomImage" alt="pb offer" />
                        </a>
                    </div>
                </div>
            )}
            {isNative && (
                <div className="PbNativeSection">
                    <div className="PbNativeLeft">
                        <div className="PbNativeImageBlock">
                            <img src={imageUrl} className="PbNativeImage" alt={title} />
                        </div>

                        <div className="PbNativeContentBlock">
                            <div className="PbNativeTitle">
                                {title}
                            </div>

                            <div className="PbNativeDescription">
                                {description}
                            </div>
                        </div>
                    </div>

                    <div className="PbNativeRight">
                        <Button
                            className="PbNativeButton"
                            text={'View'}
                            onClick={onClick}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PromoBanner;
