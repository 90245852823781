import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

// @ts-ignore
import { PaymentMethod } from "./Method.ts"
// @ts-ignore
import { Card } from "./factories/Card.tsx";
// @ts-ignore
import { UnsupportedPaymentDataException } from "./Exception.ts";

export interface PaymentDataFactory {
    render(): ReactJSXElement;
    validate(): boolean;
    getState(): object;
    getMethod(): PaymentMethod;
    reset(): void;
}

export function Make(method: PaymentMethod): PaymentDataFactory {
    switch (method) {
        case PaymentMethod.Card:
            return new Card();
        default:
            throw new UnsupportedPaymentDataException();
    }
}
