import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie-consent';
import { useLocation } from 'react-router-dom';
import {useTranslation} from "react-i18next";

import {usePwa} from "../pwa-context/PwaContext";

import './PwaInstallPopup.css';

const pwaCookieName = 'PwaBannerDismissed';
const pwaCookieExpiresDays = 90;

const toggleBodyScroll = (disableScroll) => {
    document.body.style.overflow = disableScroll ? 'hidden' : '';
};

const PwaInstallPopup = () => {
    const [isBannerVisible, setIsBannerVisible] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const location = useLocation();
    const { isInstallable, promptInstall } = usePwa();
    const { t } = useTranslation();

    useEffect(() => {
        if (Cookies.get(pwaCookieName)) {
            setIsBannerVisible(false);
            return;
        }

        if (location.pathname.startsWith('/office') && isInstallable) {
            setTimeout(() => {
                setIsBannerVisible(true);
                setIsAnimating(true);
            }, 1000);
        } else {
            setIsBannerVisible(false);
        }
    }, [location.pathname, isInstallable]);

    useEffect(() => {
        toggleBodyScroll(isBannerVisible);
        return () => toggleBodyScroll(false);
    }, [isBannerVisible]);

    const handleInstallClick = async () => {
        await promptInstall();
        hideBanner(true);
    };

    const handleDismissClick = () => {
        hideBanner(false);
    };

    const hideBanner = (isAccept = false) => {
        setIsAnimating(false);
        setTimeout(() => {
            Cookies.set(pwaCookieName, 'true', { expires: pwaCookieExpiresDays });
            setIsBannerVisible(false);
        }, 300);
    };

    if (!isInstallable) return null;

    return (
        <div
            className={`PwaInstallerNotificationWrapper ${isAnimating ? 'visible' : ''}`}
            role="dialog"
        >
            {isBannerVisible && (
                <div className="PwaInstallerNotificationSection">
                    <div className="PwaInstallerNotificationContent">
                        <div className="PwaInstallerTitleWrapper">
                            <span>
                                {t('office.pwa.install.popup.title')}
                            </span>
                        </div>
                        <div className="PwaInstallerDescriptionWrapper">
                            <span>
                                {t('office.pwa.install.popup.description')}
                            </span>
                        </div>
                        <div className="PwaInstallerButtonsWrapper">
                            <button onClick={handleInstallClick}>
                                {t('office.pwa.install.popup.button.install')}
                            </button>
                            <button onClick={handleDismissClick} className="dismiss-btn">
                                {t('office.pwa.install.popup.button.skip')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PwaInstallPopup;
