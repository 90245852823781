import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";

import Button from "../../../components/button/Button";
import Loader from "../../../components/loader/Loader";
import RegisterResendConfirm from "../../../components/api/client/auth/register/resend-confirm/RegisterResendConfirm";
import {FireNotification} from "../../../components/notification/Notification";

import './RegisterConfirm.css';

const timerSeconds = 60;

const RegisterConfirm = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { email } = location.state || {};
    const [timer, setTimer] = useState(timerSeconds);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [loader, setLoader] = useState(null);

    useEffect(() => {
        if (!email) {
            navigate('/404', { replace: true });
        }
    }, [email, navigate]);

    useEffect(() => {
        document.title = t('title.register.resend.confirm');
        setIsSuccess(true);
        setMessage(t('register.success.message'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        if (timer === 0) {
            setIsButtonDisabled(false);
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [timer]);

    useEffect(() => {
        if (message) {
            FireNotification(message, isSuccess);
            setMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    const handleResendEmail = (e) => {
        e.preventDefault();

        setLoader(Loader());
        RegisterResendConfirm(email)
            .then((response) => {
                if (response.data.code === 400) {
                    setIsSuccess(false);
                    setMessage(t(response.message));
                } else if (response.data.code !== 200) {
                    setIsSuccess(false);
                    setMessage(t('something.went.wrong.error'));
                }
            })
            .catch(error => {
                setIsSuccess(false);
                setMessage(t('something.went.wrong.error'));
            })
            .finally(() => {
                setLoader(null);
                setIsButtonDisabled(true);
                setTimer(timerSeconds);
            });
    };

    return (
        <div className="RegisterConfirmSection">
            {loader && loader}
            <h1>{t('title.register.resend.confirm')}</h1>
            <div>
                <Button
                    text={t('register.resend.confirm.button')}
                    onClick={handleResendEmail}
                    disabled={isButtonDisabled}
                />
            </div>
            <div className="RegisterConfirmTimerWrapper">
                {timer > 0 && (<p>{t('register.resend.confirm.timer.message', {leftSeconds: timer})}</p>)}
            </div>
        </div>
    );
}

export default RegisterConfirm;
