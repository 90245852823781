import axios from "axios";
import AppEnv from "../../app-env/AppEnv.ts";

const baseUrl = AppEnv.REACT_APP_API_URL;
const token = AppEnv.REACT_APP_API_TOKEN;
const timeout = AppEnv.REACT_APP_API_TIMEOUT;

const Client = (authPayload) => {
    const client = axios.create({baseURL: baseUrl});
    client.defaults.timeout = timeout;
    client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    if (authPayload) {
        client.defaults.headers.common['payload'] = authPayload;
    }

    return client;
};

export default Client;
