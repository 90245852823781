import './Button.css';

const Button = (props) => {
    const { text, ...rest } = props;

    return (
        <div className="Button">
            <button {...rest} >
                {text}
            </button>
        </div>
    );
}

export default Button;
