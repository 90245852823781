import React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PasswordField from "../field/password-field/PasswordField";
import Button from "../../button/Button";
import ChangePassword from "../../api/client/auth/change-password/ChangePassword";
import Loader from "../../loader/Loader";
import {FireNotification} from "../../notification/Notification";

import './ChangePasswordForm.css';

const recoveryCodeMinLength = 24;
const recoveryCodeMaxLength = 64;
const passwordMinLength = 8;
const passwordMaxLength = 64;

class ChangePasswordForm extends React.Component {
    state = {
        password: "",
        confirmPassword: "",
        passwordError: "",
        confirmPasswordError: "",
        message: "",
        isSuccess: null,
        loader: null,
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.message && prevState.message !== this.state.message) {
            FireNotification(this.state.message, this.state.isSuccess);
            this.setState({message: ''});
        }
    };

    getRecoveryError = recoveryCode => {
        if (!validateRecoveryCode(recoveryCode)) {
            return this.props.t('change.password.invalid.link');
        }

        return "";
    };

    getPasswordError = password => {
        const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\s:])(\S)+$/;
        if (!passwordRegex.test(password)) {
            return this.props.t('change.password.invalid.password.field.message');
        }

        if (password.length < passwordMinLength) {
            return this.props.t('change.password.password.min.length.field.message', {minLength: passwordMinLength});
        }

        if (password.length > passwordMaxLength) {
            return this.props.t('change.password.password.max.length.field.message', {maxLength: passwordMaxLength});
        }

        return "";
    };

    checkEqualPasswords = (password, confirmPassword) => {
        return password === confirmPassword;
    };

    validateRecoveryCode = (value) => {
        const error = this.getRecoveryError(value);
        this.setState({message: error});

        return !error;
    };

    validatePassword = (value) => {
        const error = this.getPasswordError(value);
        this.setState({passwordError: error});

        return !error;
    };

    validateConfirmPassword = (value, password) => {
        let error = "";
        if (!this.checkEqualPasswords(password ? password : this.state.password, value)) {
            error = this.props.t('change.password.passwords.not.equals.field.message');
        }
        this.setState({confirmPasswordError: error});

        return !error;
    };

    onChangePassword = (event) => {
        this.setState({password: event.target.value});
        this.validatePassword(event.target.value);

        if (this.state.confirmPassword !== '') {
            this.validateConfirmPassword(this.state.confirmPassword, event.target.value);
        }
    };

    onChangeConfirmPassword = (event) => {
        this.setState({confirmPassword: event.target.value});
        this.validateConfirmPassword(event.target.value);
    };

    onSubmit = (e) => {
        e.preventDefault();

        const { recoveryCode } = this.props

        if (
            !this.validateRecoveryCode(recoveryCode)
            || !this.validatePassword(this.state.password)
            || !this.validateConfirmPassword(this.state.confirmPassword)
        ) {
            return;
        }

        this.setState({loader: Loader()});
        ChangePassword({
            recoveryCode: recoveryCode,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
        })
            .then((response) => {
                if (response.data.code === 200 && response.data.result === true) {
                    this.setState({
                        isSuccess: true,
                        message: this.props.t('change.password.success.message'),
                        password: '',
                        confirmPassword: '',
                    });
                    e.target.reset();
                } else if (response.data.code === 400) {
                    if (response.data.details.password) {
                        this.setState({passwordError: this.props.t(response.data.details.password)});
                    }
                } else {
                    this.setState({emailError: this.props.t('something.went.wrong.error')});
                }
            })
            .catch(error => {
                this.setState({emailError: this.props.t('something.went.wrong.error')});
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    render() {
        return (
            <div className="ChangePasswordForm">
                {this.state.loader && this.state.loader}
                {this.state.isSuccess
                    && <NavLink
                        style={{
                            color: "#DBFC08",
                            margin: "10px",
                            textDecoration: "none",
                        }}
                        to="/auth/login"
                    >
                        {this.props.t('change.password.login.link')}
                    </NavLink>
                }

                <form onSubmit={this.onSubmit}>
                    <PasswordField
                        id="password"
                        name="password"
                        autoComplete="new-password"
                        label={this.props.t('change.password.password.field.label')}
                        value={this.state.password}
                        error={this.state.passwordError}
                        onChange={this.onChangePassword}
                        required
                    />
                    <PasswordField
                        id="confirmPassword"
                        name="confirmPassword"
                        autoComplete="new-password"
                        label={this.props.t('change.password.confirm.password.field.label')}
                        value={this.state.confirmPassword}
                        error={this.state.confirmPasswordError}
                        onChange={this.onChangeConfirmPassword}
                        required
                    />

                    <Button text={this.props.t('office.system.form.submit.button')} />
                </form>
            </div>
        );
    }
}

export const validateRecoveryCode = recoveryCode => {
    const recoveryCodeRegex = /^[a-z0-9]+$/

    return !(!recoveryCodeRegex.test(recoveryCode)
        || recoveryCode.length < recoveryCodeMinLength
        || recoveryCode.length > recoveryCodeMaxLength);
};

export default withTranslation()(ChangePasswordForm);
