import Client from "../../Client";
import {GetAuthToken} from "../../../../storages/auth-token-storage/AuthTokenStorage";

const BranchEmployeeLeft = (employeeId) => {
    return Client(GetAuthToken()).post(
        `/account/branch-employee/left`,
        {
            employeeId: employeeId,
        },
    );
}

export default BranchEmployeeLeft;
