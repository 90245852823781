import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Navigate} from "react-router";
import {useHref} from "react-router-dom";

import {GetAccInfo} from "../../../components/storages/account-info-storage/AccountInfoStorage";
import SystemPM from "../../../components/system-pm/SystemPM";
import PrevButton from "../../../components/prev-button/PrevButton";
import Loader from "../../../components/loader/Loader";
import PromoBannerOverview from "../../../components/api/client/account/promo-banner/PromoBannerOverview";
import {FireNotification} from "../../../components/notification/Notification";

import "./PromoBanner.css";

let requestsState = [];

const PromoBanner = () => {
    const overviewRequest = 'overviewRequest';
    const [redirect, setRedirect] = useState(null);
    const [overview, setOverview] = useState( null);
    const [message, setMessage] = useState( '');
    const [isSuccess, setIsSuccess] = useState( null);
    const [loader, setLoader] = useState(Loader());
    const { t } = useTranslation();
    const supportLink = useHref('/support');

    useEffect(() => {
        document.title = t('title.office.promo.banner');

        const accInfo = GetAccInfo();
        if (!accInfo?.isBusiness) {
            setRedirect('/404');
        } else {
            if (!requestsState[overviewRequest]) {
                requestsState[overviewRequest] = true;
                fetch();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message) {
            FireNotification(message, isSuccess);
            setMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    const fetch = () => {
        !loader && setLoader(Loader());
        PromoBannerOverview()
            .then((response) => {
                if (response.status === 200) {
                    setOverview(response.data.result);
                } else {
                    setIsSuccess(false);
                    setMessage(t('something.went.wrong.error'));
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirect('/auth/logout');
                }
                setIsSuccess(false);
                setMessage(t('something.went.wrong.error'));
            })
            .finally(() => {
                requestsState[overviewRequest] = false;
                setLoader(null);
            });
    };

    return (
        <div className="PromoBanners">
            {loader && loader}
            {redirect && <Navigate to={redirect} />}
            <PrevButton route={`/office/settings`} />
            <h1>{t('title.office.promo.banner')}</h1>
            <br/>

            <div className="SystemNotifySection">
                <SystemPM
                    html={t('office.promo.banner.creation.tip.message', {
                        link: `<a href="${supportLink}">${t('office.promo.banner.creation.tip.link.text')}</a>`,
                    })}
                />
            </div>

            {overview && overview.isActiveFeature && (
                <div className="PromoBannersStatsWrapper">
                    <h2>
                        {t('office.promo.banner.stat.subtitle')}
                    </h2>

                    {overview.stats.length > 0 ? (
                        <div className="PromoBannerTable">
                            <div className="PromoBannerRow header">
                                <div className="PromoBannerCell">
                                    {t('office.promo.banner.stat.table.branch.header')}
                                </div>
                                <div className="PromoBannerCell">
                                    {t('office.promo.banner.stat.table.views.header')}
                                </div>
                                <div className="PromoBannerCell">
                                    {t('office.promo.banner.stat.table.clicks.header')}
                                </div>
                                <div className="PromoBannerCell">
                                    {t('office.promo.banner.stat.table.ctr.header')}
                                </div>
                            </div>
                            {overview.stats.map((stat) => {
                                return (
                                    <div className="PromoBannerRow">
                                        <div className="PromoBannerCell">
                                            {stat.branchName}
                                        </div>
                                        <div className="PromoBannerCell">
                                            {stat.totalViews}
                                        </div>
                                        <div className="PromoBannerCell">
                                            {stat.totalClicks}
                                        </div>
                                        <div className="PromoBannerCell">
                                            {stat.ctr}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="PromoBannerNoData">
                            {t('office.promo.banner.no.data')}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
};

export default PromoBanner;
