import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import Loader from "../../components/loader/Loader";
import PaymentConfirmation from "../../components/api/client/payment/PaymentConfirmation";
import LandingHeaderTemplate from "../../components/template/landing-header/LandingHeaderTemplate";
import LandingPaySuccessTemplate from "../../components/template/landing-pay-success/LandingPaySuccessTemplate";
import PromoBanner from "../../components/branch/promo-banner/PromoBanner";
import PromoBannerClick from "../../components/api/client/promo-banner/PromoBannerClick";

import "./PayConfirmation.css";

let requestsState = [];

const uuidLength = 36;

const PayConfirmation = () => {
    const paymentRequest = 'paymentRequest';
    const navigate = useNavigate();
    const { uuid } = useParams();
    const [confirmation, setConfirmation] = useState( null);
    const [loader, setLoader] = useState(Loader());
    const { t } = useTranslation();

    useEffect(() => {
        function redirectTo404() {
            navigate("/404");
        }

        function getPaymentUuidError() {
            const uuidRegex = /^[a-zA-Z0-9-]+$/

            if (!uuidRegex.test(uuid) || uuid.length !== uuidLength) {
                return 'incorrect uuid';
            }

            return "";
        }

        if (!requestsState[paymentRequest]) {
            requestsState[paymentRequest] = true;

            if (getPaymentUuidError()) {
                redirectTo404();
            }

            PaymentConfirmation(uuid)
                .then((response) => {
                    if (response.status === 200 && !!response.data.result) {
                        setConfirmation(response.data.result);
                        document.title = t('title.landing.pay', {name: response.data.result.landingName});
                    } else {
                        redirectTo404();
                    }
                })
                .catch(error => {
                    redirectTo404();
                })
                .finally(() => {
                    requestsState[paymentRequest] = false;
                    setLoader(null);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onBannerClick = (targetId, e) => {
        e.preventDefault();

        if (!targetId) {
            return;
        }

        setLoader(Loader());
        PromoBannerClick(targetId)
            .then((response) => {
                if (response.status === 200 && !!response.data.result) {
                    window.open(response.data.result, '_blank', 'noopener noreferrer');
                }
            })
            .catch(error => {})
            .finally(() => {
                setLoader(null);
            })
    };

    return (
        <div className="PayConfirmationSection">
            {loader && loader}
            <LandingHeaderTemplate
                name={!!confirmation && confirmation.landingName}
                subName={!!confirmation && confirmation.landingSubName}
                logo={!!confirmation && confirmation.landingLogo}
            />
            <br/>
            {!!confirmation && <LandingPaySuccessTemplate
                text={confirmation.isExecuted ? t('pay.congratulation') : t('pay.process')}
            />}
            {!!confirmation && confirmation.promoBanner && <PromoBanner
                imageUrl={confirmation.promoBanner.imageUrl}
                title={confirmation.promoBanner.title}
                description={confirmation.promoBanner.description}
                isNative={confirmation.promoBanner.isNative}
                isCustom={confirmation.promoBanner.isCustom}
                onClick={(e) => onBannerClick(confirmation.promoBanner.targetId, e)}
            />}
        </div>
    );
};

export default PayConfirmation;
