import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import TextField from "../../../../field/text-field/TextField";
import PasswordField from "../../../../field/password-field/PasswordField";

import "./Card.css";

const formPrefix = 'card_';

const Render = (props) => {
    const { t } = useTranslation();

    const [number, setNumber] = useState(props.data.number);
    const [numberError, setNumberError] = useState("");

    const [expMonth, setExpMonth] = useState(props.data.expMonth);
    const [expMonthError, setExpMonthError] = useState("");

    const [expYear, setExpYear] = useState(props.data.expYear);
    const [expYearError, setExpYearError] = useState("");

    const [holder, setHolder] = useState(props.data.holder);
    const [holderError, setHolderError] = useState("");

    const [cvc, setCvc] = useState(props.data.cvc);
    const [cvcError, setCvcError] = useState("");

    const onChangeNumber = (event) => {
        setNumber(event.target.value);
        setNumberError(props.onChangeNumber(event.target.value));
    };

    const onChangeExpMonth = (event) => {
        setExpMonth(event.target.value);
        setExpMonthError(props.onChangeExpMonth(event.target.value));
    };

    const onChangeExpYear = (event) => {
        setExpYear(event.target.value);
        setExpYearError(props.onChangeExpYear(event.target.value));
    };

    const onChangeHolder = (event) => {
        setHolder(event.target.value);
        setHolderError(props.onChangeHolder(event.target.value));
    };

    const onChangeCvc = (event) => {
        setCvc(event.target.value);
        setCvcError(props.onChangeCvc(event.target.value));
    };

    return (
        <div className="CardWrapper">
            <TextField
                name={`${formPrefix}number`}
                label={t('card.number.field.label')}
                value={number}
                error={numberError}
                onChange={onChangeNumber}
            />

            <TextField
                name={`${formPrefix}holder`}
                label={t('card.holder.field.label')}
                value={holder}
                error={holderError}
                onChange={onChangeHolder}
            />

            <div className="MetaWrapper">
                <div className="ExpDateWrapper">
                    <div className="ExpDateLabelWrapper">
                        <span>{t('card.exp.date.field.label')}</span>
                    </div>

                    <div className="ExpDateFieldWrapper">
                        <div className="ExpMonthWrapper">
                            <TextField
                                name={`${formPrefix}exp-month`}
                                value={expMonth}
                                error={expMonthError}
                                onChange={onChangeExpMonth}
                                placeholder="MM"
                            />
                        </div>

                        <div className="ExpYearWrapper">
                            <TextField
                                name={`${formPrefix}exp-year`}
                                value={expYear}
                                error={expYearError}
                                onChange={onChangeExpYear}
                                placeholder="YY"
                            />
                        </div>
                    </div>
                </div>

                <div className="CvcWrapper">
                    <PasswordField
                        name={`${formPrefix}cvc`}
                        label={t('card.cvc.field.label')}
                        value={cvc}
                        error={cvcError}
                        onChange={onChangeCvc}
                    />
                </div>
            </div>
        </div>
    );
};

export default Render;
