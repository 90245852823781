import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";

import Loader from "../../../components/loader/Loader";
import CheckRegisterEmployee
    from "../../../components/api/client/auth/register/employee/check-register/CheckRegisterEmployee";
import RegisterEmployeeForm from "../../../components/form/register-form/employee/RegisterEmployeeForm";

const activationCodeMinLength = 24;
const activationCodeMaxLength = 64;

let requestsState = [];

const RegisterEmployee = () => {
    const { code } = useParams();
    const [loader, setLoader] = useState(Loader());
    const navigate = useNavigate();
    const [email, setEmail] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('title.register');

        function redirectTo404() {
            navigate("/404");
        }

        function getValidateError(code) {
            const valueRegex = /^[a-z0-9]+$/

            if (
                !valueRegex.test(code)
                || code.length < activationCodeMinLength
                || code.length > activationCodeMaxLength
            ) {
                return t('verify.link.expired.message')
            }

            return '';
        }

        if (!requestsState[code]) {
            requestsState[code] = true;
            if (!getValidateError(code)) {
                CheckRegisterEmployee(code)
                    .then((response) => {
                        if (
                            response.status === 200
                            && response.data.result
                            && response.data.result.isValid === true
                        ) {
                            setEmail(response.data.result.email);
                        } else {
                            redirectTo404();
                        }
                    })
                    .catch(error => {
                        redirectTo404();
                    })
                    .finally(() => {
                        requestsState[code] = false;
                        setLoader(null);
                    });
            } else {
                redirectTo404();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {loader && loader}
            <h1>{t('title.register')}</h1>
            <br/>
            <RegisterEmployeeForm
                code={code}
                email={email}
            />
        </div>
    );
}

export default RegisterEmployee;
