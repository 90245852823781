import { useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import Field from "../Field";

import './PasswordField.css';

const PasswordField = ({id, name, label, error, onChange, onBlur, autoComplete, required}) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    return (
        <div className="PasswordField">
            <Field
                {...(id ? {id} : {})}
                type={showPassword ? 'text' : 'password'}
                name={name}
                {...(autoComplete ? {autoComplete} : {})}
                label={label}
                error={error}
                onChange={onChange}
                onBlur={onBlur}
                {...(required ? {required} : {})}
            />
            <span
                className="PasswordVisibilityToggle"
                onClick={togglePasswordVisibility}
            >
                {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
            </span>
        </div>
    );
}

export default PasswordField;
