import {useEffect, useState} from "react";
import {MdDeleteOutline, MdOutlinePlace, MdOutlineStore, MdPeopleOutline} from "react-icons/md";
import {useTranslation} from "react-i18next";
import {useHref, useNavigate} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import SystemPM from "../../../components/system-pm/SystemPM";
import {FireNotification} from "../../../components/notification/Notification";
import Loader from "../../../components/loader/Loader";
import BranchList from "../../../components/api/client/account/branch/BranchList";
import BranchDelete from "../../../components/api/client/account/branch/BranchDelete";
import Button from "../../../components/button/Button";

import "./Branch.css";

const perPage = 5;

let requestsState = [];

const Branch = () => {
    const navigate = useNavigate();
    const listRequest = 'listRequest';
    const [branches, setBranches] = useState( {
        list: [],
        limit: perPage,
        offset: 0,
    });
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [message, setMessage] = useState( '');
    const [isSuccess, setIsSuccess] = useState( null);
    const [availableCreationLimit, setAvailableCreationLimit] = useState( 0);
    const [hasVerification, setHasVerification] = useState( null);
    const [loader, setLoader] = useState(Loader());
    const { t } = useTranslation();
    const verificationLink = useHref('/office/verification');

    const onCreate = () => {
        navigate(`/office/branch/create`);
    };

    const onEdit = (id) => {
        let canEdit = true;
        branches.list.forEach((branch) => {
            if (branch.id === parseInt(id) && !branch.canEdit) {
                canEdit = false;
            }
        });

        if (canEdit) {
            navigate(`/office/branch/edit/${id}`);
        }
    };

    const onView = (id) => {
        navigate(`/office/branch/${id}`);
    };

    const onDelete = (e) => {
        e.preventDefault();

        const id = e.currentTarget.getAttribute('data-id');
        let canDelete = true;
        branches.list.forEach((branch) => {
            if (branch.id === parseInt(id) && !branch.canDelete) {
                canDelete = false;
            }
        });

        if (id && canDelete) {
            setLoader(Loader());
            BranchDelete(id)
                .then((response) => {
                    if (response.status === 200 && response.data.result === true) {
                        fetch(1, true);
                        setIsSuccess(true);
                        setMessage(t('office.system.form.success.deleted.message'));
                    } else {
                        setIsSuccess(false);
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    }
                    setIsSuccess(false);
                    setMessage(t('something.went.wrong.error'));
                })
                .finally(() => {
                    setLoader(null);
                });
        }
    };

    const fetch = async (page = null, isRefresh = false) => {
        if (!requestsState[listRequest]) {
            requestsState[listRequest] = true;
            BranchList({limit: perPage, offset: (null !== page ? (perPage * (page - 1)) : (offset + perPage))})
                .then((response) => {
                    if (response.data.result.list) {
                        const newList = response.data.result.list;

                        if (isRefresh) {
                            setBranches(prevBranches => ({
                                ...prevBranches,
                                list: [...newList],
                            }));
                        } else {
                            setBranches(prevBranches => ({
                                ...prevBranches,
                                list: [...prevBranches.list, ...newList],
                            }));
                        }

                        setHasVerification(response.data.result.hasVerification);
                        setAvailableCreationLimit(response.data.result.availableCount);
                        setHasMore(newList.length === perPage);
                        setOffset(response.data.result.offset);
                    } else {
                        setMessage(t('something.went.wrong.error'));
                        setIsSuccess(false);
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    } else {
                        setMessage(t('something.went.wrong.error'));
                    }
                    setIsSuccess(false);
                })
                .finally(() => {
                    requestsState[listRequest] = false;
                });
        }
    };

    useEffect(() => {
        document.title = t('title.office.branches');

        fetch(1);
        setLoader(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message) {
            FireNotification(message, isSuccess);
            setMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return (
        <div className="BranchList">
            {loader && loader}
            <h1>{t('title.office.branches')}</h1>
            <br/>

            {(hasVerification === false) && (<div className="SystemNotifySection">
                <SystemPM
                    html={t('office.system.verify.first.message', {
                        link: `<a href="${verificationLink}">${t('office.system.verify.first.link')}</a>`
                    })}
                />
            </div>)}

            {availableCreationLimit > 0 && hasVerification && <Button
                text={t('office.branches.new.button')}
                onClick={onCreate}
            />}
            <div className="BranchListWrapper">
                <div className="BranchListItems">
                    {branches && branches.list && (
                        <InfiniteScroll
                            dataLength={branches.list.length}
                            next={fetch}
                            hasMore={hasMore}
                            loader={<div><Loader fullScreen={false} /></div>}
                            endMessage={""}
                        >
                            <div className="BranchListItemWrapper">
                                {branches.list.length > 0 ? (
                                    ((t) => {
                                        return (branches.list.map((branch) => {
                                            return (
                                                <div className="BranchItem">
                                                    <div className="BranchGeneral">
                                                        <div className="BranchInfo">
                                                            <span className="BranchName">
                                                                {branch.name}
                                                            </span>
                                                            <span className="BranchPublicName">
                                                                <MdOutlineStore /> {branch.publicName}
                                                            </span>
                                                            <span className="BranchAddress">
                                                                <MdOutlinePlace /> {branch.address}
                                                            </span>
                                                            <span className="BranchEmployees">
                                                                <MdPeopleOutline /> {branch.countEmployees}
                                                            </span>
                                                        </div>
                                                        <div className="BranchControl">
                                                            <div>
                                                                <MdDeleteOutline
                                                                    style={{
                                                                        cursor: branch.canDelete ? "pointer" : "not-allowed",
                                                                    }}
                                                                    data-id={branch.id}
                                                                    onClick={onDelete}
                                                                    disabled={!branch.canDelete}
                                                                    title={branch.canDelete
                                                                        ? t('office.content.control.delete.button')
                                                                        : t('office.content.control.cannot.delete.button')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="BranchFooter">
                                                        <Button
                                                            text={t('office.content.control.view.button')}
                                                            onClick={() => onView(branch.id)}
                                                        />
                                                        <Button
                                                            style={{
                                                                cursor: branch.canEdit ? "pointer" : "not-allowed",
                                                            }}
                                                            text={t('office.content.control.edit.button')}
                                                            onClick={() => onEdit(branch.id)}
                                                            disabled={!branch.canEdit}
                                                            title={branch.canEdit
                                                                ? t('office.content.control.edit.button')
                                                                : t('office.content.control.cannot.edit.button')}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }));
                                    })(t)
                                ) : (loader === null && branches.list !== 'undefined' && (
                                    <span className="NoData"></span>
                                ))}
                            </div>
                        </InfiniteScroll>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Branch;
