import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie-consent';
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

import './PwaInstallManualPopup.css';

const pwaCookieName = 'PwaManualBannerDismissed';
const pwaCookieExpiresDays = 90;

const toggleBodyScroll = (disableScroll) => {
    document.body.style.overflow = disableScroll ? 'hidden' : '';
};

const PwaInstallManualPopup = ({ isManualOpen = false, onClose }) => {
    const [isBannerVisible, setisBannerVisible] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if (!isManualOpen && Cookies.get(pwaCookieName)) {
            setisBannerVisible(false);
            return;
        }

        if (location.pathname.startsWith('/office') || isManualOpen) {
            setTimeout(() => {
                setisBannerVisible(true);
                setIsAnimating(true);
            }, isManualOpen ? 0 : 1000);
        }
    }, [location.pathname, isManualOpen]);

    useEffect(() => {
        toggleBodyScroll(isBannerVisible);
        return () => toggleBodyScroll(false);
    }, [isBannerVisible]);

    const handleClose = () => {
        setIsAnimating(false);
        setTimeout(() => {
            if (!isManualOpen) {
                Cookies.set(pwaCookieName, 'true', { expires: pwaCookieExpiresDays });
            }
            setisBannerVisible(false);
            if (onClose) onClose();
        }, 300);
    };

    return (
        <div className={`PwaInstallerManualNotificationWrapper ${isAnimating ? 'visible' : ''}`} role="dialog">
            {isBannerVisible && (
                <div className="PwaInstallerManualNotificationSection">
                    <div className="PwaInstallerManualNotificationContent">
                        <div className="PwaInstallerTitleWrapper">
                            <span>
                                {t('office.pwa.install.popup.title')}
                            </span>
                        </div>
                        <div className="PwaInstallerDescriptionWrapper">
                            <ol>
                                <li>
                                    {t('office.pwa.install.manual.popup.1')}
                                </li>
                                <li>
                                    {t('office.pwa.install.manual.popup.2')}
                                </li>
                                <li>
                                    {t('office.pwa.install.manual.popup.3')}
                                </li>
                            </ol>
                        </div>
                        <div className="PwaInstallerNoteWrapper">
                            <span>
                                {t('office.pwa.install.popup.description')}
                            </span>
                        </div>
                        <div className="PwaInstallerButtonsWrapper">
                            <button onClick={handleClose}>
                                {t('office.pwa.install.manual.popup.button.ok')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PwaInstallManualPopup;
