// @ts-ignore
import { PaymentData } from "../../Entity.ts";
// @ts-ignore
import { PaymentMethod } from "../../Method.ts";

export class CardPaymentData implements PaymentData {
    constructor(
        public number: string,
        public expMonth: string,
        public expYear: string,
        public holder: string,
        public cvc: string,
    ) {}

    getData(): object {
        return {
            number: this.number,
            expMonth: this.expMonth,
            expYear: this.expYear,
            holder: this.holder,
            cvc: this.cvc,
        };
    }

    getMethod(): number {
        return PaymentMethod.Card;
    }
}
