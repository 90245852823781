import Select from "react-select";

import './SelectField.css';

const SelectField = (props) => {
    return (
        <div className="SelectField">
            <div className="Field">
                <label>
                    {props.label}
                    <Select
                        {...props}
                        className="SelectFieldInput"
                        menuPlacement="bottom"
                        autosize={false}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        styles={{
                            control: base => ({
                                ...base,
                                border: "3px solid transparent",
                                "&:hover": {
                                    border: "3px solid transparent",
                                },
                                "&:focus-within": {
                                    border: "3px solid #DBFC08",
                                },
                                minWidth: "365px",
                                height: "62px",
                            }),
                            option: base => ({
                                ...base,
                                borderRadius: 0,
                                width: '100%',
                            }),
                            menu: base => ({
                                ...base,
                                borderRadius: "0 0 10px 10px",
                                backgroundColor: "#1F1F1F",
                                borderColor: "transparent",
                            }),
                            input: base => ({
                                ...base,
                                margin: -5,
                            }),
                            valueContainer: base => ({
                                ...base,
                                height: "62px",
                                left: 18,
                            }),
                            indicatorsContainer: base => ({
                                ...base,
                                height: "62px",
                            }),
                        }}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 20,
                            colors: {
                                ...theme.colors,
                                primary25: '#DBFC08',
                                neutral0: '#1F1F1F',
                                neutral20: '#5E5E5E',
                                neutral80: '#5E5E5E',
                                primary: '#5E5E5E',
                            },
                        })}
                    />
                    {props.error && <div>{props.error}</div>}
                </label>
            </div>
        </div>
    );
};

export default SelectField;
