import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    MdClose,
    MdDeleteOutline,
    MdDownload,
    MdEditSquare,
    MdIosShare,
    MdOutlinePlace,
    MdOutlinePrint
} from "react-icons/md";
import {useTranslation} from "react-i18next";
import { saveAs } from 'file-saver';
import {Backdrop, Box, Fade, Modal, Typography} from "@mui/material";

import Button from "../../../components/button/Button";
import PaymentPageList from "../../../components/api/client/account/payment-page/PaymentPageList";
import Loader from "../../../components/loader/Loader";
import {FireNotification} from "../../../components/notification/Notification";
import SystemPM from "../../../components/system-pm/SystemPM";
import InfiniteScroll from "react-infinite-scroll-component";
import BranchEmployeeLeft from "../../../components/api/client/account/branch-employee/BranchEmployeeLeft";
import {GetAccInfo} from "../../../components/storages/account-info-storage/AccountInfoStorage";

import "./QrCode.css";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: 24,
    p: 4,
};

const perPage = 5;

let requestsState = [];

const QrCode = () => {
    const qrRef = useRef();
    const navigate = useNavigate();
    const listRequest = 'listRequest';
    const [paymentPages, setPaymentPages] = useState( {
        list: [],
        limit: perPage,
        offset: 0,
    });
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [message, setMessage] = useState( '');
    const [isSuccess, setIsSuccess] = useState( null);
    const [hasVerification, setHasVerification] = useState( null);
    const [loader, setLoader] = useState(Loader());
    const [canShareQR, setCanShareQR] = useState(true);
    const [openQrWindow, setOpenQrWindow] = useState({});
    const [accInfo, setAccInfo] = useState(null);
    const { t } = useTranslation();

    const onManageInvites = () => {
        navigate(`/office/invites`);
    };

    const onEdit = (id) => {
        let canEdit = true;
        paymentPages.list.forEach((paymentPage) => {
            if (paymentPage.id === parseInt(id) && !paymentPage.canEdit) {
                canEdit = false;
            }
        });

        if (canEdit) {
            navigate(`/office/qr-code/edit/${id}`);
        }
    };

    const onDelete = (e) => {
        e.preventDefault();

        const id = e.currentTarget.getAttribute('data-id');
        let canDelete = true;
        paymentPages.list.forEach((paymentPage) => {
            if (paymentPage.id === parseInt(id) && !paymentPage.canDelete) {
                canDelete = false;
            }
        });

        if (id && canDelete) {
            setLoader(Loader());
            BranchEmployeeLeft(parseInt(id))
                .then((response) => {
                    if (response.status === 200 && response.data.result === true) {
                        fetch(1, true);
                        setIsSuccess(true);
                        setMessage(t('office.system.form.success.deleted.message'));
                    } else {
                        setIsSuccess(false);
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    }
                    setIsSuccess(false);
                    setMessage(t('something.went.wrong.error'));
                })
                .finally(() => {
                    setLoader(null);
                });
        }
    };

    const fetch = async (page = null, isRefresh = false) => {
        if (!requestsState[listRequest]) {
            requestsState[listRequest] = true;
            PaymentPageList({limit: perPage, offset: (null !== page ? (perPage * (page - 1)) : (offset + perPage))})
                .then((response) => {
                    if (response.status === 200) {
                        const newList = response.data.result.list;

                        if (isRefresh) {
                            setPaymentPages(prevPaymentPages => ({
                                ...prevPaymentPages,
                                list: [...newList],
                            }));
                        } else {
                            setPaymentPages(prevPaymentPages => ({
                                ...prevPaymentPages,
                                list: [...prevPaymentPages.list, ...newList],
                            }));
                        }

                        setHasVerification(response.data.result.hasVerification);
                        setHasMore(newList.length === perPage);
                        setOffset(response.data.result.offset);
                    } else {
                        setIsSuccess(false);
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    }
                    setIsSuccess(false);
                    setMessage(t('something.went.wrong.error'));
                })
                .finally(() => {
                    requestsState[listRequest] = false;
                    setLoader(null);
                });
        }
    };

    useEffect(() => {
        document.title = t('title.office.qr.code');

        if (!navigator.canShareQR) {
            setCanShareQR(false);
        }

        fetch(1);
        setAccInfo(GetAccInfo());
        setLoader(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message) {
            FireNotification(message, isSuccess);
            setMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    useEffect(() => {
        if (paymentPages) {
            const initialModalsState = paymentPages.list.reduce((acc, page) => {
                acc[page.id] = false;
                return acc;
            }, {});
            setOpenQrWindow(initialModalsState);
        }
    }, [paymentPages]);

    const downloadQRCode = (id) => {
        const imgElement = qrRef.current.querySelector("img");
        const imgSrc = imgElement.src;

        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            canvas.toBlob((blob) => {
                saveAs(blob, `takk-qr-${id}.png`);
            });
        };

        img.src = imgSrc;
    };

    const downloadForPrint = (id) => {
        const imgElement = qrRef.current.querySelector("img");
        const imgSrc = imgElement.src;

        fetch(imgSrc)
            .then((response) => response.text())
            .then((svgText) => {
                const blob = new Blob([svgText], { type: "image/svg+xml" });

                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = `takk-qr-print-${id}.svg`;
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            })
            .catch((error) => {
                console.error("Error fetching QR for print:", error);
            });
    };

    const shareQRCode = async (id) => {
        if (!canShareQR) {
            return;
        }

        const imgElement = qrRef.current.querySelector("img");
        const imgSrc = imgElement.src;

        const img = new Image();
        img.crossOrigin = "Anonymous";

        img.onload = async function () {
            const blob = await fetch(imgSrc).then((res) => res.blob());
            const filesArray = [new File([blob], `takk-qr-${id}.png`, { type: blob.type })];

            const shareData = {
                files: filesArray,
                title: "QR Code",
                text: "Here is your QR code",
            };

            await navigator.share(shareData);
        };

        img.src = imgSrc;
    };


    const onOpenLanding = (uuid) => {
        window.open(`/pay/${uuid}`, '_blank');
    };

    const onOpenQrWindow = (id) => {
        setOpenQrWindow((prevState) => ({
            ...prevState,
            [id]: true,
        }));
    };

    const onCloseQrWindow = (id) => {
        setOpenQrWindow((prevState) => ({
            ...prevState,
            [id]: false,
        }));
    };

    return (
        <div className="PaymentPageList">
            {loader && loader}
            <h1>{t('title.office.qr.code')}</h1>
            <br/>

            {hasVerification === false && (accInfo && accInfo.isEmployee) && (<div className="SystemNotifySection">
                <SystemPM
                    html={paymentPages.list.length === 0
                        ? t('office.system.verify.no.branches.employee.message')
                        : t('office.system.verify.missing.employee.message')}
                />
            </div>)}

            {<Button
                text={t('office.branches.detail.invitations.button')}
                onClick={onManageInvites}
            />}
            <div className="PaymentPageListListWrapper">
                <div className="PaymentPageListItems">
                    {paymentPages && paymentPages.list && (
                        <InfiniteScroll
                            dataLength={paymentPages.list.length}
                            next={fetch}
                            hasMore={hasMore}
                            loader={<div><Loader fullScreen={false} /></div>}
                            endMessage={""}
                        >
                            <div className="PaymentPageListItemWrapper">
                                {paymentPages.list.length > 0 ? (
                                    ((t) => {
                                        return (paymentPages.list.map((paymentPage) => {
                                            return (
                                                <div className="PaymentPageItem">
                                                    <div className="PaymentPageGeneral">
                                                        <div className="PaymentPageAvatar">
                                                            {paymentPage && paymentPage.avatar && (<img src={paymentPage.avatar} alt={paymentPage.title}/>)}
                                                        </div>
                                                        <div className="PaymentPageInfo">
                                                            <span className="PaymentPageName">
                                                                {paymentPage.name}
                                                            </span>
                                                            <span className="PaymentPageTitle">
                                                                {paymentPage.title}
                                                            </span>
                                                            <span className="PaymentPageSubtitle">
                                                                {paymentPage.subtitle}
                                                            </span>
                                                            <span className="PaymentPageAddress">
                                                                <MdOutlinePlace /> {paymentPage.address}
                                                            </span>
                                                        </div>
                                                        <div className="PaymentPageControl">
                                                            <div>
                                                                <MdDeleteOutline
                                                                    style={{
                                                                        cursor: paymentPage.canDelete ? "pointer" : "not-allowed",
                                                                        display: paymentPage.canDelete ? 'block' : 'none',
                                                                    }}
                                                                    data-id={paymentPage.canDelete ? paymentPage.employeeId : 0}
                                                                    onClick={onDelete}
                                                                    disabled={!paymentPage.canDelete}
                                                                    title={paymentPage.canDelete
                                                                        ? t('office.content.control.delete.button')
                                                                        : t('office.content.control.cannot.delete.button')}
                                                                />
                                                            </div>
                                                            <div>
                                                                <MdEditSquare
                                                                    style={{
                                                                        cursor: paymentPage.canEdit ? "pointer" : "not-allowed",
                                                                    }}
                                                                    data-id={paymentPage.id}
                                                                    onClick={() => onEdit(paymentPage.id)}
                                                                    disabled={!paymentPage.canEdit}
                                                                    title={paymentPage.canEdit
                                                                        ? t('office.content.control.edit.button')
                                                                        : t('office.content.control.cannot.edit.button')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="PaymentPageActions">
                                                        <Button
                                                            text={t('office.qr.code.qr.code.button')}
                                                            onClick={() => onOpenQrWindow(paymentPage.id)}
                                                        />
                                                        <Button
                                                            text={t('office.content.control.view.button')}
                                                            onClick={() => onOpenLanding(paymentPage.cashboxUuid)}
                                                        />
                                                    </div>

                                                    <Modal
                                                        open={openQrWindow[paymentPage.id]}
                                                        onClose={() => onCloseQrWindow(paymentPage.id)}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                        slots={{ backdrop: Backdrop }}
                                                        slotProps={{
                                                            backdrop: {
                                                                timeout: 500,
                                                                style: {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                    backdropFilter: 'blur(5px)',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <Fade in={openQrWindow[paymentPage.id]}>
                                                            <Box sx={modalStyle}>
                                                                <Typography id="modal-modal-description" sx={{mt: 2}}>
                                                                    <div className="QrWrapper">
                                                                        <div className="QrCloseWindow" onClick={() => onCloseQrWindow(paymentPage.id)}>
                                                                            <MdClose style={{cursor: 'pointer'}} />
                                                                        </div>
                                                                        <div id={`qrcode-${paymentPage.cashboxUuid}`} ref={qrRef}
                                                                             className="QrCodeWrapper">
                                                                            <img src={paymentPage.qrUrl} alt="QR Code"/>
                                                                        </div>
                                                                        <Button
                                                                            text={
                                                                                <span className="ButtonContentWrapper">
                                                                                    {t('office.qr.code.qr.download.button')}
                                                                                    <MdDownload/>
                                                                                </span>
                                                                            }
                                                                            onClick={() => downloadQRCode(paymentPage.cashboxUuid)}
                                                                        />
                                                                        <Button
                                                                            text={
                                                                                <span className="ButtonContentWrapper">
                                                                                    {t('office.qr.code.qr.print.download.button')}
                                                                                    <MdOutlinePrint/>
                                                                                </span>
                                                                            }
                                                                            onClick={() => downloadForPrint(paymentPage.cashboxUuid)}
                                                                        />
                                                                        <Button
                                                                            text={
                                                                                <span className="ButtonContentWrapper">
                                                                                    {t('office.qr.code.qr.share.button')}
                                                                                    <MdIosShare/>
                                                                                </span>
                                                                            }
                                                                            onClick={() => shareQRCode(paymentPage.cashboxUuid)}
                                                                            disabled={!canShareQR}
                                                                            title={!canShareQR ? t('office.qr.code.share.qr.not-supported.message') : ''}
                                                                        />
                                                                    </div>
                                                                </Typography>
                                                            </Box>
                                                        </Fade>
                                                    </Modal>
                                                </div>
                                            );
                                        }))
                                    })(t)
                                ) : (loader === null && paymentPages.list !== 'undefined' && (
                                    <span className="NoData">
                                        {t('office.qr.code.no.codes')}
                                    </span>
                                ))}
                            </div>
                        </InfiniteScroll>
                    )}
                </div>
            </div>
        </div>
    );
};

export default QrCode;
