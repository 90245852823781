// @ts-ignore
import { UnsupportedPaymentDataException } from "./Exception.ts";

export enum PaymentDataType {
    Iban = 1,
}

export function GetLabel(type: number): string {
    switch (type) {
        case PaymentDataType.Iban:
            return 'Iban';
        default:
            throw new UnsupportedPaymentDataException();
    }
}
