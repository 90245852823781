import React from 'react';
import { Link } from 'react-router-dom';

import {usePwa} from "../../pwa-context/PwaContext";

const PwaInstallLink = ({ text, icon, wrapperClass, iconWrapperClass, textWrapperClass }) => {
    const { isInstallable, isInstalled, promptInstall } = usePwa();

    if (!isInstallable || isInstalled) {
        return null;
    }

    return (
        <div className={wrapperClass}>
            <Link to='#' onClick={promptInstall}>
                <span className={iconWrapperClass}>
                    {icon}
                </span>
                <span className={textWrapperClass}>
                    {text}
                </span>
            </Link>
        </div>
    );
};

export default PwaInstallLink;
