import React, {useEffect, useState} from "react";
import {Link, useHref, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    MdBrandingWatermark,
    MdCreditCard,
    MdOutlineHelp,
    MdOutlineInstallDesktop,
    MdOutlineInstallMobile,
    MdOutlineNorthWest,
    MdOutlineSouthEast,
    MdPowerSettingsNew
} from "react-icons/md";

import ProfileRequest from "../../../components/api/client/account/profile/Profile";
import Loader from "../../../components/loader/Loader";
import ProfileForm from "../../../components/form/settings-form/SettingsForm";
import {FireNotification} from "../../../components/notification/Notification";
import SystemPM from "../../../components/system-pm/SystemPM";
import PwaInstallLink from "../../../components/settings/pwa-install-link/PwaInstallLink";
import {useDeviceCheck} from "../../../components/device-check-provider/DeviceCheckProvider";
import PwaInstallPopupLink from "../../../components/settings/pwa-install-popup-link/PwaInstallPopupLink";
import {AmountFormat} from "../../../components/formatter/Formatter";

import './Settings.css';

let requestsState = [];

const Settings = () => {
    const navigate = useNavigate();
    const profileRequest = 'profileRequest';
    const [profile, setProfile] = useState( []);
    const [message, setMessage] = useState( '');
    const [loader, setLoader] = useState(Loader());
    const { t } = useTranslation();
    const verificationLink = useHref('/office/verification');
    const { isMobile, isIOS, isStandalone } = useDeviceCheck();

    useEffect(() => {
        document.title = t('title.office.settings');

        if (!requestsState[profileRequest]) {
            requestsState[profileRequest] = true;
            ProfileRequest()
                .then((response) => {
                    if (response.status === 200 && response.data.result === true) {
                        setProfile(response.data.profile);
                    } else {
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    }
                    setMessage(t('something.went.wrong.error'));
                })
                .finally(() => {
                    requestsState[profileRequest] = false;
                    setLoader(null);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    useEffect(() => {
        if (message) {
            FireNotification(message, false);
            setMessage('');
        }
    }, [message]);

    return (
        <div className="ProfileWrapper">
            {loader && loader}
            <h1>{t('office.settings.subtitle')}</h1>
            {profile && <div>
                {
                    profile.isBusinessAccount
                    && !profile.hasVerification
                    && (!profile.lastVerificationStatus || !profile.lastVerificationStatus.isPending)
                    && <SystemPM
                        html={t('office.system.verify.first.message', {
                            link: `<a href="${verificationLink}">${t('office.system.verify.first.link')}</a>`
                        })}
                    />}
                <br/>

                <div className="ProfileHeader">
                    <div className="ProfileUsername">
                        <div className="ProfileAccType">
                            <span className="ProfileAccTypeLabel">
                                {
                                    profile.isBusinessAccount
                                        ? t('office.settings.profile.account.type.business')
                                        : profile.isEmployeeAccount
                                            ? t('office.settings.profile.account.type.employee')
                                            : 'basic'
                                }
                            </span>
                        </div>
                        <span>
                            {profile.email}
                        </span>
                    </div>
                    {profile.tariff && <div className="ProfileTariff">
                        <div className="TariffHeader">
                                <span>
                                    {t('office.settings.form.plan.header')}
                                </span>
                        </div>
                        <div className="TariffWrapper">
                            <div className="TariffRowWrapper">
                                <div className="TariffIcon">
                                    <div className="TariffType">
                                        {<MdOutlineSouthEast/>}
                                    </div>
                                </div>
                                <div className="TariffContent">
                                        <span className="TariffSubHeader">
                                            {t('office.settings.form.plan.income.fee.subtitle')}
                                        </span>
                                    <span className="TariffValue">
                                        {profile.tariff.incomeFee} %
                                        {!!profile.tariff.incomeFeeAmount && (
                                            ` + ${AmountFormat(profile.tariff.incomeFeeAmount, profile.tariff.currency)}`
                                        )}
                                        </span>
                                </div>
                            </div>
                            <div className="TariffRowWrapper">
                                <div className="TariffIcon">
                                    <div className="TariffType">
                                        {<MdOutlineNorthWest/>}
                                    </div>
                                </div>
                                <div className="TariffContent">
                                        <span className="TariffSubHeader">
                                            {t('office.settings.form.plan.withdrawal.fee.subtitle')}
                                        </span>
                                    <span className="TariffValue">
                                            {profile.tariff.withdrawalFee} %
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>

                <div className="ProfileSection">
                    <ProfileForm profile={profile}/>
                    {profile.isBusinessAccount && profile.isReVerificationAvailable && (<span className="ReVerifyWrapper">
                        <Link to={'/office/verification'}>
                            {t('office.settings.correct.personal.data.button')}
                        </Link>
                    </span>)}
                    <br/>
                </div>

                <div className="SettingsNavbar">
                    <div className="SettingsNavbarWrapper">
                        {!isStandalone && (!isIOS
                            ? (
                                <PwaInstallLink
                                    text={t('office.settings.navbar.install.pwa')}
                                    icon={isMobile ? <MdOutlineInstallMobile/> : <MdOutlineInstallDesktop/>}
                                    wrapperClass="SettingsNavItem"
                                    textWrapperClass="SettingsNavText"
                                    iconWrapperClass="SettingsNavIcon"
                                />
                            ) : (
                                <PwaInstallPopupLink
                                    text={t('office.settings.navbar.install.pwa')}
                                    icon={<MdOutlineInstallMobile/>}
                                    wrapperClass="SettingsNavItem"
                                    textWrapperClass="SettingsNavText"
                                    iconWrapperClass="SettingsNavIcon"
                                />
                            )
                        )}
                        <div className="SettingsNavItem">
                            <Link to={'/office/payout-methods'}>
                                <span className="SettingsNavIcon">
                                    <MdCreditCard />
                                </span>
                                <span className="SettingsNavText">
                                    {t('office.settings.navbar.payouts.methods')}
                                </span>
                            </Link>
                        </div>
                        {profile.isBusinessAccount && (
                            <div className="SettingsNavItem">
                                <Link to={'/office/promo-banner'}>
                                <span className="SettingsNavIcon">
                                    <MdBrandingWatermark />
                                </span>
                                    <span className="SettingsNavText">
                                    {t('office.settings.navbar.promo.banners')}
                                </span>
                                </Link>
                            </div>
                        )}
                        <div className="SettingsNavItem">
                            <Link to={'/support'}>
                                <span className="SettingsNavIcon">
                                    <MdOutlineHelp />
                                </span>
                                <span className="SettingsNavText">
                                    {t('office.settings.navbar.support')}
                                </span>
                            </Link>
                        </div>
                        <div className="SettingsNavItem">
                            <Link to={'/auth/logout'}>
                                <span className="SettingsNavIcon">
                                    <MdPowerSettingsNew/>
                                </span>
                                <span className="SettingsNavText">
                                    {t('office.settings.navbar.logout')}
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default Settings;
