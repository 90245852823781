import Field from "../Field";

const TextField = ({id, name, label, error, onChange, onFocus, onBlur, disabled, value, placeholder, className, labelclassname, autoComplete}) => (
    <div className="TextField">
        <Field
            {...(id ? {id} : {})}
            type="text"
            name={name}
            {...(autoComplete ? {autoComplete} : {})}
            label={label}
            value={value}
            error={error}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
            placeholder={placeholder}
            labelclassname={labelclassname}
            className={className}
        />
    </div>
);

export default TextField;
