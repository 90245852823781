import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import WalletForm from "../../../../components/form/payout-method-form/PayoutMethodForm";

const PayoutMethodCreate = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('title.office.payout.method.create');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCancel = () => {
        navigate('/office/payout-methods');
    };

    return (
        <div>
            <h1>{t('office.payout.method.create.subtitle')}</h1>
            <br/>
            <WalletForm onCancel={onCancel} />
        </div>
    );
};

export default PayoutMethodCreate;
