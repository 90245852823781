import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import RegisterForm from "../../../components/form/register-form/RegisterForm";

const Register = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('title.register');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h1>{t('title.register')}</h1>
            <br/>
            <RegisterForm />
        </div>
    );
}

export default Register;
