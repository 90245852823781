import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHref, useNavigate, useParams} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import SystemPM from "../../../../components/system-pm/SystemPM";
import {FireNotification} from "../../../../components/notification/Notification";
import Loader from "../../../../components/loader/Loader";
import Button from "../../../../components/button/Button";
import BranchInviteListByBranch from "../../../../components/api/client/account/branch-invite/BranchInviteListByBranch";
import BranchInviteCancel from "../../../../components/api/client/account/branch-invite/BranchInviteCancel";
import PrevButton from "../../../../components/prev-button/PrevButton";

import "./BranchInvite.css";

const perPage = 10;

let requestsState = [];

const BranchInvite = () => {
    const navigate = useNavigate();
    const listRequest = 'listRequest';
    const { id } = useParams();
    const [invites, setInvites] = useState( {
        list: [],
        limit: perPage,
        offset: 0,
    });
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [message, setMessage] = useState( '');
    const [isSuccess, setIsSuccess] = useState( null);
    const [branchName, setBranchName] = useState( '');
    const [availableCreationLimit, setAvailableCreationLimit] = useState( 0);
    const [hasVerification, setHasVerification] = useState( null);
    const [loader, setLoader] = useState(Loader());
    const { t } = useTranslation();
    const verificationLink = useHref('/office/verification');

    const onCreate = () => {
        navigate(`/office/branch/${id}/invite/create`);
    };

    const onCancel = (id) => {
        if (id) {
            setLoader(Loader());
            BranchInviteCancel(parseInt(id))
                .then((response) => {
                    if (response.status === 200 && response.data.result === true) {
                        fetch(1, true);
                        setIsSuccess(true);
                        setMessage(t('office.system.form.success.cancel.message'));
                    } else {
                        setIsSuccess(false);
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    }
                    setIsSuccess(false);
                    setMessage(t('something.went.wrong.error'));
                })
                .finally(() => {
                    setLoader(null);
                });
        }
    };

    const fetch = async (page = null, isRefresh = false) => {
        if (!requestsState[listRequest]) {
            requestsState[listRequest] = true;
            BranchInviteListByBranch(id, {limit: perPage, offset: (null !== page ? (perPage * (page - 1)) : (offset + perPage))})
                .then((response) => {
                    if (response.data.result.list) {
                        const newList = response.data.result.list;

                        if (isRefresh) {
                            setInvites(prevInvites => ({
                                ...prevInvites,
                                list: [...newList],
                            }));
                        } else {
                            setInvites(prevInvites => ({
                                ...prevInvites,
                                list: [...prevInvites.list, ...newList],
                            }));
                        }

                        setBranchName(response.data.result.branchName);
                        setHasVerification(response.data.result.hasVerification);
                        setAvailableCreationLimit(response.data.result.availableCount);
                        setHasMore(newList.length === perPage);
                        setOffset(response.data.result.offset);
                    } else {
                        setMessage(t('something.went.wrong.error'));
                        setIsSuccess(false);
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    } else {
                        setMessage(t('something.went.wrong.error'));
                    }
                    setIsSuccess(false);
                })
                .finally(() => {
                    requestsState[listRequest] = false;
                });
        }
    };

    useEffect(() => {
        document.title = t('title.office.branch.invites');

        fetch(1);
        setLoader(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message) {
            FireNotification(message, isSuccess);
            setMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return (
        <div className="BranchInviteList">
            {loader && loader}
            <PrevButton route={`/office/branch/${id}`} />
            <h1>{t('title.office.branch.invites')}</h1>
            <h2>{branchName}</h2>
            <br/>

            {(hasVerification === false) && (<div className="SystemNotifySection">
                <SystemPM
                    html={t('office.system.verify.first.message', {
                        link: `<a href="${verificationLink}">${t('office.system.verify.first.link')}</a>`
                    })}
                />
            </div>)}

            {availableCreationLimit > 0 && hasVerification && <Button
                text={t('office.branches.new.button')}
                onClick={onCreate}
            />}
            <div className="BranchInviteListWrapper">
                <div className="BranchInviteListItems">
                    {invites && invites.list && (
                        <InfiniteScroll
                            dataLength={invites.list.length}
                            next={fetch}
                            hasMore={hasMore}
                            loader={<div><Loader fullScreen={false} /></div>}
                            endMessage={""}
                        >
                            <div className="BranchInviteListItemWrapper">
                                {invites.list.length > 0 ? (
                                    ((t) => {
                                        return (invites.list.map((invite) => {
                                            return (
                                                <div className="BranchInviteItem">
                                                    <div className="BranchInviteGeneral">
                                                        <div className="BranchInviteInfo">
                                                            <span className="BranchInviteName">
                                                                {invite.email}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="BranchInviteFooter">
                                                        <Button
                                                            text={t('office.content.control.cancel.button')}
                                                            onClick={() => onCancel(invite.id)}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }));
                                    })(t)
                                ) : (loader === null && invites.list !== 'undefined' && (
                                    <span className="NoData"></span>
                                ))}
                            </div>
                        </InfiniteScroll>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BranchInvite;
