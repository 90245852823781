import React, { useState } from "react";

import TextField from "../../../../field/text-field/TextField";

const formPrefix = 'iban_wallet_';

const Render = (props) => {
    const [walletAccount, setWalletAccount] = useState(props.data.account);
    const [walletAccountError, setWalletAccountError] = useState(
        props.data.account === '' ? props.t('required.field.message') : ""
    );
    const [walletName, setWalletName] = useState(props.data.name);
    const [walletNameError, setWalletNameError] = useState(
        props.data.name === '' ? props.t('required.field.message') : ""
    );
    const [walletCode, setWalletCode] = useState(props.data.code);
    const [walletCodeError, setWalletCodeError] = useState("");

    const onChangeWalletAccount = (event) => {
        setWalletAccount(event.target.value);
        setWalletAccountError(props.onChangeAccount(event.target.value));
    };

    const onChangeWalletName = (event) => {
        setWalletName(event.target.value);
        setWalletNameError(props.onChangeName(event.target.value));
    };

    const onChangeWalletCode = (event) => {
        setWalletCode(event.target.value);
        setWalletCodeError(props.onChangeCode(event.target.value));
    };

    return (
        <div className="WalletWrapper">
            <TextField
                name={`${formPrefix}account`}
                label={props.t('office.payout.method.form.iban.account.number.field.label')}
                value={walletAccount}
                error={walletAccountError}
                onChange={onChangeWalletAccount}
            />

            <TextField
                name={`${formPrefix}name`}
                label={props.t('office.payout.method.form.iban.full.name.field.label')}
                value={walletName}
                error={walletNameError}
                onChange={onChangeWalletName}
            />

            <TextField
                name={`${formPrefix}code`}
                label={props.t('office.payout.method.form.iban.swift.code.field.label')}
                value={walletCode}
                error={walletCodeError}
                onChange={onChangeWalletCode}
            />
        </div>
    );
};

export default Render;
