import React, {useState, useCallback, useEffect} from "react";
import Cropper from "react-easy-crop";
import { Slider, Typography, Box } from "@mui/material";
import heic2any from "heic2any";
import {useTranslation} from "react-i18next";

import getCroppedImg from "../../../../utils/cropImage";
import Loader from "../../../loader/Loader";
import Button from "../../../button/Button";

import "./AvatarField.css";

const AvatarField = (props) => {
    const [inputId] = useState( Math.random().toString(36));
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [minZoom, setMinZoom] = useState(1);
    const [maxZoom, setMaxZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropInProcess, setCropInProcess] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.reset) {
            handleReset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reset]);

    const handleImageLoad = (image) => {
        const { width, height } = image;

        // const minZoomWidth = props.minWidth / width;
        // const minZoomHeight = props.minHeight / height;
        // const minZoom = Math.max(minZoomWidth, minZoomHeight);

        const maxZoomWidth = width / props.minWidth;
        const maxZoomHeight = height / props.minHeight;
        const maxZoom = Math.min(maxZoomWidth, maxZoomHeight);

        setMinZoom(1);
        setMaxZoom(maxZoom);
        setZoom(1);
    };

    const handleChooseFiles = (e) => {
        e.preventDefault();

        document.getElementById(inputId).click();
    };

    const handleFileChange = async (e) => {
        let file = e.target.files[0];
        if (file) {
            setLoader(Loader({fullScreen: false}));
            const extension = file.name.split(".").pop().toLowerCase();
            if (!props.fileTypes.includes(extension)) {
                setError(t('office.system.form.invalid.file.format.message"'));
                setLoader(null);
                return;
            }

            if (['heic', 'heif'].includes(extension)) {
                try {
                    const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
                    file = new File([convertedBlob], `${file.name}.jpg`, { type: 'image/jpeg' });
                } catch (e) {
                    console.error('Error on while convert HEIC/HEIF', e);
                    return;
                } finally {
                    setLoader(null);
                }
            } else {
                setLoader(null);
            }

            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    if (img.width < props.minWidth || img.height < props.minHeight) {
                        setError(t('office.system.form.min.size.limits.message', {
                            minSizes: `${props.minWidth}x${props.minHeight}`,
                        }));
                        return;
                    }

                    setError('');
                    setImageSrc(reader.result);
                    handleImageLoad(img);
                };
            };
            reader.readAsDataURL(file);
        }
    };

    const onCropComplete = useCallback((croppedArea, newCroppedAreaPixels) => {
        setCropInProcess(true);
        if (newCroppedAreaPixels && newCroppedAreaPixels.width && newCroppedAreaPixels.height) {
            if (croppedAreaPixels === null) {
                props.onStartedCrop && props.onStartedCrop();
            }

            setCroppedAreaPixels(newCroppedAreaPixels);
        } else {
            console.warn('Invalid cropped area pixels detected', newCroppedAreaPixels);
        }
    }, [croppedAreaPixels, props]);

    const handleCrop = async (e) => {
        e.preventDefault();

        try {
            if (!croppedAreaPixels) {
                console.log('Crop area not detected');
                return;
            }

            const croppedImg = await getCroppedImg(imageSrc, croppedAreaPixels);
            const response = await fetch(croppedImg);
            const blob = await response.blob();
            const croppedFile = new File([blob], 'cropped-avatar.jpg', { type: 'image/jpeg' });
            props.onImageCropped(croppedFile, croppedImg);
            handleReset(e);
            setCroppedImage(croppedImg);
        } catch (e) {
            console.error(e.message || "Error on while crop image.", e);
        }
    };

    const handleReset = (e = null) => {
        e && e.preventDefault();

        setImageSrc(null);
        setCroppedImage(null);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setMinZoom(1);
        setMaxZoom(1);
        setCroppedAreaPixels(null);
        setError('');
        setCropInProcess(false);
        props.onStoppedCrop && props.onStoppedCrop();
    };

    return (
        <div className="AvatarSection">
            <div className="Field">
                <label>
                    {props.label}
                </label>
            </div>
            <div className="AvatarField">
                <div className="AvatarFieldWrapper">
                    {!loader && (<div className="AvatarCurrentWrapper">
                        {props.current && !croppedImage && !cropInProcess && !loader && (<img src={props.current} alt={props.alt}/>)}
                        {croppedImage && !cropInProcess && !loader && (<img src={croppedImage} alt={props.alt}/>)}
                    </div>)}
                    {imageSrc ? (
                        <div className="AvatarCropSection">
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                gap={2}
                                margin="0 auto"
                                maxWidth={360}
                            >
                                <Box style={{
                                    position: "relative",
                                    width: "100%",
                                    height: 200,
                                }}>
                                    <Box style={{
                                        position: "relative",
                                        width: 200,
                                        height: 200,
                                        margin: "0 auto",
                                    }}>
                                        <Cropper
                                            image={imageSrc}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={1}
                                            onCropChange={setCrop}
                                            onZoomChange={setZoom}
                                            onCropComplete={onCropComplete}
                                            zoomWithScroll
                                            restrictPosition
                                            minZoom={minZoom}
                                            maxZoom={maxZoom}
                                            style={{width: 200, height: 200}}
                                        />
                                    </Box>
                                    <Slider
                                        value={zoom}
                                        min={minZoom}
                                        max={maxZoom}
                                        step={0.1}
                                        onChange={(e, zoom) => setZoom(zoom)}
                                        style={{
                                            marginTop: 5,
                                        }}
                                        sx={{
                                            width: "80%",
                                            color: "#ffffff",
                                            "& .MuiSlider-thumb": {
                                                backgroundColor: "#ffffff",
                                            },
                                            "& .MuiSlider-rail": {
                                                backgroundColor: "#c5c5c5",
                                            },
                                        }}
                                    />
                                </Box>
                                <Box display="flex" gap={2} marginTop={5}>
                                    <Button
                                        onClick={handleCrop}
                                        text={t('office.system.form.confirm.button')}
                                    />
                                    <Button
                                        onClick={handleReset}
                                        text={t('office.system.form.cancel.button')}
                                    />
                                </Box>
                            </Box>
                        </div>
                    ) : (
                        <div className="AvatarFormSection">
                            {loader && <div className="AvatarLoaderSection">{loader}</div>}
                            {!loader && <input
                                id={inputId}
                                name={props.name}
                                type="file"
                                accept={props.fileTypes.map((ext) => `.${ext}`).join(",")}
                                onChange={handleFileChange}
                                hidden
                            />}
                            {!loader && (<div className="ControlWrapper">
                                <Button
                                    text={t('office.system.form.choose.file.button')}
                                    onClick={handleChooseFiles}
                                />
                                <div className="ControlTips">
                                    <div className="FormatTip">
                                        <span>
                                            <span className="TipsWrapper">{'.' + props.fileTypes.join(', .')}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    )}
                </div>
            </div>
            <div>
                {(error || props.error) && (
                    <Typography color="#5E5E5E" fontSize="16px">
                        {error && error}
                        {props.error && props.error}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default AvatarField;
