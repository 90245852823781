import Client from "../../Client";
import {GetAuthToken} from "../../../../storages/auth-token-storage/AuthTokenStorage";

const WithdrawalCreate = ({userAccountDetailId, amount}) => {
    return Client(GetAuthToken()).post(
        '/account/withdrawal/create',
        {
            userAccountDetailId: userAccountDetailId,
            amount: amount,
        },
    );
}

export default WithdrawalCreate;
