import Client from "../../Client";
import {GetAuthToken} from "../../../../storages/auth-token-storage/AuthTokenStorage";

const ProfileUpdate = ({contact, contactFullName, website, fullName}) => {
    return Client(GetAuthToken()).post(
        '/account/profile/update',
        {
            contact: contact,
            contactFullName: contactFullName,
            website: website,
            fullName: fullName,
        },
    );
}

export default ProfileUpdate;
