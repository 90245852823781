import getSymbolFromCurrency from "currency-symbol-map";

const AmountFormat = (amount, currency) => {
    return `${GetCurrencySymbol(currency)} ${GetFormattedAmount(amount)}`;
};

const GetFormattedAmount = (amount) => {
    return `${GetFormattedIntegerPartOfAmount(amount)}${GetDecimalSeparator()}${GetFractionalPartOfAmount(amount)}`;
};

const GetFormattedIntegerPartOfAmount = (amount) => {
    const amountStr = amount.toString();
    let [integerPart] = amountStr.split('.');

    return parseInt(integerPart).toLocaleString().replace(/,/g, GetThousandsSeparator());
};

const GetThousandsSeparator = () => {
    return '.';
};

const GetDecimalSeparator = () => {
    return ',';
};

const GetFractionalPartOfAmount = (amount) => {
    const amountStr = amount.toString();
    let [, fractionalPart] = amountStr.split('.');

    if (!fractionalPart) {
        fractionalPart = '00';
    }

    return fractionalPart;
};

const GetCurrencySymbol = (currency) => {
    const symbol = getSymbolFromCurrency(currency);
    return !!symbol ? symbol : currency;
};

export {
    AmountFormat,
    GetCurrencySymbol,
    GetFormattedIntegerPartOfAmount,
    GetDecimalSeparator,
    GetFractionalPartOfAmount,
};
