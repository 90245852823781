import React from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Button from "../../../button/Button";
import LandingPay from "../../../api/client/landing/LandingPay";
import Loader from "../../../loader/Loader";
import {FireNotification} from "../../../notification/Notification";
import {AmountFormat} from "../../../formatter/Formatter";

import "./PaymentForm.css";

class PaymentForm extends React.Component {
    state = {
        message: "",
        redirect: "",
        loader: null,
        cashboxUuidInProcess: null,
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.message && prevState.message !== this.state.message) {
            FireNotification(this.state.message, false);
            this.setState({message: ''});
        }
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (!this.props.paymentData.validate()) {
            return;
        }

        this.setState({loader: Loader()});
        LandingPay({
            cashboxUuid: this.props.cashboxUuid,
            method: this.props.paymentData.getMethod(),
            currency: this.props.currency,
            amount: parseFloat(this.props.amount) + parseFloat(this.props.fee),
            containsPaidFee: this.props.fee !== 0,
            paymentData: this.props.paymentData.getState(),
        })
            .then((response) => {
                if (response.data.code === 200) {
                    if (!response.data.result.executed) {
                        // TODO should add handling via ping status of payment
                        this.setState({cashboxUuidInProcess: response.data.UUID});
                    } else {
                        this.props.paymentData.reset();
                        this.props.onSuccess();
                    }
                } else if (response.data.code === 400 && response.data.details.length !== 0) {
                    // TODO add output errors for each payment field
                    this.setState({message: this.props.t('something.went.wrong.error')});
                } else {
                    this.setState({message: this.props.t('something.went.wrong.error')});
                }
            })
            .catch(error => {
                if (error.response?.status === 404) {
                    this.setState({redirect: "/404"});
                } else {
                    this.setState({message: this.props.t('something.went.wrong.error')});
                }
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    render() {
        const { t } = this.props;

        return (
            <div className="PaymentForm">
                {this.state.loader && this.state.loader}
                {this.state.redirect && <Navigate to={this.state.redirect} />}

                <div className="AmountWrapper">
                    <div className="AmountSector">
                        {AmountFormat(parseFloat(this.props.amount) + parseFloat(this.props.fee), this.props.currency)}
                    </div>
                    <div className="EditWrapper">
                        <Button
                            text={t('edit.button')}
                            onClick={this.props.onCancel}
                        />
                    </div>
                </div>

                <form
                    onSubmit={this.onSubmit}
                    autoComplete="off"
                >
                    {this.props.paymentData && this.props.paymentData.render()}

                    <div className="FormFooterSection">
                        <div className="FormButtonsWrapper">
                            <Button text={t('pay.by.card.button')} />
                        </div>

                        <div className="FormAgreementWrapper">
                            <span>
                                {t('pay.agreement.text')}<br/>
                                <Link to={'/terms-and-conditions'} target="_blank" >{t('pay.agreement.link')}</Link>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(PaymentForm);
