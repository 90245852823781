import React from 'react';
import {
    Routes as BaseRoutes,
    Route,
} from "react-router";

import Login from "../../pages/auth/login/Login";
import Register from "../../pages/auth/register/Register";
import ForgotPassword from "../../pages/auth/forgot-password/ForgotPassword";
import NotFound from "../../pages/not-found/NotFound";
import { RouteGuard, RouteCommon } from "./route-guard/RouteGuard";
import Verify from "../../pages/auth/verify/Verify";
import ChangePassword from "../../pages/auth/change-password/ChangePassword";
import Logout from "../../pages/auth/logout/Logout";
import Verification from "../../pages/office/verification/Verification";
import PayoutMethod from "../../pages/office/payout-method/PayoutMethod";
import PayoutMethodCreate from "../../pages/office/payout-method/create/PayoutMethodCreate";
import QrCode from "../../pages/office/qr-code/QrCode";
import QrCodeCreate from "../../pages/office/qr-code/create/QrCodeCreate";
import QrCodeEdit from "../../pages/office/qr-code/create/QrCodeEdit";
import WithdrawalCreate from "../../pages/office/overview/overview/withdrawal/WithdrawalCreate";
import Pay from "../../pages/pay/Pay";
import Static from "../../pages/static/Static";
import PayConfirmation from "../../pages/pay-confirmation/PayConfirmation";
import Settings from "../../pages/office/settings/Settings";
import Overview from "../../pages/office/overview/Overview";
import RegisterConfirm from "../../pages/auth/register-confirm/RegisterConfirm";
import Branch from "../../pages/office/branch/Branch";
import BranchCreate from "../../pages/office/branch/create/BranchCreate";
import BranchEdit from "../../pages/office/branch/edit/BranchEdit";
import Detail from "../../pages/office/branch/detail/Detail";
import BranchInvite from "../../pages/office/branch/invite/BranchInvite";
import BranchInviteCreate from "../../pages/office/branch/invite/create/BranchInviteCreate";
import RegisterEmployee from "../../pages/auth/register-employee/RegisterEmployee";
import EmployeeInvite from "../../pages/office/qr-code/employee-invite/EmployeeInvite";
import PromoBanner from "../../pages/office/promo-banner/PromoBanner";

const Routes = () => (
    <BaseRoutes>
        <Route path="/auth/logout" element={<RouteGuard element={Logout} />} />
        <Route path="/office" element={<RouteGuard element={Overview} />} />
        <Route path="/office/overview" element={<RouteGuard element={Overview} />} />
        <Route path="/office/settings" element={<RouteGuard element={Settings} />} />
        <Route path="/office/verification" element={<RouteGuard element={Verification} />} />
        <Route path="/office/payout-methods" element={<RouteGuard element={PayoutMethod} />} />
        <Route path="/office/payout-method/create" element={<RouteGuard element={PayoutMethodCreate} />} />
        <Route path="/office/branches" element={<RouteGuard element={Branch} />} />
        <Route path="/office/branch/create" element={<RouteGuard element={BranchCreate} />} />
        <Route path="/office/branch/edit/:id" element={<RouteGuard element={BranchEdit} />} />
        <Route path="/office/branch/:id" element={<RouteGuard element={Detail} />} />
        <Route path="/office/branch/:id/invites" element={<RouteGuard element={BranchInvite} />} />
        <Route path="/office/branch/:id/invite/create" element={<RouteGuard element={BranchInviteCreate} />} />
        <Route path="/office/qr-codes" element={<RouteGuard element={QrCode} />} />
        <Route path="/office/invites" element={<RouteGuard element={EmployeeInvite} />} />
        <Route path="/office/qr-code/create" element={<RouteGuard element={QrCodeCreate} />} />
        <Route path="/office/qr-code/edit/:id" element={<RouteGuard element={QrCodeEdit} />} />
        <Route path="/office/withdrawal/create" element={<RouteGuard element={WithdrawalCreate} />} />
        <Route path="/office/promo-banner" element={<RouteGuard element={PromoBanner} />} />

        <Route path="/auth/login" element={<RouteCommon element={Login} />} />
        <Route path="/auth/register" element={<RouteCommon element={Register} />} />
        <Route path="/auth/register/confirm" element={<RouteCommon element={RegisterConfirm} />} />
        <Route path="/auth/register/employee/:code" element={<RouteCommon element={RegisterEmployee} />} />
        <Route path="/auth/verify/:activationCode" element={<RouteCommon element={Verify} />} />
        <Route path="/auth/forgot-password" element={<RouteCommon element={ForgotPassword} />} />
        <Route path="/auth/change-password/:recoveryCode" element={<RouteCommon element={ChangePassword} />} />

        <Route path="/pay/:uuid" element={<Pay />} />
        <Route path="/pay/:uuid/confirmation" element={<PayConfirmation />} />
        <Route path="/privacy-policy" element={<Static slug="privacy-policy" />} />
        <Route path="/terms-and-conditions" element={<Static slug="terms-and-conditions" />} />
        <Route path="/support" element={<Static slug="support" />} />
        <Route path="/404" element={<NotFound/>} />
        <Route path="/" element={<RouteGuard element={Overview} />} />

        <Route path="*" element={<NotFound/>} />
    </BaseRoutes>
);

export default Routes;
