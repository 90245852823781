import { CookieConsent } from "react-cookie-consent";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

import './PrivacyNotify.css';

const expires = 365;

const PrivacyNotify = () => {
    const { t } = useTranslation();

    return (
        <div className="PrivacyNotifyWrapper">
            <CookieConsent
                location="bottom"
                buttonText="OK"
                buttonWrapperClasses="PrivacyPolicyButtonWrapper"
                buttonClasses="PrivacyPolicyButton"
                contentClasses="PrivacyPolicyContentWrapper"
                cookieName="PrivacyPolicyConsent"
                expires={expires}
            >
                <div className="PrivacyNotifyText">
                    {t('privacy.policy.text')}&nbsp;
                    <Link target="_blank" to="/privacy-policy">{t('privacy.policy.link.text')}</Link>
                </div>
            </CookieConsent>
        </div>
    );
};

export default PrivacyNotify;
