import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {MdDeleteOutline, MdOutlinePayment} from "react-icons/md";
import { useTranslation } from "react-i18next";

import Button from "../../../components/button/Button";
import WalletDelete from "../../../components/api/client/account/wallet/WalletDelete";
import WalletList from "../../../components/api/client/account/wallet/WalletList";
import Loader from "../../../components/loader/Loader";
import {FireNotification} from "../../../components/notification/Notification";

import "./PayoutMethod.css";

let requestsState = [];

const PayoutMethod = () => {
    const navigate = useNavigate();
    const listRequest = 'listRequest';
    const [wallets, setWallets] = useState( null);
    const [message, setMessage] = useState( '');
    const [isSuccess, setIsSuccess] = useState( null);
    const [availableCreationLimit, setAvailableCreationLimit] = useState( 0);
    const [loader, setLoader] = useState(Loader());
    const { t } = useTranslation();

    const onCreate = () => {
        navigate('/office/payout-method/create');
    };

    const onDelete = (e) => {
        e.preventDefault();

        const walletId = e.currentTarget.getAttribute('data-id');
        if (walletId) {
            setLoader(Loader());
            WalletDelete(walletId)
                .then((response) => {
                    if (response.status === 200 && response.data.result === true) {
                        updateList();
                        setIsSuccess(true);
                        setMessage(t('office.system.form.success.deleted.message'));
                    } else {
                        setIsSuccess(false);
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    }
                    setIsSuccess(false);
                    setMessage(t('something.went.wrong.error'));
                })
                .finally(() => {
                    setLoader(null);
                });
        }
    };

    const updateList = () => {
        !loader && setLoader(Loader());
        WalletList()
            .then((response) => {
                if (response.status === 200) {
                    setWallets(response.data.result);
                    setAvailableCreationLimit(response.data.result.availableCount);
                } else {
                    setIsSuccess(false);
                    setMessage(t('something.went.wrong.error'));
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    navigate("/auth/logout");
                }
                setIsSuccess(false);
                setMessage(t('something.went.wrong.error'));
            })
            .finally(() => {
                requestsState[listRequest] = false;
                setLoader(null);
            });
    };

    useEffect(() => {
        document.title = t('title.office.payout.method');

        if (!requestsState[listRequest]) {
            requestsState[listRequest] = true;
            updateList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message) {
            FireNotification(message, isSuccess);
            setMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return (
        <div className="WalletList">
            {loader && loader}
            <h1>{t('title.office.payout.method')}</h1>
            <br/>

            {availableCreationLimit > 0 && <Button
                text={t('office.payout.method.new.button')}
                onClick={onCreate}
            />}
            <div className="WalletListWrapper">
                {wallets && wallets.list.map((wallet) => {
                    const walletType = wallet.type === 1 ? 'IBAN' : ' - ';
                    return (
                        <div className="WalletItem">
                            <div className="WalletType">
                                <span>{walletType}</span>
                            </div>
                            <div className="WalletGeneral">
                                <div className="WalletIcon">
                                    <MdOutlinePayment/>
                                </div>
                                <div className="WalletInfo">
                                    <div className="WalletName">
                                        {wallet.name}
                                    </div>
                                    <div className="WalletDetail">
                                        {Object.keys(wallet.paymentData).map(function (key) {
                                            return (
                                                <div className="WalletData">
                                                    {key}: {wallet.paymentData[key]}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="WalletControl">
                                    <div>
                                        <MdDeleteOutline data-id={wallet.id} onClick={onDelete} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PayoutMethod;
