import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import {MdInfoOutline, MdOutlineNorthWest} from "react-icons/md";
import {IconButton, Tooltip} from "@mui/material";

import Button from "../../../../components/button/Button";
import WithdrawalOverview from "../../../../components/api/client/account/withdrawal/WithdrawalOverview";
import WithdrawalListRequest from "../../../../components/api/client/account/withdrawal/WithdrawalList";
import Loader from "../../../../components/loader/Loader";
import {AmountFormat} from "../../../../components/formatter/Formatter";
import {FireNotification} from "../../../../components/notification/Notification";
import {GetCurrentLanguage} from "../../../../components/i18n/LanguageSwitcher/LanguageSwitcher";
import SystemPM from "../../../../components/system-pm/SystemPM";

import "./TransactionList.css";
import "./WithdrawalList.css";

const withdrawalsPerPage = 10;

let requestsState = [];

const WithdrawalList = () => {
    const navigate = useNavigate();
    const overviewRequest = 'overviewRequest';
    const listRequest = 'listRequest';
    const [title, setTitle] = useState('My withdrawals');
    const [withdrawals, setWithdrawals] = useState( null);
    const [withdrawalsOffset, setWithdrawalsOffset] = useState(0);
    const [hasMoreWithdrawals, setHasMoreWithdrawals] = useState(true);
    const [message, setMessage] = useState( '');
    const [canWithdraw, setCanWithdraw] = useState( false);
    const [minWithdrawAmount, setMinWithdrawAmount] = useState( null);
    const [currency, setCurrency] = useState( null);
    const [loader, setLoader] = useState(Loader({
        fullScreen: false,
        targetClass: 'WithdrawalControlWrapper',
    }));
    const { t } = useTranslation();

    const onCreate = () => {
        navigate('/office/withdrawal/create');
    };

    const fetchOverview = () => {
        WithdrawalOverview({limit: withdrawalsPerPage})
            .then((response) => {
                if (response.status === 200 && response.data.error === null) {
                    const withdrawalList = response.data.result.withdrawals.list;
                    setWithdrawals(withdrawalList);
                    setMinWithdrawAmount(response.data.result.minAmountLimit);
                    setCurrency(response.data.result.currencyCode);
                    setCanWithdraw(response.data.result.canWithdraw);
                    setHasMoreWithdrawals(withdrawalList.length === withdrawalsPerPage);
                } else {
                    if (response.data.error !== 'verification missing') {
                        setMessage(t('something.went.wrong.error'));
                    }
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    navigate("/auth/logout");
                }
                setMessage(t('something.went.wrong.error'));
            })
            .finally(() => {
                requestsState[overviewRequest] = false;
                setLoader(null);
            });
    };

    const fetchList = async () => {
        if (!requestsState[listRequest]) {
            requestsState[listRequest] = true;
            WithdrawalListRequest({limit: withdrawalsPerPage, offset: withdrawalsOffset + withdrawalsPerPage})
                .then((response) => {
                    if (response.status === 200) {
                        const newWithdrawals = response.data.result.list;
                        setWithdrawals(withdrawals => [...withdrawals, ...newWithdrawals]);
                        setHasMoreWithdrawals(newWithdrawals.length === withdrawalsPerPage);
                        setWithdrawalsOffset(withdrawalsOffset + withdrawalsPerPage);
                    } else {
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    } else {
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .finally(() => {
                    requestsState[listRequest] = false;
                });
        }
    };

    useEffect(() => {
        setTitle(t('office.account.withdrawal.subtitle'));

        if (!requestsState[overviewRequest]) {
            requestsState[overviewRequest] = true;
            fetchOverview();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message) {
            FireNotification(message, false);
            setMessage('');
        }
    }, [message]);

    return (
        <div className="WithdrawalWrapper TransactionWrapper">
            <h2 className="TabTitle">{title}</h2>
            <br/>

            <div className="WithdrawalControlWrapper">
                {loader && loader}
                {minWithdrawAmount && currency && (<div className="SystemNotifySection">
                    <SystemPM
                        html={t('office.account.withdrawal.min.amount.message', {
                            amount: AmountFormat(minWithdrawAmount, currency),
                        })}
                    />
                </div>)}
                <Button
                    text={t('office.account.withdrawal.new.button')}
                    onClick={onCreate}
                    disabled={!canWithdraw}
                    title={
                        !canWithdraw
                            ? (
                                minWithdrawAmount && currency
                                    ? t('office.account.withdrawal.min.amount.message', {
                                        amount: AmountFormat(minWithdrawAmount, currency),
                                    }) : t('office.account.withdrawal.no.money.message')
                            )
                            : ''
                    }
                />
            </div>
            <div className="WithdrawalListWrapper">
                {withdrawals && (
                    <InfiniteScroll
                        dataLength={withdrawals.length}
                        next={fetchList}
                        hasMore={hasMoreWithdrawals}
                        loader={<div><Loader fullScreen={false}/></div>}
                        endMessage={""}
                    >
                        <div>
                            <div className="TransactionList">
                                {withdrawals.length > 0 ? (
                                    ((t) => {
                                        let lastDate = null;
                                        let currentLang = GetCurrentLanguage();
                                        return withdrawals.map((transaction, index) => {
                                            const transactionDate = new Date(transaction.createdAt * 1000);
                                            const formattedDate = transactionDate.toLocaleDateString(currentLang);
                                            const showDate = formattedDate !== lastDate;
                                            lastDate = formattedDate;

                                            return (
                                                <>
                                                    {showDate && (
                                                        <div className="DateHeader">
                                                            <div className="DateContent">
                                                                {formattedDate}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="TransactionItem">
                                                        <div className="TransactionGeneral">
                                                            <div className="TransactionDir">
                                                                {<MdOutlineNorthWest/>}
                                                            </div>
                                                            <div className="TransactionInfo">
                                                                <span className="TransactionTitle">
                                                                    {transaction.walletName}
                                                                </span>
                                                                <span className="TransactionStatus">
                                                                    {transaction.isPending && t('office.account.withdrawal.status.pending')}
                                                                    {transaction.isDone && t('office.account.withdrawal.status.done')}
                                                                    {transaction.isRejected && (
                                                                        <Tooltip
                                                                            className="StatusTooltip"
                                                                            title={transaction.rejectNote}
                                                                            enterTouchDelay={0}
                                                                            leaveTouchDelay={5000}
                                                                            arrow={true}
                                                                            placement={'bottom'}
                                                                        >
                                                                            {t('office.account.withdrawal.status.rejected')}
                                                                            <IconButton style={{
                                                                                padding: '0',
                                                                                marginTop: '2px',
                                                                                verticalAlign: 'top',
                                                                            }}>
                                                                                <MdInfoOutline/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                </span>
                                                                <span className="TransactionDate">
                                                                    {transactionDate.toLocaleTimeString(currentLang)}
                                                                </span>
                                                            </div>
                                                            <div className="TransactionTotal">
                                                                <span className="TransactionAmount">
                                                                    {AmountFormat(transaction.amount, transaction.currencyCode)}
                                                                </span>
                                                                <span className="TransactionFee">
                                                                    {<Tooltip
                                                                        className="FeeTooltip"
                                                                        title={t('office.account.withdrawal.fee.tooltip')}
                                                                        enterTouchDelay={0}
                                                                        arrow={true}
                                                                        placement={'bottom-start'}
                                                                    >
                                                                        <IconButton style={{
                                                                            padding: '0',
                                                                            marginTop: '2px',
                                                                            verticalAlign: 'top'
                                                                        }}>
                                                                            <MdInfoOutline/>
                                                                        </IconButton>
                                                                        <span className="FeeAmount">
                                                                            {AmountFormat(transaction.fee, transaction.currencyCode)}
                                                                        </span>
                                                                    </Tooltip>}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="TransactionMeta">
                                                            {<Tooltip
                                                                title={t('office.account.withdrawal.id.tooltip')}
                                                                enterTouchDelay={0}
                                                                arrow={true}
                                                                placement={'bottom-start'}
                                                            >
                                                                <span className="TransactionIdentifier">
                                                                    {transaction.id}
                                                                </span>
                                                                <IconButton style={{padding: '0', verticalAlign: 'top'}}>
                                                                    <MdInfoOutline/>
                                                                </IconButton>
                                                            </Tooltip>}
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        });
                                    })(t)
                                ) : (loader === null && withdrawals.length === 0 && !hasMoreWithdrawals && (
                                    <span className="NoData">
                                        {t('office.account.withdrawal.no.withdrawals')}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </InfiniteScroll>
                )}
            </div>
        </div>
    );
};

export default WithdrawalList;
