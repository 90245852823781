import React from "react";
import { Navigate } from "react-router";
import getSymbolFromCurrency from "currency-symbol-map";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import TextField from "../field/text-field/TextField";
import HiddenField from "../field/hidden-field/HiddenField";
import Button from "../../button/Button";
import { Make as MakePaymentData } from "./payment-data/Factory.ts";
import { PaymentDataFactory } from "./payment-data/Factory.ts";
import PaymentForm from "./payment-form/PaymentForm";
import { PaymentMethod } from "./payment-data/Method.ts";
import Loader from "../../loader/Loader";
import LandingPay from "../../api/client/landing/LandingPay";
import LandingPaySuccessTemplate from "../../template/landing-pay-success/LandingPaySuccessTemplate";
import Checkbox from "../field/Checkbox";
import { AmountFormat } from "../../formatter/Formatter";

import "./PayForm.css";

const LandingScreen = 1;
const PaymentScreen = 2;
const SuccessScreen = 3;

const PaymentMode = {
    Classic: 1,
    Redirect: 2,
};

class PayForm extends React.Component {
    state = {
        screen: LandingScreen,
        method: "",
        selectedPresetId: "",
        customAmount: "",
        isFeePaid: null,
        isFeeChecked: null,
        feeAmount: 0,
        customAmountError: "",
        redirect: "",
        externalRedirect: "",
        loader: null,
    };
    paymentData: PaymentDataFactory|null = null;

    constructor(props) {
        super(props);
        this.scrollableBlockRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.externalRedirect !== this.state.externalRedirect && this.state.externalRedirect) {
            window.location.href = this.state.externalRedirect;
        }

        if (prevProps.paidFeeFeature && prevProps.paidFeeFeature.defaultPreset !== this.state.isFeePaid) {
            const defaultFeePreset = this.props.paidFeeFeature.defaultPreset;
            this.setState({
                isFeeChecked: defaultFeePreset,
                isFeePaid: defaultFeePreset,
            });
            this.calculateFee(this.getAmount());
        }
    }

    getCustomAmountError = (amount) => {
        if (!amount) {
            return this.props.t('pay.amount.required');
        }

        if (amount < this.props.minAmount) {
            return this.props.t('pay.amount.greater.field.message', {amount: this.props.minAmount});
        }

        if (amount > this.props.maxAmount) {
            return this.props.t('pay.amount.less.field.message', {amount: this.props.maxAmount});
        }

        const amountRegex = new RegExp(`^[0-9]+([.][0-9]{0,${this.props.amountPrecision}})?$`);
        if (!amountRegex.test(amount)) {
            return this.props.t('pay.amount.invalid.format.field.message', {precision: this.props.amountPrecision});
        }

        return "";
    };

    validateCustomAmount = (value) => {
        const error = this.getCustomAmountError(value);
        this.setState({customAmountError: error});

        return !error;
    };

    onChoosePreset = (idx) => {
        this.setState({
            customAmount: "",
            customAmountError: "",
            selectedPresetId: idx,
        });
        this.calculateFee(this.props.amountPresetList[idx]);
    };

    onChangeFeeFeature = (e) => {
        if (!this.props.paidFeeFeature) {
            return;
        }

        this.setState({
            isFeeChecked: e.target.checked,
        });
        this.calculateFee(this.getAmount());
    };

    getFee = () => {
        return this.state.isFeeChecked ? this.state.feeAmount : 0;
    };

    calculateFee = (amount) => {
        if (!this.props.paidFeeFeature) {
            return;
        }

        const factor = Math.pow(10, this.props.paidFeeFeature.precision);
        const commissionRate = this.props.paidFeeFeature.percent / 100;
        const commissionFixAmount = this.props.paidFeeFeature.amount;
        const totalAmount = (parseFloat(amount) + commissionFixAmount) / (1 - commissionRate);
        const feeAmount = Math.ceil((totalAmount - parseFloat(amount)) * factor) / factor;

        this.setState({ feeAmount: feeAmount });
    };

    onChangeCustomAmount = (event) => {
        const amount = event.target.value;
        const prefix = this.getCustomAmountPrefix();
        const fieldRegex = this.getCustomAmountPrefixRegexp();
        const clearAmount = this.getClearAmount(amount);

        this.setState({
            customAmount: fieldRegex.test(amount) && !!clearAmount
                ? amount
                : !clearAmount
                    ? clearAmount
                    : `${prefix}${clearAmount}`,
            selectedPresetId: -1,
        });

        if (this.validateCustomAmount(clearAmount)) {
            this.calculateFee(clearAmount);
        } else {
            this.calculateFee(0);
        }
    };

    onFocusCustomAmount = () => {
        if (this.scrollableBlockRef.current) {
            const scrollWidth = this.scrollableBlockRef.current.scrollWidth;
            const clientWidth = this.scrollableBlockRef.current.clientWidth;
            this.scrollableBlockRef.current.scrollTo({
                left: scrollWidth - clientWidth,
                behavior: 'smooth'
            });
        }
    };

    getCustomAmountPrefix = () => {
        return `${getSymbolFromCurrency(this.props.currency)} `;
    };

    getCustomAmountPrefixRegexp = () => {
        const prefix = this.getCustomAmountPrefix();
        return new RegExp(`^\\${prefix}`);
    };

    getClearAmount = amount => {
        return amount.replace(this.getCustomAmountPrefixRegexp(), "");
    };

    isValidAmount = () => {
        const amount = this.getAmount();
        return this.state.selectedPresetId === -1
            ? !this.getCustomAmountError(amount)
            : this.state.selectedPresetId !== "";
    };

    setDefaultPreset = () => {
        const defaultPreset = this.props.amountPresetList.length > 1 ? 1 : 0;
        this.setState({selectedPresetId: defaultPreset});
        this.calculateFee(this.props.amountPresetList[defaultPreset]);
    };

    onGoToPay = (e) => {
        e.preventDefault();

        if (this.state.selectedPresetId === "") {
            return;
        } else if (
            this.state.selectedPresetId === -1
            && !this.validateCustomAmount(this.getClearAmount(this.state.customAmount))
        ) {
            return;
        }

        if (this.props.mode === PaymentMode.Classic) {
            this.setState({
                method: PaymentMethod.Card,
                screen: PaymentScreen,
            });
        } else if (this.props.mode === PaymentMode.Redirect) {
            this.onPaymentRedirect();
        }
    };

    onPaymentStepCancel = (e) => {
        e.preventDefault();
        this.setState({screen: LandingScreen});
    };

    onPaymentStepSuccess = () => {
        this.setDefaultPreset();
        this.setState({screen: SuccessScreen});
    };

    onPaymentRedirect = () => {
        let redirectInProcess = false;
        this.setState({loader: Loader()});
        LandingPay({
            cashboxUuid: this.props.uuid,
            method: PaymentMethod.External,
            currency: this.props.currency,
            amount: parseFloat(this.getAmount()) + parseFloat(this.getFee()),
            containsPaidFee: this.state.isFeeChecked,
        })
            .then((response) => {
                if (response.data.code === 200) {
                    redirectInProcess = true;
                    this.setState({externalRedirect: response.data.result.redirectUrl});
                } else if (response.data.code === 400 && response.data.details.length !== 0) {
                    this.setState({message: this.props.t('something.went.wrong.error')});
                } else {
                    this.setState({message: this.props.t('something.went.wrong.error')});
                }
            })
            .catch(error => {
                if (error.response?.status === 404) {
                    this.setState({redirect: "/404"});
                } else {
                    this.setState({message: this.props.t('something.went.wrong.error')});
                }
            })
            .finally(() => {
                !redirectInProcess && this.setState({loader: null});
            });
    };

    renderLandingScreen() {
        const isRedirectMode = this.props.mode === PaymentMode.Redirect;

        return (
            <div className="LandingScreen">
                {this.state.redirect && <Navigate to={this.state.redirect} />}

                <form
                    autoComplete="off"
                >
                    <div className="AmountVariantsWrapper" ref={this.scrollableBlockRef}>
                        {this.props.amountPresetList?.map((preset, idx) => {
                            if (this.state.selectedPresetId === "") {
                                this.setDefaultPreset();
                            }

                            return (
                                <div className="AmountSelector Preset">
                                    <HiddenField
                                        name={`amount${idx}`}
                                        label={`${getSymbolFromCurrency(this.props.currency)} ${preset}`}
                                        labelclassname={idx === this.state.selectedPresetId ? 'selected' : ''}
                                        labelonclick={this.onChoosePreset.bind(null, idx)}
                                        value={preset}
                                    />
                                </div>
                            );
                        })}

                        <div className="AmountSelector Custom">
                            <TextField
                                name="customAmount"
                                value={this.state.customAmount}
                                error={this.state.customAmountError}
                                onChange={this.onChangeCustomAmount}
                                onFocus={this.onFocusCustomAmount}
                                placeholder={`${getSymbolFromCurrency(this.props.currency)} 0.00`}
                                className={this.state.selectedPresetId === -1 && this.isValidAmount() ? 'selected' : ''}
                            />
                        </div>
                    </div>
                    <div className="AmountOptionsWrapper">
                        <div className="CommissionCheckboxWrapper" data-test={this.state.isFeePaid}>
                            {null !== this.state.isFeePaid && this.isValidAmount() && <Checkbox
                                label={this.props.t('pay.fee.paid.checkbox.text', {
                                    amount: AmountFormat(this.state.feeAmount ?? 0, this.props.currency),
                                })}
                                checked={null !== this.state.isFeeChecked ? this.state.isFeeChecked : this.state.isFeePaid}
                                onChange={this.onChangeFeeFeature}
                            />}
                        </div>
                    </div>

                    <div className="FormFooterSection">
                        <div className="FormButtonsWrapper">
                            <Button
                                text={
                                    isRedirectMode
                                        ? this.props.t('pay.redirect.button')
                                        : this.props.t('continue.button')
                                }
                                onClick={this.onGoToPay}
                            />
                        </div>

                        {isRedirectMode && <div className="FormAgreementWrapper">
                            <span>
                                {this.props.t('pay.agreement.text')}<br/>
                                <Link to={'/terms-and-conditions'} target="_blank">{this.props.t('pay.agreement.link')}</Link>
                            </span>
                        </div>}
                    </div>
                </form>
            </div>
        );
    }

    renderPaymentScreen() {
        return (
            <div className="PaymentScreen">
                <PaymentForm
                    cashboxUuid={this.props.uuid}
                    currency={this.props.currency}
                    paymentData={MakePaymentData(this.state.method)}
                    paymentMethods={this.props.paymentMethods}
                    amount={this.getAmount()}
                    fee={this.getFee()}
                    onCancel={this.onPaymentStepCancel}
                    onSuccess={this.onPaymentStepSuccess}
                />
            </div>
        );
    }

    renderSuccessScreen() {
        return (
            <LandingPaySuccessTemplate
                text={this.props.t('pay.congratulation')}
            />
        );
    }

    getAmount() {
        return this.state.selectedPresetId !== -1
            ? this.props.amountPresetList[this.state.selectedPresetId]
            : this.getClearAmount(this.state.customAmount);
    }

    render() {
        return (
            <div className="PayForm">
                {this.state.loader && this.state.loader}
                {this.state.screen === LandingScreen && this.renderLandingScreen()}
                {this.state.screen === PaymentScreen && !!this.state.method && this.renderPaymentScreen()}
                {this.state.screen === SuccessScreen && this.renderSuccessScreen()}
            </div>
        );
    }
}

export default withTranslation()(PayForm);
