const keyName = 'auth-token';

const GetAuthToken = () => (
    localStorage.getItem(keyName)
);

const SetAuthToken = (token) => (
    localStorage.setItem(keyName, token)
);

const ResetAuthToken = () => (
    localStorage.removeItem(keyName)
);

export { GetAuthToken, SetAuthToken, ResetAuthToken };
