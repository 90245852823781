import { Link } from "react-router-dom";
import {MdArrowBackIosNew} from "react-icons/md";

import "./PrevButton.css";

const PrevButton = ({route, classWrapper = 'PrevButtonWrapper'}) => {
    return (
        <div className="PrevButtonSection">
            <div className={classWrapper}>
                <div className="PrevButtonBlock">
                    <Link to={route}>
                        <MdArrowBackIosNew
                            style={{
                                color: "rgba(255, 255, 255, 0.6)",
                                fontSize: '32px',
                            }}
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PrevButton;
