import Field from "../Field";

const EmailField = ({id, value, name, label, error, onChange, onBlur, autoComplete, required, disabled}) => {
    return (
        <div className="EmailField">
            <Field
                {...(id ? {id} : {})}
                type="email"
                name={name}
                value={value}
                {...(autoComplete ? {autoComplete} : {})}
                label={label}
                error={error}
                onChange={onChange}
                onBlur={onBlur}
                {...(required ? {required} : {})}
                {...(disabled ? {disabled} : {})}
            />
        </div>
    );
}

export default EmailField;
