import { ResetAuthToken } from "../../../components/storages/auth-token-storage/AuthTokenStorage";
import {Navigate} from "react-router";
import {useEffect} from "react";

const Logout = () => {
    useEffect(() => {
        document.title = "Log out";
    }, []);

    ResetAuthToken();

    return <Navigate to='/auth/login'></Navigate>;
};

export default Logout;
