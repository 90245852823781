import React, { useState, useEffect } from 'react';
import { isMobile, isDesktop, isTablet, isIOS, isMacOs } from 'react-device-detect';

const useDeviceInfo = () => {
    const isStandalone = () => {
        return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;
    };
    const [deviceInfo, setDeviceInfo] = useState({
        isMobile: isMobile,
        isTablet: isTablet,
        isDesktop: isDesktop || (!isMobile && !isTablet),
        isIOS: isIOS,
        isMacOs: isMacOs,
        isStandalone: isStandalone(),
    });

    useEffect(() => {
        const handleResize = () => {
            setDeviceInfo({
                isMobile: isMobile,
                isTablet: isTablet,
                isDesktop: isDesktop || (!isMobile && !isTablet),
                isIOS: isIOS,
                isMacOs: isMacOs,
                isStandalone: isStandalone(),
            });
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    return deviceInfo;
};

const DeviceCheckProvider = ({ children }) => {
    const deviceInfo = useDeviceInfo();
    return (
        <DeviceCheckContext.Provider value={deviceInfo}>
            {children}
        </DeviceCheckContext.Provider>
    );
};

const DeviceCheckContext = React.createContext();

export const useDeviceCheck = () => React.useContext(DeviceCheckContext);

export default DeviceCheckProvider;
