import { Rings } from "react-loader-spinner";

import "./Loader.css";

const Loader = ({ fullScreen = true, targetClass = null } = {}) => {
    const wrapperClass = `LoaderWrapper ${targetClass ? `WithTarget ${targetClass}` : ``}`;
    const fullScreenClass = fullScreen ? 'FullScreen' : '';

    return (
        <div className={`${wrapperClass} ${fullScreenClass}`}>
            <Rings
                height="120"
                width="120"
                color="#DBFC08"
                radius="3"
                wrapperStyle={{}}
                wrapperClass="Loader"
                visible={true}
                ariaLabel="rings-loading"
            />
        </div>
    );
};

export default Loader;
