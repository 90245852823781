import { useEffect } from "react";

import Info from "../api/client/account/info/Info";
import { SetAccInfo } from "../storages/account-info-storage/AccountInfoStorage";
import {GetAuthToken, ResetAuthToken} from "../storages/auth-token-storage/AuthTokenStorage";

const infoRequest = 'infoRequest';

let requestsState = [];

const AccountInfo = () => {
    useEffect(() => {
        if (!!GetAuthToken() && !requestsState[infoRequest]) {
            requestsState[infoRequest] = true;

            Info()
                .then((response) => {
                    if (response.status === 200) {
                        SetAccInfo(response.data.result);
                    } else if (response.status === 401) {
                        ResetAuthToken();
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        ResetAuthToken();
                    }
                })
                .finally(() => {
                    requestsState[infoRequest] = false;
                });
        }
    }, []);
};

export default AccountInfo;
