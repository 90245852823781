import Client from "../../Client";
import {GetAuthToken} from "../../../../storages/auth-token-storage/AuthTokenStorage";

const WalletCreate = ({name, type, paymentData}) => {
    return Client(GetAuthToken()).post(
        '/account/wallet/create',
        {
            name: name,
            type: type,
            paymentData: paymentData,
        },
    );
}

export default WalletCreate;
