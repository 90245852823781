import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";

// @ts-ignore
import {PaymentDataFactory} from "../Factory.ts";
// @ts-ignore
import {PaymentData} from "../Entity.ts";
// @ts-ignore
import {CardPaymentData} from "./Card/Entity.ts";
// @ts-ignore
import {PaymentMethod} from "../Method.ts";
import Render from "./Card/Render";
import i18n from "../../../../i18n/Translates/Translates"

const cardNumberMinLength = 13;
const cardNumberMaxLength = 19;

const expMonthLength = 2;
const expMonthMinValue = 1;
const expMonthMaxValue = 12;

const expYearLength = 2;
const expYearMaxGapValue = 10;

const holderMaxLength = 30;

const cvcMinLength = 3;
const cvcMaxLength = 4;

export class Card implements PaymentDataFactory {
    private paymentData: CardPaymentData;
    private t: Function;

    constructor(
        number: string = '',
        expMonth: string = '',
        expYear: string = '',
        holder: string = '',
        cvc: string = '',
    ) {
        this.paymentData = new CardPaymentData(number, expMonth, expYear, holder, cvc);
        this.t = i18n.t.bind(i18n);
    }

    getState(): PaymentData {
        return this.paymentData;
    }

    getMethod(): PaymentMethod {
        return PaymentMethod.Card;
    }

    render(): ReactJSXElement {
        return (
            <Render
                data={this.paymentData}
                onChangeNumber={this.onChangeNumber.bind(this)}
                onChangeExpMonth={this.onChangeExpMonth.bind(this)}
                onChangeExpYear={this.onChangeExpYear.bind(this)}
                onChangeHolder={this.onChangeHolder.bind(this)}
                onChangeCvc={this.onChangeCvc.bind(this)}
            />
        );
    }

    validate(): boolean {
        return !this.validateNumber(this.paymentData.number)
            && !this.validateExpMonth(this.paymentData.expMonth)
            && !this.validateExpYear(this.paymentData.expYear)
            && !this.validateHolder(this.paymentData.holder)
            && !this.validateCvc(this.paymentData.cvc);
    }

    reset(): void {
        this.paymentData = new CardPaymentData('', '', '', '', '');
    }

    onChangeNumber(value: string): string {
        let trimmedValue = value.replace(/\s/g,'');
        this.paymentData.number = trimmedValue;
        return this.validateNumber(trimmedValue);
    }

    onChangeExpMonth(value: string): string {
        this.paymentData.expMonth = value;
        return this.validateExpMonth(value);
    }

    onChangeExpYear(value: string): string {
        this.paymentData.expYear = value;
        return this.validateExpYear(value);
    }

    onChangeHolder(value: string): string {
        this.paymentData.holder = value;
        return this.validateHolder(value);
    }

    onChangeCvc(value: string): string {
        this.paymentData.cvc = value;
        return this.validateCvc(value);
    }

    validateNumber(value: string): string {
        return this.getNumberError(value);
    }

    validateExpMonth(value: string): string {
        return this.getExpMonthError(value);
    }

    validateExpYear(value: string): string {
        return this.getExpYearError(value);
    }

    validateHolder(value: string): string {
        return this.getHolderError(value);
    }

    validateCvc(value: string): string {
        return this.getCvcError(value);
    }

    getNumberError = (value: string) => {
        if (!value) {
            return this.t('required.field.message');
        }

        const regex = /^[0-9]+$/;
        if (!regex.test(value)) {
            return this.t('invalid.card.number.field.message');
        }

        if (value.length < cardNumberMinLength || value.length > cardNumberMaxLength) {
            return this.t('card.number.length.field.message', {
                minLength: cardNumberMinLength,
                maxLength: cardNumberMaxLength,
            });
        }

        return "";
    };

    getExpMonthError = (value: string) => {
        if (!value) {
            return this.t('required.field.message');
        }

        const regex = /^[0-9]+$/;
        if (!regex.test(value)) {
            return this.t('invalid.card.exp.month.field.message');
        }

        if (value.length !== expMonthLength) {
            return this.t('card.exp.month.length.field.message', {length: expMonthLength});
        }

        if (parseInt(value) < expMonthMinValue || parseInt(value) > expMonthMaxValue) {
            return this.t('card.exp.month.range.field.message', {
                minValue: expMonthMinValue,
                maxValue: expMonthMaxValue,
            });
        }

        return "";
    };

    getExpYearError = (value: string) => {
        if (!value) {
            return this.t('required.field.message');
        }

        const regex = /^[0-9]+$/;
        if (!regex.test(value)) {
            return this.t('invalid.card.exp.year.field.message');
        }

        if (value.length !== expYearLength) {
            return this.t('card.exp.year.length.field.message', {length: expYearLength});
        }

        const currentShortYear = (new Date().getFullYear()) % 100;
        if (parseInt(value) < currentShortYear) {
            return this.t('card.exp.year.expired.field.message');
        }
        if (parseInt(value) > (currentShortYear + expYearMaxGapValue)) {
            return this.t('card.exp.year.invalid.expiration.field.message');
        }

        return "";
    };

    getHolderError = (value: string) => {
        if (!value) {
            return this.t('required.field.message');
        }

        const regex = /^[a-zA-Z- 0-9]+$/;
        if (!regex.test(value)) {
            return this.t('invalid.card.holder.field.message');
        }

        if (value.length > holderMaxLength) {
            return this.t('card.holder.max.length.field.message', {maxLength: expYearLength});
        }

        return "";
    };

    getCvcError = (value: string) => {
        if (!value) {
            return;
        }

        const regex = /^[0-9]+$/;
        if (!regex.test(value)) {
            return this.t('invalid.card.cvc.field.message');
        }

        if (value.length < cvcMinLength || value.length > cvcMaxLength) {
            return this.t('card.cvc.length.field.message', {
                minLength: cvcMinLength,
                maxLength: cvcMaxLength,
            });
        }

        return "";
    };
}
