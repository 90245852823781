import React from "react";
import { Navigate } from "react-router";
import { withTranslation } from "react-i18next";

import TextField from "../field/text-field/TextField";
import Button from "../../button/Button";
import Loader from "../../loader/Loader";
import {FireNotification} from "../../notification/Notification";
import BranchInviteCreate from "../../api/client/account/branch-invite/BranchInviteCreate";

import "./BranchInviteForm.css";

const emailMaxLength = 64;

class BranchInviteForm extends React.Component {
    state = {
        email: "",
        emailError: "",
        message: "",
        isSuccess: null,
        redirect: "",
        loader: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            email: this.props.email ? this.props.model.email : "",
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.model !== prevProps.model) {
            this.updateFormData();
        }

        if (this.state.message && prevState.message !== this.state.message) {
            FireNotification(this.state.message, this.state.isSuccess);
            this.setState({message: ''});
        }
    }

    updateFormData() {
        const { model } = this.props;
        this.setState({
            email: model ? model.email : "",
        });
    }

    getEmailError = email => {
        if (!email) {
            return this.props.t('required.field.message');
        }

        const valueRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
        if (!valueRegex.test(email)) {
            return this.props.t('office.branch.invite.form.invalid.email');
        }

        if (email.length > emailMaxLength) {
            return this.props.t('office.branch.invite.form.email.max.length.field.message', {
                maxLength: emailMaxLength,
            });
        }

        return "";
    };

    validateEmail = (value) => {
        const error = this.getEmailError(value);
        this.setState({emailError: error});

        return !error;
    };

    onChangeEmail = (event) => {
        this.setState({email: event.target.value});
        this.validateEmail(event.target.value);
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (!this.validateEmail(this.state.email)) {
            return;
        }

        if (this.props.branchId) {
            this.setState({loader: Loader()});
            this.createRecord(this.props.branchId, e);
        }
    };

    createRecord = (branchId, e) => {
        BranchInviteCreate({
            branchId: parseInt(branchId),
            email: this.state.email,
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.result === true) {
                        e.target.reset();

                        this.setState({
                            message: this.props.t('office.branch.invite.form.create.success.message'),
                            isSuccess: true,
                            email: "",
                            emailError: "",
                        });
                        this.props.onSubmit && this.props.onSubmit();
                    } else {
                        this.setState({
                            message: this.props.t('something.went.wrong.error'),
                            isSuccess: false,
                        });
                    }
                } else {
                    this.setState({
                        message: this.props.t('something.went.wrong.error'),
                        isSuccess: false,
                    });
                }
            })
            .catch(error => {
                if (error) {
                    if (error.response?.status === 401) {
                        this.setState({redirect: "/auth/logout"})
                    }
                    this.setState({
                        message: this.props.t('something.went.wrong.error'),
                        isSuccess: false,
                    });
                }
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    render() {
        return (
            <div className="BranchInviteForm">
                {this.state.loader && this.state.loader}
                {this.state.redirect && <Navigate to={this.state.redirect} />}

                <form
                    onSubmit={this.onSubmit}
                    autoComplete="off"
                >
                    <TextField
                        name="name"
                        label={this.props.t('office.branch.invite.form.email.field.label')}
                        value={this.state.email}
                        error={this.state.emailError}
                        onChange={this.onChangeEmail}
                    />

                    <div className="FormButtonsWrapper">
                        <Button
                            text={this.props.t('office.system.form.submit.button')}
                            disabled={this.props.disabled}
                        />
                        <Button
                            text={this.props.t('office.system.form.cancel.button')}
                            onClick={this.props.onCancel}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(BranchInviteForm);
