import React from "react";
import { Navigate } from "react-router";
import { withTranslation } from "react-i18next";

import TextField from "../field/text-field/TextField";
import Button from "../../button/Button";
import Loader from "../../loader/Loader";
import {FireNotification} from "../../notification/Notification";
import {BranchType, GetBranchTypeLabel, GetBranchTypeList, IsBranchType} from "../../branch/type/BranchType";
import SelectField from "../field/select-field/SelectField";
import BranchUpdate from "../../api/client/account/branch/BranchUpdate";
import BranchCreate from "../../api/client/account/branch/BranchCreate";

import './BranchForm.css';

const nameMaxLength = 255;
const publicNameMaxLength = 255;
const addressMaxLength = 255;

class BranchForm extends React.Component {
    state = {
        name: "",
        nameError: "",
        publicName: "",
        publicNameError: "",
        address: "",
        addressError: "",
        type: "",
        typeError: "",
        message: "",
        isSuccess: null,
        redirect: "",
        loader: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.model ? this.props.model.name : "",
            publicName: this.props.model ? this.props.model.publicName : "",
            address: this.props.model ? this.props.model.address : "",
            type: this.props.model ? this.prepareType(this.props.model.type) : this.prepareType(BranchType.Other),
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.model !== prevProps.model) {
            this.updateFormData();
        }

        if (this.state.message && prevState.message !== this.state.message) {
            FireNotification(this.state.message, this.state.isSuccess);
            this.setState({message: ''});
        }
    }

    updateFormData() {
        const { model } = this.props;
        this.setState({
            name: model ? model.name : "",
            publicName: model ? model.publicName : "",
            address: model ? model.address : "",
            type: model ? this.prepareType(model.type) : null,
        });
    }

    getNameError = name => {
        if (!name) {
            return this.props.t('required.field.message');
        }

        const valueRegex = /^[a-zA-Z0-9- ]+$/;
        if (!valueRegex.test(name)) {
            return this.props.t('office.branches.form.invalid.name');
        }

        if (name.length > nameMaxLength) {
            return this.props.t('office.branches.form.name.max.length.field.message', {
                maxLength: nameMaxLength,
            });
        }

        return "";
    };

    getPublicNameError = publicName => {
        if (!publicName) {
            return this.props.t('required.field.message');
        }

        const valueRegex = /^[a-zA-Z0-9- ]+$/;
        if (!valueRegex.test(publicName)) {
            return this.props.t('office.branches.form.invalid.publicName');
        }

        if (publicName.length > publicNameMaxLength) {
            return this.props.t('office.branches.form.publicName.max.length.field.message', {
                maxLength: publicNameMaxLength,
            });
        }

        return "";
    };

    getAddressError = address => {
        const valueRegex = /^[a-zA-Z0-9-., ]+?$/;
        if (!valueRegex.test(address)) {
            return this.props.t('office.branches.form.invalid.address');
        }

        if (address.length > addressMaxLength) {
            return this.props.t('office.branches.form.address.max.length.field.message', {
                maxLength: addressMaxLength,
            });
        }

        return "";
    };

    getTypeError = type => {
        if (!IsBranchType(type)) {
            return this.props.t('office.branches.form.invalid.type');
        }

        return "";
    };

    validateName = (value) => {
        const error = this.getNameError(value);
        this.setState({nameError: error});

        return !error;
    };

    validatePublicName = (value) => {
        const error = this.getPublicNameError(value);
        this.setState({publicNameError: error});

        return !error;
    };

    validateAddress = (value) => {
        const error = this.getAddressError(value);
        this.setState({addressError: error});

        return !error;
    };

    validateType = (value) => {
        const error = this.getTypeError(value);
        this.setState({typeError: error});

        return !error;
    };

    onChangeName = (event) => {
        this.setState({name: event.target.value});
        this.validateName(event.target.value);
    };

    onChangePublicName = (event) => {
        this.setState({publicName: event.target.value});
        this.validatePublicName(event.target.value);
    };

    onChangeAddress = (event) => {
        this.setState({address: event.target.value});
        this.validateAddress(event.target.value);
    };

    onChangeType = (data) => {
        if (this.validateType(data.value)) {
            this.setState({type: data});
        }
    };

    getAvailableTypes = () => {
        const availableTypes = GetBranchTypeList();

        let map = [];
        for (let i = 0; i < availableTypes.length; i++) {
            map.push(this.prepareType(availableTypes[i]));
        }

        return map;
    };

    prepareType = type => {
        return {value: type, label: this.props.t(GetBranchTypeLabel(type))};
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (
            !this.validateName(this.state.name)
            || !this.validatePublicName(this.state.publicName)
            || !this.validateAddress(this.state.address)
            || !this.validateType(!!this.state.type ? this.state.type.value : null)
        ) {
            return;
        }

        this.setState({loader: Loader()});
        if (this.props.id) {
            this.updateRecord(this.props.id, e);
        } else {
            this.createRecord(e);
        }
    };

    createRecord = (e) => {
        BranchCreate({
            name: this.state.name,
            publicName: this.state.publicName,
            address: this.state.address,
            type: this.state.type.value,
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.result === true) {
                        e.target.reset();
                        this.setState({
                            name: '',
                            redirect: '/office/branches',
                        });
                        this.props.onSubmit && this.props.onSubmit();
                    } else if (response.data.details === 'limit per user') {
                        this.setState({
                            message: this.props.t('something.went.wrong.error'),
                            isSuccess: false,
                        });
                    } else {
                        this.setState({
                            message: this.props.t('something.went.wrong.error'),
                            isSuccess: false,
                        });
                    }
                } else {
                    this.setState({
                        message: this.props.t('something.went.wrong.error'),
                        isSuccess: false,
                    });
                }
            })
            .catch(error => {
                if (error) {
                    if (error.response?.status === 401) {
                        this.setState({redirect: "/auth/logout"})
                    }
                    this.setState({
                        message: this.props.t('something.went.wrong.error'),
                        isSuccess: false,
                    });
                }
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    updateRecord = (id, e) => {
        BranchUpdate({
            id: id,
            name: this.state.name,
            publicName: this.state.publicName,
            address: this.state.address,
            type: this.state.type.value,
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.result === true) {
                        e.target.reset();

                        this.setState({
                            message: this.props.t('office.branches.form.update.success.message'),
                            isSuccess: true,
                            name: response.data.data.name,
                            publicName: response.data.data.publicName,
                            address: response.data.data.address,
                            type: this.prepareType(response.data.data.type),
                        });
                        this.props.onSubmit && this.props.onSubmit();
                    } else {
                        this.setState({
                            message: this.props.t('something.went.wrong.error'),
                            isSuccess: false,
                        });
                    }
                } else {
                    this.setState({
                        message: this.props.t('something.went.wrong.error'),
                        isSuccess: false,
                    });
                }
            })
            .catch(error => {
                if (error) {
                    if (error.response?.status === 401) {
                        this.setState({redirect: "/auth/logout"})
                    }
                    this.setState({
                        message: this.props.t('something.went.wrong.error'),
                        isSuccess: false,
                    });
                }
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    render() {
        return (
            <div className="BranchForm">
                {this.state.loader && this.state.loader}
                {this.state.redirect && <Navigate to={this.state.redirect} />}

                <form
                    onSubmit={this.onSubmit}
                    autoComplete="off"
                >
                    <TextField
                        name="name"
                        label={this.props.t('office.branches.form.name.field.label')}
                        value={this.state.name}
                        error={this.state.nameError}
                        onChange={this.onChangeName}
                    />

                    <TextField
                        name="publicName"
                        label={this.props.t('office.branches.form.publicName.field.label')}
                        value={this.state.publicName}
                        error={this.state.publicNameError}
                        onChange={this.onChangePublicName}
                    />

                    <TextField
                        name="address"
                        label={this.props.t('office.branches.form.address.field.label')}
                        value={this.state.address}
                        error={this.state.addressError}
                        onChange={this.onChangeAddress}
                    />

                    <SelectField
                        name="type"
                        label={this.props.t('office.branches.form.type.field.label')}
                        value={this.state.type}
                        error={this.state.typeError}
                        placeholder={this.props.t('office.branches.form.type.field.placeholder')}
                        options={this.getAvailableTypes()}
                        onChange={this.onChangeType}
                    />

                    <div className="FormButtonsWrapper">
                        <Button
                            text={this.props.t('office.system.form.submit.button')}
                            disabled={this.props.disabled}
                        />
                        <Button
                            text={this.props.t('office.system.form.cancel.button')}
                            onClick={this.props.onCancel}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(BranchForm);
