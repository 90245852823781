import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

// @ts-ignore
import { PaymentDataFactory } from "../Factory.ts";
// @ts-ignore
import { PaymentData } from "../Entity.ts";
import Render from "./Iban/Render";
// @ts-ignore
import { IbanPaymentData } from "./Iban/Entity.ts";

const accountNumberMinLength = 15;
const accountNumberMaxLength = 34;

const codeMinLength = 8;
const codeMaxLength = 11;

const nameMaxLength = 255;

export class Iban implements PaymentDataFactory {
    private readonly paymentData: IbanPaymentData;
    private readonly t: Function;

    constructor(
        t: Function,
        accountNumber: string = '',
        fullName: string = '',
        bankCode: string|null = null,
    ) {
        this.t = t;
        this.paymentData = new IbanPaymentData(accountNumber, fullName, bankCode);
    }

    getState(): PaymentData {
        return this.paymentData;
    }

    render(): ReactJSXElement {
        return (
            <Render
                t={this.t}
                data={this.paymentData}
                onChangeAccount={this.onChangeAccountNumber.bind(this)}
                onChangeName={this.onChangeName.bind(this)}
                onChangeCode={this.onChangeCodeNumber.bind(this)}
            />
        );
    }

    validate(): boolean {
        return this.validateAccountNumber(this.paymentData.account) === ''
            && this.validateName(this.paymentData.name) === ''
            && this.validateCodeNumber(this.paymentData.code) === '';
    }

    onChangeAccountNumber(value: string): string {
        this.paymentData.account = value;
        return this.validateAccountNumber(value);
    }

    onChangeName(value: string): string {
        this.paymentData.name = value;
        return this.validateName(value);
    }

    onChangeCodeNumber(value: string): string {
        this.paymentData.code = value;
        return this.validateCodeNumber(value);
    }

    validateAccountNumber(value: string): string {
        return this.getAccountNumberError(value);
    }

    validateName(value: string): string {
        if (!value) {
            return this.t('required.field.message');
        }

        const regex = /^[a-zA-Z- 0-9]+$/;
        if (!regex.test(value)) {
            return this.t('office.payout.method.form.iban.invalid.full.name');
        }

        if (value.length > nameMaxLength) {
            return this.t('office.payout.method.iban.full.name.max.length.field.message', {
                maxLength: nameMaxLength,
            });
        }

        return '';
    }

    validateCodeNumber(value: string): string {
        if (!value) {
            return;
        }

        const regex = /^[A-Z0-9]+$/;
        if (!regex.test(value)) {
            return this.t('office.payout.method.form.iban.invalid.swift.code');
        }

        if (value.length < codeMinLength || value.length > codeMaxLength) {
            return this.t('office.payout.method.iban.full.name.max.length.field.message', {
                minLength: codeMinLength,
                maxLength: codeMaxLength,
            });
        }

        return '';
    }

    getAccountNumberError = (value: string) => {
        if (!value) {
            return this.t('required.field.message');
        }

        const regex = /^[A-Z0-9]+$/;
        if (!regex.test(value)) {
            return this.t('office.payout.method.form.iban.invalid.account.number');
        }

        if (value.length < accountNumberMinLength || value.length > accountNumberMaxLength) {
            return this.t('office.payout.method.iban.account.number.max.length.field.message', {
                minLength: accountNumberMinLength,
                maxLength: accountNumberMaxLength,
            });
        }

        return '';
    };
}
