import React from "react";
import { Navigate } from "react-router";
import { withTranslation } from "react-i18next";

import TextField from "../field/text-field/TextField";
import Button from "../../button/Button";
import WalletCreate from "../../api/client/account/wallet/WalletCreate";
import SelectField from "../field/select-field/SelectField";
import { GetLabel, PaymentDataType } from "./payment-data/Type.ts";
import { Make as MakePaymentData } from "./payment-data/Factory.ts";
import { PaymentDataFactory } from "./payment-data/Factory.ts";
import Loader from "../../loader/Loader";
import {FireNotification} from "../../notification/Notification";

import './PayoutMethodForm.css';

const nameMaxLength = 255;

const availableTypes = [
    PaymentDataType.Iban,
];

class PayoutMethodForm extends React.Component {
    state = {
        name: "",
        nameError: "",
        type: "",
        typeError: "",
        message: "",
        isSuccess: null,
        redirect: "",
        loader: null,
    };
    paymentData: PaymentDataFactory|null = null;

    componentDidUpdate(prevProps, prevState) {
        if (this.state.message && prevState.message !== this.state.message) {
            FireNotification(this.state.message, this.state.isSuccess);
            this.setState({message: ''});
        }
    };

    getNameError = name => {
        if (!name) {
            return this.props.t('required.field.message');
        }

        const nameRegex = /^[a-zA-Z0-9- ]+$/;
        if (!nameRegex.test(name)) {
            return this.props.t('office.payout.method.form.invalid.name');
        }

        if (name.length > nameMaxLength) {
            return this.props.t('office.payout.method.name.max.length.field.message', {
                maxLength: nameMaxLength,
            });
        }

        return "";
    };

    getTypeError = type => {
        if (!type) {
            return this.props.t('required.field.message');
        }

        if (!availableTypes.includes(type)) {
            return this.props.t('office.payout.method.form.invalid.type');
        }

        return "";
    };

    validateName = (value) => {
        const error = this.getNameError(value);
        this.setState({nameError: error});

        return !error;
    };

    validateType = (value) => {
        const error = this.getTypeError(value);
        this.setState({typeError: error});

        return !error;
    };

    onChangeName = (event) => {
        this.setState({name: event.target.value});
        this.validateName(event.target.value);
    };

    onChangeType = (data) => {
        this.setState({type: data});
        if (this.validateType(data.value)) {
            this.paymentData = MakePaymentData(data.value, this.props.t);
        }
    };

    getAvailableTypes = (availableTypes) => {
        let map = [];
        if (!availableTypes) {
            return map;
        }
        for (let i = 0; i < availableTypes.length; i++) {
            map.push({value: availableTypes[i], label: GetLabel(availableTypes[i])});
        }
        return map;
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (
            !this.validateName(this.state.name)
            || !this.validateType(this.state.type.value)
            || (!this.paymentData || !this.paymentData.validate())
        ) {
            return;
        }

        this.setState({loader: Loader()});
        WalletCreate({
            name: this.state.name,
            type: this.state.type.value,
            paymentData: this.paymentData.getState(),
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.result === true) {
                        e.target.reset();
                        this.setState({
                            isSuccess: true,
                            message: this.props.t('office.system.form.success.saved.message'),
                            name: "",
                            redirect: "/office/payout-methods",
                        });
                    } else if (response.data.details === 'limit per user') {
                        this.setState({
                            message: this.props.t('office.payout.method.form.exceeded.limit.message'),
                            isSuccess: false,
                        });
                    } else if (response.data.details.message === 'incorrect payment data') {
                        this.setState({
                            message: this.props.t('office.payout.method.form.incorrect.format.data.message'),
                            isSuccess: false,
                        });
                    } else {
                        this.setState({
                            message: this.props.t('something.went.wrong.error'),
                            isSuccess: false,
                        });
                    }
                } else {
                    this.setState({
                        message: this.props.t('something.went.wrong.error'),
                        isSuccess: false,
                    });
                }
            })
            .catch(error => {
                if (error) {
                    if (error.response?.status === 401) {
                        this.setState({redirect: "/auth/logout"})
                    }
                    this.setState({
                        message: this.props.t('something.went.wrong.error'),
                        isSuccess: false,
                    });
                }
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    render() {
        return (
            <div className="WalletForm">
                {this.state.loader && this.state.loader}
                {this.state.redirect && <Navigate to={this.state.redirect} />}

                <form
                    onSubmit={this.onSubmit}
                    encType="multipart/form-data"
                    autoComplete="off"
                >
                    <TextField
                        name="name"
                        label={this.props.t('office.payout.method.form.name.field.label')}
                        value={this.state.name}
                        error={this.state.nameError}
                        onChange={this.onChangeName}
                    />
                    <SelectField
                        name="type"
                        label={this.props.t('office.payout.method.form.type.field.label')}
                        value={this.state.type}
                        error={this.state.typeError}
                        placeholder={this.props.t('office.payout.method.form.type.field.placeholder')}
                        options={this.getAvailableTypes(availableTypes)}
                        onChange={this.onChangeType}
                    />

                    {this.paymentData && this.paymentData.render()}

                    <div className="FormButtonsWrapper">
                        <Button text={this.props.t('office.system.form.submit.button')} />
                        <Button
                            text={this.props.t('office.system.form.cancel.button')}
                            onClick={this.props.onCancel}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(PayoutMethodForm);
