import {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ChangePasswordForm, { validateRecoveryCode } from "../../../components/form/change-password-form/ChangePasswordForm";
import { CanChangePassword } from "../../../components/api/client/auth/change-password/ChangePassword";
import Loader from "../../../components/loader/Loader";

let requestsState = [];

const ChangePassword = () => {
    const [loader, setLoader] = useState(Loader());
    const { recoveryCode } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('title.change.password');

        function redirectTo404() {
            navigate("/404");
        }

        if (!requestsState[recoveryCode]) {
            requestsState[recoveryCode] = true;
            if (validateRecoveryCode(recoveryCode)) {
                CanChangePassword({recoveryCode: recoveryCode})
                    .then((response) => {
                        if (response.status !== 200 || response.data.result !== true) {
                            redirectTo404();
                        }
                    })
                    .catch(error => {
                        redirectTo404();
                    })
                    .finally(() => {
                        requestsState[recoveryCode] = false;
                        setLoader(null);
                    });
            } else {
                redirectTo404();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {loader && loader}
            <h1>{t('title.change.password')}</h1>
            <br/>
            <ChangePasswordForm recoveryCode={recoveryCode} />
        </div>
    );
};

export default ChangePassword;
