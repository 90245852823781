import Client from "../../Client";
import {GetAuthToken} from "../../../../storages/auth-token-storage/AuthTokenStorage";

const BranchInviteCreate = ({branchId, email}) => {
    return Client(GetAuthToken()).post(
        `/account/branch-invite/create`,
        {
            branchId: branchId,
            email: email,
        },
    );
}

export default BranchInviteCreate;
