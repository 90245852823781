import React, {createRef} from "react";
import { NavLink } from "react-router-dom";
import { Navigate } from "react-router";
import { withTranslation } from "react-i18next";

import PasswordField from "../field/password-field/PasswordField";
import Button from "../../button/Button";
import Login from "../../api/client/auth/login/Login";
import { SetAuthToken } from "../../storages/auth-token-storage/AuthTokenStorage";
import Loader from "../../loader/Loader";
import {FireNotification} from "../../notification/Notification";
import EmailField from "../field/email-field/EmailField";
import {SetAccInfo} from "../../storages/account-info-storage/AccountInfoStorage";

import './LoginForm.css';

const emailMaxLength = 64;
const passwordMinLength = 1;
const passwordMaxLength = 64;

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            message: "",
            email: "",
            emailError: "",
            password: "",
            passwordError: "",
            loader: null,
        };

        this.fakeFormRef = createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.message && prevState.message !== this.state.message) {
            FireNotification(this.state.message, false);
            this.setState({message: ''});
        }
    }

    getEmailError = email => {
        const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

        if (!emailRegex.test(email)) {
            return this.props.t('login.invalid.email.field.message');
        }

        if (email.length > emailMaxLength) {
            return this.props.t('login.email.max.length.field.message', {maxLength: emailMaxLength});
        }

        return "";
    };

    validateEmail = (value) => {
        const error = this.getEmailError(value);
        this.setState({emailError: error});

        return !error;
    };

    getPasswordError = password => {
        const passwordRegex = /^(?=.*[a-zA-Z^\w\s:])(\S)+$/;
        if (!passwordRegex.test(password)) {
            return this.props.t('login.invalid.password.field.message');
        }

        if (password.length < passwordMinLength) {
            return this.props.t('login.password.min.length.field.message', {minLength: passwordMinLength});
        }

        if (password.length > passwordMaxLength) {
            return this.props.t('login.password.max.length.field.message', {maxLength: passwordMaxLength});
        }

        return "";
    };

    validatePassword = (password) => {
        const error = this.getPasswordError(password);
        this.setState({passwordError: error});

        return !error;
    };

    onChangeEmail = (event) => {
        this.setState({email: event.target.value})
        this.validateEmail(event.target.value);
    };

    onChangePassword = (event) => {
        this.setState({password: event.target.value});
        this.validatePassword(event.target.value);
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (!this.validateEmail(this.state.email) || !this.validatePassword(this.state.password)) {
            return;
        }

        this.setState({loader: Loader()});
        Login({email: this.state.email, password: this.state.password})
            .then((response) => {
                e.target.reset();
                if (response.data.code === 200 && response.data.result === true) {
                    SetAuthToken(response.data.auth.token);
                    SetAccInfo(response.data.auth.info);
                    this.setState({authenticated: true});
                    this.fakeFormRef.current.submit();
                } else {
                    if (response.data.code === 400) {
                        if (response.data.details.email) {
                            this.setState({message: this.props.t(response.data.details.email)});
                        }
                        if (response.data.details.password) {
                            this.setState({message: this.props.t(response.data.details.password)});
                        }
                    } else {
                        this.setState({message: this.props.t('something.went.wrong.error')});
                    }
                }
            })
            .catch(error => {
                this.setState({message: this.props.t('something.went.wrong.error')});
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    render() {
        return (
            <div className="LoginFormSection">
                <form onSubmit={this.onSubmit}>
                    {this.state.loader && this.state.loader}
                    {this.state.authenticated && <Navigate to='/office/overview'/>}

                    <div className="LoginForm">
                        <EmailField
                            id="email"
                            name="email"
                            autoComplete="username"
                            label={this.props.t('login.email.field.label')}
                            error={this.state.emailError}
                            onChange={this.onChangeEmail}
                            required
                        />
                        <PasswordField
                            id="password"
                            name="password"
                            autoComplete="current-password"
                            label={this.props.t('login.password.field.label')}
                            error={this.state.passwordError}
                            onChange={this.onChangePassword}
                            required
                        />

                        <div style={{margin: "15px 0"}}>
                            <NavLink
                                style={{
                                    color: "#DBFC08",
                                    margin: "10px",
                                    textDecoration: "none",
                                }}
                                to="/auth/forgot-password"
                            >
                                {this.props.t('login.forgot.password.link')}
                            </NavLink>
                        </div>

                        <div style={{margin: "15px 0"}}>
                            <NavLink
                                style={{
                                    color: "#DBFC08",
                                    margin: "10px",
                                    textDecoration: "none",
                                }}
                                to="/auth/register"
                            >
                                {this.props.t('login.signup.link')}
                            </NavLink>
                        </div>

                        <Button
                            text={this.props.t('office.system.form.submit.button')}
                            type="submit"
                            aria-label="Login"
                        />
                    </div>
                </form>
                <form
                    ref={this.fakeFormRef}
                    // eslint-disable-next-line no-script-url
                    action="javascript:void(0);"
                    method="POST"
                    style={{display: 'none'}}
                >
                    <input type="email" name="email" value={this.state.email} readOnly/>
                    <input type="password" name="password" value={this.state.password} readOnly/>
                    <button type="submit">Submit</button>
                </form>
            </div>
        )
    };
}

export default withTranslation()(LoginForm);
