import Field from "../Field";

const HiddenField = ({name, label, value, labelclassname, labelonclick}) => (
    <div className="HiddenField">
        <Field
            type="hidden"
            name={name}
            label={label}
            labelclassname={labelclassname}
            labelonclick={labelonclick}
            value={value}
        />
    </div>
);

export default HiddenField;
