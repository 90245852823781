import React from "react";
import { Navigate } from "react-router";
import * as mime from "react-native-mime-types";
import { withTranslation } from "react-i18next";

import TextField from "../field/text-field/TextField";
import Button from "../../button/Button";
import PaymentPageCreate from "../../api/client/account/payment-page/PaymentPageCreate";
import PaymentPageUpdate from "../../api/client/account/payment-page/PaymentPageUpdate";
import Loader from "../../loader/Loader";
import {FireNotification} from "../../notification/Notification";
import AvatarField from "../field/avatar-field/AvatarField";

import './QrCodeForm.css';

const titleMaxLength = 255;

const fileTypes = ['jpg', 'jpeg', 'png', 'heic', 'heif'];

class QrCodeForm extends React.Component {
    state = {
        title: "",
        titleError: "",
        avatarImg: null,
        avatar: null,
        avatarPreview: null,
        avatarInProcess: false,
        avatarResetState: true,
        avatarError: "",
        message: "",
        isSuccess: null,
        redirect: "",
        loader: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            title: this.props.model ? this.props.model.title : "",
            avatarImg: this.props.model ? this.props.model.avatar : null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.model !== prevProps.model) {
            this.updateFormData();
        }

        if (this.state.message && prevState.message !== this.state.message) {
            FireNotification(this.state.message, this.state.isSuccess);
            this.setState({message: ''});
        }
    }

    updateFormData() {
        const { model } = this.props;
        this.setState({
            title: model ? model.title : "",
            avatarImg: model ? model.avatar : null,
        });
    }

    getTitleError = title => {
        if (!title) {
            return this.props.t('required.field.message');
        }

        const titleRegex = /^[a-zA-Z0-9- ]+$/;
        if (!titleRegex.test(title)) {
            return this.props.t('office.qr.code.form.invalid.title');
        }

        if (title.length > titleMaxLength) {
            return this.props.t('office.qr.code.form.title.max.length.field.message', {
                maxLength: titleMaxLength,
            });
        }

        return "";
    };

    validateTitle = (value) => {
        const error = this.getTitleError(value);
        this.setState({titleError: error});

        return !error;
    };

    validateAvatar = (value) => {
        const error = this.getAvatarError(value);
        this.setState({avatarError: error});

        return !error;
    };

    onChangeTitle = (event) => {
        this.setState({title: event.target.value});
        this.validateTitle(event.target.value);
    };

    handleImageCropped = (file, image) => {
        this.setState({
            avatar: file,
            avatarPreview: image,
        });
        this.setState({avatarResetState: false});
        this.validateAvatar(file);
    };

    onStartedCrop = () => {
        this.setState({avatarInProcess: true});
    };

    onStoppedCrop = () => {
        this.setState({avatarInProcess: false});
    };

    getAvatarError = avatar => {
        let error = '';

        if (avatar) {
            let fileMimeType = mime.lookup(avatar.name);
            let isValid = false;
            fileTypes.forEach(fileType => {
                if (fileMimeType === mime.lookup(fileType)) {
                    isValid = true;
                }
            });

            if (!isValid) {
                error = this.props.t('office.qr.code.form.avatar.incorrect.type.field.message');
            }
        }

        return error;
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (
            !this.validateTitle(this.state.title)
            || !this.validateAvatar(this.state.avatar)
        ) {
            return;
        }

        this.setState({loader: Loader()});
        if (this.props.id) {
            this.updateRecord(this.props.id, e);
        } else {
            this.createRecord(e);
        }
    };

    createRecord = (e) => {
        PaymentPageCreate({
            title: this.state.title,
            avatar: this.state.avatar,
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.result === true) {
                        e.target.reset();
                        this.setState({
                            name: '',
                            redirect: '/office/qr-codes',
                        });
                        this.props.onSubmit && this.props.onSubmit();
                    } else if (response.data.details === 'limit per user') {
                        this.setState({
                            message: this.props.t('something.went.wrong.error'),
                            isSuccess: false,
                        });
                    } else {
                        this.setState({
                            message: this.props.t('something.went.wrong.error'),
                            isSuccess: false,
                        });
                    }
                } else {
                    this.setState({
                        message: this.props.t('something.went.wrong.error'),
                        isSuccess: false,
                    });
                }
            })
            .catch(error => {
                if (error) {
                    if (error.response?.status === 401) {
                        this.setState({redirect: "/auth/logout"})
                    }
                    this.setState({
                        message: this.props.t('something.went.wrong.error'),
                        isSuccess: false,
                    });
                }
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    updateRecord = (id, e) => {
        PaymentPageUpdate({
            id: id,
            title: this.state.title,
            avatar: this.state.avatar,
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.result === true) {
                        e.target.reset();
                        this.setState({avatar: null});
                        this.setState({avatarResetState: true});

                        this.setState({
                            message: this.props.t('office.qr.code.form.update.success.message'),
                            isSuccess: true,
                            title: response.data.data.title,
                            avatarImg: response.data.data.avatar,
                        });
                        this.props.onSubmit && this.props.onSubmit();
                    } else {
                        this.setState({
                            message: this.props.t('something.went.wrong.error'),
                            isSuccess: false,
                        });
                    }
                } else {
                    this.setState({
                        message: this.props.t('something.went.wrong.error'),
                        isSuccess: false,
                    });
                }
            })
            .catch(error => {
                if (error) {
                    if (error.response?.status === 401) {
                        this.setState({redirect: "/auth/logout"})
                    }
                    this.setState({
                        message: this.props.t('something.went.wrong.error'),
                        isSuccess: false,
                    });
                }
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    render() {
        return (
            <div className="LandingForm">
                {this.state.loader && this.state.loader}
                {this.state.redirect && <Navigate to={this.state.redirect} />}

                <form
                    onSubmit={this.onSubmit}
                    encType="multipart/form-data"
                    autoComplete="off"
                >
                    <TextField
                        name="title"
                        label={this.props.t('office.qr.code.form.title.field.label')}
                        value={this.state.title}
                        error={this.state.titleError}
                        onChange={this.onChangeTitle}
                    />

                    <AvatarField
                        name="avatar"
                        current={this.state.avatarImg}
                        alt={this.state.name}
                        label={this.props.t('office.qr.code.form.avatar.field.label')}
                        error={this.state.avatarError}
                        reset={this.state.avatarResetState}
                        fileTypes={fileTypes}
                        minWidth={200}
                        minHeight={200}
                        onImageCropped={this.handleImageCropped}
                        onStartedCrop={this.onStartedCrop}
                        onStoppedCrop={this.onStoppedCrop}
                    />

                    <div className="FormButtonsWrapper">
                        <Button text={this.props.t('office.system.form.submit.button')} />
                        <Button
                            text={this.props.t('office.system.form.cancel.button')}
                            onClick={this.props.onCancel}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(QrCodeForm);
