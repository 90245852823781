import React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

import EmailField from "../field/email-field/EmailField";
import Button from "../../button/Button";
import ForgotPassword from "../../api/client/auth/forgot-password/ForgotPassword";
import Loader from "../../loader/Loader";
import {FireNotification} from "../../notification/Notification";

import './ForgotPasswordForm.css';

const emailMaxLength = 64;

class ForgotPasswordForm extends React.Component {
    state = {
        message: "",
        isSuccess: null,
        email: "",
        emailError: "",
        loader: null,
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.message && prevState.message !== this.state.message) {
            FireNotification(this.state.message, this.state.isSuccess);
            this.setState({message: ''});
        }
    }

    getEmailError = email => {
        const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

        if (!emailRegex.test(email)) {
            return this.props.t('forgot.password.invalid.email.field.message');
        }

        if (email.length > emailMaxLength) {
            return this.props.t('forgot.password.email.max.length.field.message', {maxLength: emailMaxLength});
        }

        return "";
    };

    validateEmail = (value) => {
        const error = this.getEmailError(value);
        this.setState({emailError: error});

        return !error;
    };

    onChangeEmail = (event) => {
        this.setState({email: event.target.value})
        this.validateEmail(event.target.value);
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (!this.validateEmail(this.state.email)) {
            return;
        }

        this.setState({loader: Loader()});
        ForgotPassword({email: this.state.email})
            .then((response) => {
                if (response.data.code === 200 && response.data.result === true) {
                    this.setState({
                        message: this.props.t('forgot.password.success.message'),
                        isSuccess: true,
                    });
                    e.target.reset();
                } else {
                    if (response.data.code === 400) {
                        if (response.data.details.email) {
                            this.setState({emailError: this.props.t(response.data.details.email)});
                        }
                    } else {
                        this.setState({emailError: this.props.t('something.went.wrong.error')});
                    }
                }
            })
            .catch(error => {
                if (error.response?.status === 404) {
                    this.setState({emailError: this.props.t('forgot.password.email.not.found.message')});
                } else {
                    this.setState({emailError: this.props.t('something.went.wrong.error')});
                }
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    render() {
        return (
            <div className="ForgotPasswordForm">
                {this.state.loader && this.state.loader}

                <form onSubmit={this.onSubmit}>
                    <EmailField
                        id="email"
                        name="email"
                        autoComplete="username"
                        label={this.props.t('forgot.password.email.field.label')}
                        error={this.state.emailError}
                        onChange={this.onChangeEmail}
                        required
                    />

                    <div style={{margin: "15px 0"}}>
                        <NavLink
                            style={{
                                color: "#DBFC08",
                                margin: "10px",
                                textDecoration: "none",
                            }}
                            to="/auth/login"
                        >
                            {this.props.t('forgot.password.login.link')}
                        </NavLink>
                    </div>

                    <Button text={this.props.t('office.system.form.submit.button')} />
                </form>
            </div>
        );
    }
}

export default withTranslation()(ForgotPasswordForm);
