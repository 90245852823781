import ReactCountryFlag from "react-country-flag";

export const LanguagesForSelect = [
    { value: 'en', label: 'English', icon: <ReactCountryFlag countryCode="US" svg /> },
    { value: 'de', label: 'German', icon: <ReactCountryFlag countryCode="DE" svg /> },
    // { value: 'es', label: 'Spanish', icon: <ReactCountryFlag countryCode="ES" svg /> },
    // { value: 'fr', label: 'French', icon: <ReactCountryFlag countryCode="FR" svg /> },
    { value: 'it', label: 'Italian', icon: <ReactCountryFlag countryCode="IT" svg /> },
    // { value: 'ru', label: 'Russian', icon: <ReactCountryFlag countryCode="RU" svg /> },
];
