import Client from "../../Client";

const ChangePassword = ({recoveryCode, password, confirmPassword}) => {
    return Client().post('/auth/change-password', {
        recoveryCode: recoveryCode,
        password: password,
        confirmPassword: confirmPassword,
    });
};

export const CanChangePassword = ({recoveryCode}) => {
    return Client().post('/auth/can-change-password', {recoveryCode: recoveryCode});
};

export default ChangePassword;
