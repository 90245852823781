// @ts-ignore
import { PaymentData } from "../../Entity.ts";

export class IbanPaymentData implements PaymentData {
    constructor(
        public account: string,
        public name: string,
        public code: string|null = null,
    ) {}

    getData(): object {
        return {
            account: this.account,
            name: this.name,
            code: this.code,
        };
    }
}
