import Client from "../../Client";
import {GetAuthToken} from "../../../../storages/auth-token-storage/AuthTokenStorage";

const BranchCreate = ({name, publicName, address = null, type = null}) => {
    return Client(GetAuthToken()).post(
        `/account/branch/create`,
        {
            name: name,
            publicName: publicName,
            address: address,
            type: type,
        },
    );
}

export default BranchCreate;
