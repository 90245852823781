import React from "react";
import * as mime from 'react-native-mime-types';
import { useNavigate } from "react-router-dom";
import {withTranslation} from "react-i18next";
import {generatePath} from "react-router";

import TextField from "../field/text-field/TextField";
import Button from "../../button/Button";
import SelectField from "../field/select-field/SelectField";
import { GetCountryName } from "../../i18n/Countries/Countries";
import FileField from "../field/file-field/FileField";
import VerificationCreate from "../../api/client/account/verification/VerificationCreate";
import Loader from "../../loader/Loader";
import {FireNotification} from "../../notification/Notification";
import Checkbox from "../field/Checkbox";

import './VerificationForm.css';

const nameMaxLength = 255;
const legalNameMaxLength = 255;
const legalNumberMaxLength = 255;
const addressMaxLength = 255;

const fileTypes = ['jpg', 'jpeg', 'png', 'heic', 'heif', 'pdf'];
const fileLimit = 10;

function NavigateTo(url) {
    const navigate = useNavigate();
    navigate(url);
}

class VerificationForm extends React.Component {
    state = {
        name: "",
        nameError: "",
        legalName: "",
        legalNameError: "",
        legalNumber: "",
        legalNumberError: "",
        address: "",
        addressError: "",
        country: "",
        countryError: "",
        documents: [],
        documentsResetState: false,
        documentsError: "",
        agreement: false,
        agreementError: "",
        message: "",
        loader: null,
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.message && prevState.message !== this.state.message) {
            FireNotification(this.state.message, false);
            this.setState({message: ''});
        }
    };

    getAvailableCountries = (availableCountryCodes) => {
        let map = [];
        if (!availableCountryCodes) {
            return map;
        }
        for (let i = 0; i < availableCountryCodes.length; i++) {
            map.push({value: availableCountryCodes[i], label: GetCountryName(availableCountryCodes[i])});
        }
        return map;
    };

    getNameError = name => {
        if (!name) {
            return this.props.t('required.field.message');
        }

        const nameRegex = /^[a-zA-Z0-9-. ]+?$/;
        if (!nameRegex.test(name)) {
            return this.props.t('office.verification.form.invalid.name');
        }

        if (name.length > nameMaxLength) {
            return this.props.t('office.verification.name.max.length.field.message', {
                maxLength: nameMaxLength,
            });
        }

        return '';
    };

    validateName = (value) => {
        const error = this.getNameError(value);
        this.setState({nameError: error});

        return !error;
    };

    validateLegalName = (value) => {
        const error = this.getLegalNameError(value);
        this.setState({legalNameError: error});

        return !error;
    };

    getLegalNameError = legalName => {
        if (!legalName) {
            return this.props.t('required.field.message');
        }

        const legalNameRegex = /^[a-zA-Z0-9-. ]+?$/;
        if (!legalNameRegex.test(legalName)) {
            return this.props.t('office.verification.form.invalid.legal.name');
        }

        if (legalName.length > legalNameMaxLength) {
            return this.props.t('office.verification.legal.name.max.length.field.message', {
                maxLength: legalNameMaxLength,
            });
        }

        return "";
    };

    validateLegalNumber = (value) => {
        const error = this.getLegalNumberError(value);
        this.setState({legalNumberError: error});

        return !error;
    };

    getLegalNumberError = legalNumber => {
        if (!legalNumber) {
            return this.props.t('required.field.message');
        }

        const legalNameRegex = /^[a-zA-Z0-9- ]+?$/;
        if (!legalNameRegex.test(legalNumber)) {
            return this.props.t('office.verification.form.invalid.legal.number');
        }

        if (legalNumber.length > legalNumberMaxLength) {
            return this.props.t('office.verification.legal.number.max.length.field.message', {
                maxLength: legalNumberMaxLength,
            });
        }

        return "";
    };

    validateAddress = (value) => {
        const error = this.getAddressError(value);
        this.setState({addressError: error});

        return !error;
    };

    getAddressError = address => {
        if (!address) {
            return this.props.t('required.field.message');
        }

        const addressRegex = /^[a-zA-Z0-9-., ]+?$/;
        if (!addressRegex.test(address)) {
            return this.props.t('office.verification.form.invalid.address');
        }

        if (address.length > addressMaxLength) {
            return this.props.t('office.verification.address.max.length.field.message', {
                maxLength: addressMaxLength,
            });
        }

        return "";
    };

    getCountryError = country => {
        if (!country) {
            return this.props.t('required.field.message');
        }

        if (!this.props.availableCountries.includes(country)) {
            return this.props.t('office.verification.form.invalid.country');
        }

        return "";
    };

    validateCountry = (value) => {
        const error = this.getCountryError(value);
        this.setState({countryError: error});

        return !error;
    };

    getDocumentsError = documents => {
        if (!documents || documents.length === 0) {
            return this.props.t('required.field.message');
        }

        if (documents.length > fileLimit) {
            return this.props.t('office.verification.form.documents.exceeded.limit.field.message');
        }

        let error = '';
        documents.forEach(document => {
            let documentMimeType = mime.lookup(document.name);
            let isValid = false;
            fileTypes.forEach(fileType => {
                if (documentMimeType === mime.lookup(fileType)) {
                    isValid = true;
                }
            });

            if (!isValid) {
                error = this.props.t('office.verification.documents.invalid.file.field.message', {
                    filename: document.name,
                });
            }
        });

        return error;
    };

    validateDocuments = (value) => {
        const error = this.getDocumentsError(value);
        this.setState({documentsError: error});

        return !error;
    };

    getAgreementError = checked => {
        let error = '';

        if (!checked) {
            error = this.props.t('required.field.message');
        }

        return error;
    }

    validateAgreement = (value) => {
        const error = this.getAgreementError(value);
        this.setState({agreementError: error});

        return !error;
    };

    onChangeName = (event) => {
        this.setState({name: event.target.value})
        this.validateName(event.target.value);
    };

    onChangeLegalName = (event) => {
        this.setState({legalName: event.target.value})
        this.validateLegalName(event.target.value);
    };

    onChangeLegalNumber = (event) => {
        this.setState({legalNumber: event.target.value})
        this.validateLegalNumber(event.target.value);
    };

    onChangeAddress = (event) => {
        this.setState({address: event.target.value})
        this.validateAddress(event.target.value);
    };

    onChangeCountry = (data) => {
        this.setState({country: data})
        this.validateCountry(data.value);
    };

    onChangeDocuments = (event, documents) => {
        this.setState({documents: documents});
        this.validateDocuments(documents);
    };

    onChangeAgreement = (event) => {
        this.setState({agreement: event.target.checked});
        this.validateAgreement(event.target.checked);
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (
            !this.validateName(this.state.name)
            || !this.validateLegalName(this.state.legalName)
            || !this.validateLegalNumber(this.state.legalNumber)
            || !this.validateAddress(this.state.address)
            || !this.validateCountry(this.state.country.value)
            || !this.validateDocuments(this.state.documents)
            || !this.validateAgreement(this.state.agreement)
        ) {
            return;
        }

        this.setState({loader: Loader()});
        VerificationCreate({
            name: this.state.name,
            country: this.state.country.value,
            data: {
                legalName: this.state.legalName,
                legalNumber: this.state.legalNumber,
                address: this.state.address,
            },
            documents: this.state.documents,
        })
            .then((response) => {
                if (response.data.code === 200 && response.data.result === true) {
                    e.target.reset();
                    this.setState({
                        name: "",
                        legalName: "",
                        legalNumber: "",
                        address: "",
                        country: "",
                        documents: [],
                        documentsResetState: true,
                        agreement: false,
                    });

                    this.props.hasOwnProperty('onSubmit') && this.props.onSubmit(true);
                } else {
                    this.setState({message: this.props.t('something.went.wrong.error')});
                    this.props.hasOwnProperty('onSubmit') && this.props.onSubmit(false);
                }
            })
            .catch(error => {
                this.setState({message: this.props.t('something.went.wrong.error')});
                this.props.hasOwnProperty('onSubmit') && this.props.onSubmit(false);

                if (error.response?.status === 401) {
                    NavigateTo("/auth/logout");
                }
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    render() {
        const agreementLink = generatePath('/terms-and-conditions');
        const labelText = this.props.t('office.verification.form.agreement.text', {
            link: `<a href="${agreementLink}" target="_blank">${this.props.t('office.verification.form.agreement.link')}</a>`
        });

        return (
            <div className="VerificationForm">
                {this.state.loader && this.state.loader}

                <form
                    onSubmit={this.onSubmit}
                    encType="multipart/form-data"
                    autoComplete="off"
                >
                    <TextField
                        name="name"
                        label={this.props.t('office.verification.form.name.field.label')}
                        value={this.state.name}
                        error={this.state.nameError}
                        onChange={this.onChangeName}
                    />
                    <SelectField
                        name="country"
                        label={this.props.t('office.verification.form.country.field.label')}
                        value={this.state.country}
                        error={this.state.countryError}
                        placeholder={this.props.t('office.verification.form.country.field.placeholder')}
                        options={this.getAvailableCountries(this.props.availableCountries)}
                        onChange={this.onChangeCountry}
                    />
                    <TextField
                        name="legalName"
                        label={this.props.t('office.verification.form.legal.name.field.label')}
                        value={this.state.legalName}
                        error={this.state.legalNameError}
                        onChange={this.onChangeLegalName}
                    />
                    <TextField
                        name="legalNumber"
                        label={this.props.t('office.verification.form.legal.number.field.label')}
                        value={this.state.legalNumber}
                        error={this.state.legalNumberError}
                        onChange={this.onChangeLegalNumber}
                    />
                    <TextField
                        name="address"
                        label={this.props.t('office.verification.form.address.field.label')}
                        value={this.state.address}
                        error={this.state.addressError}
                        onChange={this.onChangeAddress}
                    />
                    <FileField
                        name="documents"
                        label={this.props.t('office.verification.form.documents.field.label')}
                        onChange={this.onChangeDocuments}
                        multiple
                        accept={'.' + fileTypes.join(', .')}
                        custom={{
                            allowTypes: fileTypes,
                            fileLimit: fileLimit,
                            error: this.state.documentsError,
                            resetState: this.state.documentsResetState,
                        }}
                    />
                    <div className="AgreementWrapper">
                        <div className="AgreementItem">
                            <Checkbox
                                label={<span dangerouslySetInnerHTML={{__html: labelText}}/>}
                                onChange={this.onChangeAgreement}
                            />
                        </div>
                        {this.state.agreementError && <div className="AgreementError">
                            {this.state.agreementError}
                        </div>}
                    </div>

                    <Button text={this.props.t('office.system.form.submit.button')}/>
                </form>
            </div>
        );
    }
}

export default withTranslation()(VerificationForm);
