import {useEffect, useState} from "react";
import {useHref, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import BranchInviteForm from "../../../../../components/form/branch-invite-form/BranchInviteForm";
import BranchInviteInit from "../../../../../components/api/client/account/branch-invite/BranchInviteInit";
import Loader from "../../../../../components/loader/Loader";
import SystemPM from "../../../../../components/system-pm/SystemPM";
import {FireNotification} from "../../../../../components/notification/Notification";

let requestsState = [];

const BranchInviteCreate = () => {
    const navigate = useNavigate();
    const initRequest = 'initRequest';
    const { t } = useTranslation();
    const [loader, setLoader] = useState(Loader());
    const { id } = useParams();
    const [message, setMessage] = useState( '');
    const [hasVerification, setHasVerification] = useState( null);
    const [availableCreationLimit, setAvailableCreationLimit] = useState( null);
    const verificationLink = useHref('/office/verification');

    const initConfig = () => {
        BranchInviteInit(parseInt(id))
            .then((response) => {
                if (response.status === 200) {
                    setHasVerification(response.data.result.hasVerification);
                    setAvailableCreationLimit(response.data.result.availableCount);
                } else {
                    setMessage(t('something.went.wrong.error'));
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    navigate("/auth/logout");
                }
                setMessage(t('something.went.wrong.error'));
            })
            .finally(() => {
                requestsState[initRequest] = false;
                setLoader(null);
            });
    };

    useEffect(() => {
        document.title = t('title.office.branch.invite.create');

        if (!requestsState[initRequest]) {
            requestsState[initRequest] = true;
            initConfig();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message) {
            FireNotification(message, false);
            setMessage('');
        }
    }, [message]);

    const onCancel = () => {
        navigate(`/office/branch/${id}/invites`);
    };

    return (
        <div className="BranchInviteCreation">
            {loader && loader}
            <h1>{t('title.office.branch.invite.create')}</h1>
            {!hasVerification && (<div className="SystemNotifySection">
                <SystemPM
                    html={t('office.system.verify.first.message', {
                        link: `<a href="${verificationLink}">${t('office.system.verify.first.link')}</a>`
                    })}
                />
            </div>)}
            {hasVerification && availableCreationLimit === 0 && (<div className="SystemNotifySection">
                <SystemPM
                    html={t('office.branch.invite.form.creation.limit.reached.message')}
                />
            </div>)}
            <br/>
            <BranchInviteForm
                branchId={id}
                disabled={!hasVerification || availableCreationLimit === 0}
                onCancel={onCancel}
            />
        </div>
    );
};

export default BranchInviteCreate;
