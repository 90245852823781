import "./LandingHeaderTemplate.css";

const PayConfirmation = (props) => {
    return (
        <section className="PayHeader">
            <div className="PayHeaderLogo">
                {!!props.logo && <img src={props.logo} alt={`${props.name} logo`}/>}
            </div>
            <div className="PayHeaderInfo">
                <div className="PayHeaderName">
                    {!!props.name && props.name}
                </div>
                <div className="PayHeaderSubName">
                    {!!props.subName && props.subName}
                </div>
            </div>
        </section>
    );
};

export default PayConfirmation;
