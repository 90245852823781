import {useState} from "react";
import {useLocation} from "react-router-dom";

import Navigation from "../navigation/Navigation";
import { LanguageSwitcher } from "../i18n/LanguageSwitcher/LanguageSwitcher";
import {GetAccInfo} from "../storages/account-info-storage/AccountInfoStorage";

import logo from './logo.svg';
import './Navbar.css';

const excludeRoutesRegex = [
    /^\/pay\/[a-zA-Z0-9-]{36}$/,
    /^\/pay\/[a-zA-Z0-9-]{36}\/confirmation$/,
];

const guestContent = [
    {
        "text": "navbar.login",
        "route": "/auth/login",
    },
    {
        "text": "navbar.register",
        "route": "/auth/register",
    },
    {
        "text": "navbar.support",
        "route": "/support",
    },
];

const authorizedContent = [
    {
        "text": "navbar.overview",
        "route": "/office/overview",
    },
    {
        "text": "navbar.qr.codes",
        "route": "/office/qr-codes",
    },
    {
        "text": "navbar.branches",
        "route": "/office/branches",
    },
    {
        "text": "navbar.settings",
        "route": "/office/settings",
    },
];

const Navbar = () => {
    const [langVisibleState, setLangVisibleState] = useState( false);
    const location = useLocation();

    let authorizedContentFiltered = authorizedContent;

    const updateLangVisible = () => {
        let result = langVisibleState;
        let newState = false;
        if (!!excludeRoutesRegex) {
            excludeRoutesRegex.forEach((routeRegex) => {
                if (routeRegex.test(location.pathname)) {
                    newState = true;
                }
            });

            if (langVisibleState !== newState) {
                setLangVisibleState(result = newState);
            }
        }

        return result;
    };

    const authorizedContentFilter = (authorizedContent) => {
        let result = authorizedContent;
        const accountInfo = GetAccInfo();

        if (accountInfo?.isBusiness) {
            result = authorizedContent.filter((content => content.text !== 'navbar.qr.codes'));
        }

        if (accountInfo?.isEmployee) {
            result = authorizedContent.filter((content => content.text !== 'navbar.branches'));
        }

        return result;
    };

    updateLangVisible();
    authorizedContentFiltered = authorizedContentFilter(authorizedContentFiltered);

    return (
        <div>
            <header className="App-navbar">
                <img src={logo} alt="logo" />
                {langVisibleState && <div className="lang-switcher-wrapper">
                    <LanguageSwitcher />
                </div>}

                <Navigation
                    guestContent={guestContent}
                    authorizedContent={authorizedContentFiltered}
                    excludeRoutesRegex={excludeRoutesRegex}
                />
            </header>
        </div>
    );
};

export default Navbar;
