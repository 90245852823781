import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Loader from "../../../../components/loader/Loader";
import BranchForm from "../../../../components/form/branch-form/BranchForm";
import BranchView from "../../../../components/api/client/account/branch/BranchView";

let requestsState = [];

const BranchEdit = () => {
    const navigate = useNavigate();
    const itemRequest = 'itemRequest';
    const [branch, setBranch] = useState( null);
    const [loader, setLoader] = useState(Loader());
    const { id } = useParams();
    const { t } = useTranslation();

    const onCancel = () => {
        navigate('/office/branches');
    };

    const onSubmit = () => {
        fetchItem(id);
    };

    function getValidateError(id) {
        const idRegex = /^[0-9]+$/

        if (!idRegex.test(id) || parseInt(id) <= 0) {
            return 'Invalid id';
        }

        return "";
    }

    const fetchItem = (id) => {
        function redirectTo404() {
            navigate("/404");
        }

        if (!requestsState[itemRequest]) {
            requestsState[itemRequest] = true;
            if (!getValidateError(id)) {
                !loader && setLoader(Loader());
                BranchView(id)
                    .then((response) => {
                        if (response.status === 200) {
                            setBranch(response.data.result);
                        } else {
                            redirectTo404();
                        }
                    })
                    .catch(error => {
                        if (error.response?.status === 401) {
                            navigate("/auth/logout");
                        }

                        redirectTo404();
                    })
                    .finally(() => {
                        requestsState[itemRequest] = false;
                        setLoader(null);
                    });
            } else {
                redirectTo404();
            }
        }
    };

    useEffect(() => {
        document.title = t('title.office.branches.edit');

        fetchItem(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {loader && loader}
            <h1>{t('title.office.branches.edit')}</h1>
            <br/>
            <BranchForm
                id={id}
                model={branch}
                onSubmit={onSubmit}
                onCancel={onCancel}
            />
        </div>
    );
};

export default BranchEdit;
