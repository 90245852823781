import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";

// @ts-ignore
import { PaymentDataType } from "./Type.ts"
// @ts-ignore
import { Iban } from "./factories/Iban.tsx";
// @ts-ignore
import { UnsupportedPaymentDataException } from "./Exception.ts";

export interface PaymentDataFactory {
    render(): ReactJSXElement;
    validate(): boolean;
    getState(): object;
}

export function Make(type: PaymentDataType, t: Function): PaymentDataFactory {
    switch (type) {
        case PaymentDataType.Iban:
            return new Iban(t);
        default:
            throw new UnsupportedPaymentDataException();
    }
}
