const keyName = 'acc-info';

const GetAccInfo = () => {
    const result = localStorage.getItem(keyName);

    return !!result ? JSON.parse(result) : null;
};

const SetAccInfo = (info) => (
    localStorage.setItem(keyName, JSON.stringify(info))
);

export { GetAccInfo, SetAccInfo };
