import {Suspense} from "react";

import Navbar from "./components/navbar/Navbar";
import Routes from "./components/routes/Routes";
import { LanguageSwitcher } from "./components/i18n/LanguageSwitcher/LanguageSwitcher";
import AccountInfo from "./components/identity/AccountInfo";
import PrivacyNotify from "./components/privacy-notify/PrivacyNotify";
import {Notification} from "./components/notification/Notification";
import Loader from "./components/loader/Loader";
import PwaInstallPopup from "./components/pwa-install-popup/PwaInstallPopup";
import {useDeviceCheck} from "./components/device-check-provider/DeviceCheckProvider";
import PwaInstallManualPopup from "./components/pwa-install-manual-popup/PwaInstallManualPopup";

import './App.css';

const App = () => {
    const { isIOS, isStandalone } = useDeviceCheck();
    LanguageSwitcher();
    AccountInfo();

    return (
        <main className="App">
            <Suspense fallback={<Loader />}>
                <Navbar />
                <Routes />
                <PrivacyNotify />
                <Notification />
                {!isStandalone && (!isIOS ? <PwaInstallPopup /> : <PwaInstallManualPopup />)}
            </Suspense>
        </main>
    );
};

export default App;
