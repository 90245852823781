import './Field.css';

const Field = (props) => {
    const { label, error, labelclassname, labelonclick, ...rest } = props;

    return (
        <div className="Field">
            <label
                className={labelclassname}
                onClick={labelonclick}
                {...(rest.id ? { htmlFor: rest.id } : {})}
            >
                {label}
            </label>
            <div className="FieldWrapper">
                <input {...rest} />
                {error && <div className="ErrorWrapper">{error}</div>}
            </div>
        </div>
    );
}

export default Field;
