import {useEffect, useState} from "react";
import {useHref, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import WithdrawalForm from "../../../../../components/form/withdrawal-form/WithdrawalForm";
import WithdrawalInit from "../../../../../components/api/client/account/withdrawal/WithdrawalInit";
import Loader from "../../../../../components/loader/Loader";
import SystemPM from "../../../../../components/system-pm/SystemPM";
import {FireNotification} from "../../../../../components/notification/Notification";

let requestsState = [];

const WithdrawalCreate = () => {
    const navigate = useNavigate();
    const initRequest = 'initRequest';
    const [message, setMessage] = useState( '');
    const [config, setConfig] = useState( null);
    const [hasVerification, setHasVerification] = useState( null);
    const [loader, setLoader] = useState(Loader());
    const { t } = useTranslation();
    const verificationLink = useHref('/office/verification');

    const initConfig = () => {
        WithdrawalInit()
            .then((response) => {
                if (response.status === 200 && response.data.error === null) {
                    setHasVerification(true);
                    setConfig(response.data.data);
                } else if (response.data.error && response.data.error === 'verification missing') {
                    setHasVerification(false);
                } else {
                    setMessage(t('something.went.wrong.error'));
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    navigate("/auth/logout");
                }
                setMessage(t('something.went.wrong.error'));
            })
            .finally(() => {
                requestsState[initRequest] = false;
                setLoader(null);
            });
    };

    useEffect(() => {
        document.title = t('office.account.withdrawal.new.title');

        if (!requestsState[initRequest]) {
            requestsState[initRequest] = true;
            initConfig();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message) {
            FireNotification(message, false);
            setMessage('');
        }
    }, [message]);

    const onCancel = () => {
        navigate('/office/overview');
    };

    return (
        <div>
            {loader && loader}
            <h1>{t('office.account.withdrawal.new.title')}</h1>
            {!hasVerification && (<div className="SystemNotifySection">
                <SystemPM
                    html={t('office.system.verify.first.message', {
                        link: `<a href="${verificationLink}">${t('office.system.verify.first.link')}</a>`
                    })}
                />
            </div>)}
            <br/>
            <WithdrawalForm
                onCancel={onCancel}
                disabled={!hasVerification}
                availableWallets={config && config.userAccountDetails}
                minAmount={config && config.minAmount}
                maxAmount={config && (config.balance > config.maxAmount ? config.maxAmount : config.balance)}
                toWithdrawAmount={config && config.toWithdrawAmount}
                fee={config && config.fee}
                toWithdrawAmountIncludedFee={config && config.toWithdrawAmountIncludedFee}
                amountPrecision={config && config.amountPrecision}
                currency={config && config.currencyCode}
            />
        </div>
    );
};

export default WithdrawalCreate;
