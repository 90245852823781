import Client from "../Client";

const LandingPay = ({cashboxUuid, method, currency, amount, containsPaidFee, paymentData}) => {
    return Client().post(
        `/landing/pay`,
        {
            cashboxUuid: cashboxUuid,
            method: method,
            currency: currency,
            amount: amount,
            containsPaidFee: containsPaidFee,
            paymentData: paymentData,
        },
    );
}

export default LandingPay;
