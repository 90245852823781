import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Navigate} from "react-router";

import VerificationForm from "../../../components/form/verification-form/VerificationForm";
import VerificationStatus from "../../../components/api/client/account/verification/VerificationStatus";
import Loader from "../../../components/loader/Loader";
import SystemPM from "../../../components/system-pm/SystemPM";
import {FireNotification} from "../../../components/notification/Notification";

let requestsState = [];

const Verification = () => {
    const navigate = useNavigate();
    const canVerificationRequest = 'canVerificationRequest';
    const [canVerify, setCanVerify] = useState( true);
    const [availableCountries, setAvailableCountries] = useState( []);
    const [message, setMessage] = useState( '');
    const [redirect, setRedirect] = useState( '');
    const [loader, setLoader] = useState(Loader());
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('title.office.verification');

        if (!requestsState[canVerificationRequest]) {
            requestsState[canVerificationRequest] = true;
            VerificationStatus()
                .then((response) => {
                    if (response.status === 200) {
                        setCanVerify(response.data.canCreate);
                        setAvailableCountries(response.data.availableCountries);

                        if (response.data.isPending === true) {
                            setMessage(t('office.verification.status.pending'));
                        } else if (response.data.isApproved === true) {
                            setMessage(t('office.verification.status.approve'));
                        } else if (response.data.isRejected === true) {
                            let rejectMessage = t('office.verification.status.decline');
                            if (response.data.rejectMessage) {
                                rejectMessage += `: ${response.data.rejectMessage}`;
                            }
                            setMessage(rejectMessage);
                        }
                    } else {
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    }
                    setMessage(t('something.went.wrong.error'));
                })
                .finally(() => {
                    requestsState[canVerificationRequest] = false;
                    setLoader(null);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (isSuccessful) => {
        let message = '';
        if (isSuccessful) {
            message = t('office.verification.success.message');
            setCanVerify(true);
            FireNotification(message, true);
            setRedirect("/office/settings");
        }
        setMessage(message);
    };

    return (
        <div>
            {loader && loader}
            {redirect && <Navigate to={redirect} />}
            <h1>{t('title.office.verification')}</h1>
            <br/>
            {message && (<div className="SystemNotifySection">
                <SystemPM html={(`<span>${message}</span>`)} />
            </div>)}
            {canVerify && (<VerificationForm availableCountries={availableCountries} onSubmit={onSubmit} />)}
        </div>
    );
};

export default Verification;
