import Client from "../../Client";
import {GetAuthToken} from "../../../../storages/auth-token-storage/AuthTokenStorage";

const PaymentPageCreate = ({title, avatar = null}) => {
    return Client(GetAuthToken()).post(
        '/account/payment-page/create',
        {
            title: title,
            avatar: avatar,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        },
    );
}

export default PaymentPageCreate;
