import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./NotFound.css";

const NotFound = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('title.not.found');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="NotFoundWrapper">
            <div className="NotFoundBlock">
                <span className="NotFoundHeader">
                    404
                </span>
                <span>
                    {t('not.found.message')}
                </span>
            </div>
        </div>
    );
}

export default NotFound;
