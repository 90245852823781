// @ts-ignore
import { UnsupportedPaymentDataException } from "./Exception.ts";

export enum PaymentMethod {
    Card = 1,
    External = 4,
}

export function GetLabel(method: number): string {
    switch (method) {
        case PaymentMethod.Card:
            return 'Card';
        default:
            throw new UnsupportedPaymentDataException();
    }
}
