import React, { createContext, useState, useEffect, useContext } from 'react';

const PwaContext = createContext();

export const PwaProvider = ({ children }) => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstallable, setIsInstallable] = useState(false);
    const [isInstalled, setIsInstalled] = useState(false);

    useEffect(() => {
        const checkInstalled = () => {
            const isStandalone =
                window.matchMedia('(display-mode: standalone)').matches || navigator.standalone;
            setIsInstalled(Boolean(isStandalone));
            console.log('isStandalone: ', Boolean(isStandalone));
        };

        checkInstalled();

        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            setIsInstallable(true);
        };

        const handleAppInstalled = () => {
            setIsInstalled(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        window.addEventListener('appinstalled', handleAppInstalled);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    const promptInstall = async () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            const choiceResult = await deferredPrompt.userChoice;
            if (choiceResult.outcome === 'accepted') {
                console.log('PWA install is accepted');
            } else {
                console.log('PWA install is declined');
            }
            setDeferredPrompt(null);
        }
    };

    return (
        <PwaContext.Provider value={{ isInstallable, isInstalled, promptInstall }}>
            {children}
        </PwaContext.Provider>
    );
};

export const usePwa = () => useContext(PwaContext);
