import {useEffect, useState} from "react";
import { useHref, useLocation, useNavigate } from "react-router-dom";

import IncomeList from "./overview/IncomeList";
import WithdrawalList from "./overview/WithdrawalList";
import Dashboard from "./overview/Dashboard";
import OverviewClient from "../../../components/api/client/account/overview/Overview";
import {useTranslation} from "react-i18next";
import Loader from "../../../components/loader/Loader";
import {
    GetCurrencySymbol,
    GetDecimalSeparator,
    GetFormattedIntegerPartOfAmount,
    GetFractionalPartOfAmount,
} from "../../../components/formatter/Formatter";
import {FireNotification} from "../../../components/notification/Notification";
import SystemPM from "../../../components/system-pm/SystemPM";

import "./Overview.css";

const IncomeListScreen = 1;
const WithdrawalListScreen = 2;
const DashboardScreen = 3;

let requestsState = [];

const Overview = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const overviewRequest = 'overviewRequest';
    const [screen, setScreen] = useState(IncomeListScreen);
    const [overview, setOverview] = useState(null);
    const [message, setMessage] = useState( '');
    const [loader, setLoader] = useState(Loader());
    const { t } = useTranslation();
    const verificationLink = useHref('/office/verification');

    useEffect(() => {
        document.title = t('title.office.overview');
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setScreen(IncomeListScreen);
    }, [location.pathname]);

    useEffect(() => {
        if (message) {
            FireNotification(message, false);
            setMessage('');
        }
    }, [message]);

    const fetch = async () => {
        if (!requestsState[overviewRequest]) {
            requestsState[overviewRequest] = true;
            OverviewClient()
                .then((response) => {
                    if (response.data.result) {
                        setOverview(response.data.result);
                    } else {
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    } else {
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .finally(() => {
                    requestsState[overviewRequest] = false;
                    setLoader(null);
                });
        }
    };

    const renderIncomeListScreen = () => {
        return (
            <IncomeList />
        );
    };

    const renderWithdrawalListScreen = () => {
        return (
            <WithdrawalList />
        );
    };

    const renderDashboardScreen = () => {
        return (
            <Dashboard />
        );
    };

    const onTabChange = (tab, e) => {
        e.preventDefault();

        if (tab !== screen) {
            setScreen(tab);
        }
    };

    return (
        <div className="AccountWrapper">
            {loader && loader}
            <h1 style={{color: "white"}}>{document.title}</h1>
            <br/>
            <div className="BalanceWrapper">
                {
                    overview
                    && typeof overview.balance !== 'undefined'
                    && overview.balance !== null
                    && overview.currencyCode ? (
                        <div className="BalanceItem">
                                <span className="BalanceMain">
                                    {GetCurrencySymbol(overview.currencyCode)}
                                    &nbsp;
                                    {GetFormattedIntegerPartOfAmount(overview.balance)}
                                </span>
                            <span className="BalanceAdditional">
                                    {GetDecimalSeparator()}{GetFractionalPartOfAmount(overview.balance)}
                                </span>
                        </div>
                    ) : ''
                }
            </div>
            {(overview && !overview.hasVerification) && (<div className="SystemNotifySection">
                <SystemPM
                    html={t('office.system.verify.first.message', {
                        link: `<a href="${verificationLink}">${t('office.system.verify.first.link')}</a>`
                    })}
                />
            </div>)}
            <div>
                <div className="TabControlWrapper">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        href="#"
                        className={screen === IncomeListScreen ? 'active' : ''}
                        onClick={(e) => onTabChange(IncomeListScreen, e)}
                    >
                        {t('office.account.tab1')}
                    </a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        href="#"
                        className={screen === WithdrawalListScreen ? 'active' : ''}
                        onClick={(e) => onTabChange(WithdrawalListScreen, e)}
                    >
                        {t('office.account.tab2')}
                    </a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        href="#"
                        className={screen === DashboardScreen ? 'active' : ''}
                        onClick={(e) => onTabChange(DashboardScreen, e)}
                    >
                        {t('title.office.account.tab2')}
                    </a>
                </div>
                <div>
                    {screen === IncomeListScreen && renderIncomeListScreen()}
                    {screen === WithdrawalListScreen && renderWithdrawalListScreen()}
                    {screen === DashboardScreen && renderDashboardScreen()}
                </div>
            </div>
        </div>
    );
};

export default Overview;
