import { Bounce, toast, ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

const Notification = () => {
    const cfg = getConfig();

    return (
        <div className="NotificationWrapper">
            <ToastContainer
                position={cfg.position}
                autoClose={cfg.autoClose}
                hideProgressBar={cfg.hideProgressBar}
                newestOnTop
                closeOnClick={cfg.closeOnClick}
                rtl={false}
                pauseOnFocusLoss
                draggable={cfg.draggable}
                pauseOnHover={cfg.pauseOnHover}
                theme={cfg.theme}
                transition={cfg.transition}
            />
        </div>
    );
};

const FireNotification = (message, isSuccess = true) => {
    if (isSuccess) {
        toast.success(message, getConfig());
    } else {
        toast.error(message, getConfig());
    }
};

const getConfig = () => {
    return {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    };
};

export { Notification, FireNotification };
