import React from "react";
import {MdOutlineCelebration} from "react-icons/md";

const LandingPaySuccessTemplate = (props) => {
    return (
        <div className="SuccessScreen">
            <div className="SuccessScreenContent">
                <div>
                    <MdOutlineCelebration style={{color: "white", fontSize: "40px"}}/>
                </div>
                <div>
                    <span>
                        {props.text}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default LandingPaySuccessTemplate;
