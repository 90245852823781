import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

const version = '1.0.8';

i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
        backend: {
            loadPath: '/i18n/locales/{{lng}}/{{ns}}.json',
            queryStringParams: { v: version },
        },
        lng: 'en',
        fallbackLng: 'en',
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true,
        },
    });

export default i18n;
