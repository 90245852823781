import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import {useTranslation} from "react-i18next";

import Button from "../../../button/Button";
import Field from "../Field";

import "./FileField.css";

const FileField = (props) => {
    const [files, setFiles] = useState( []);
    const [inputId] = useState( Math.random().toString(36));
    const { t } = useTranslation();

    const handleChangeField = (e) => {
        let uploadedFiles = e.target.files;
        let fileMap = [];
        Object.keys(uploadedFiles).forEach(i => {
            const file = uploadedFiles[i];
            fileMap.push(Object.assign(file, {id: i}));
        });
        setFiles(fileMap);

        props.hasOwnProperty('onChange') && props.onChange(e, fileMap);
    }

    const handleRemoveFile = (e) => {
        e.preventDefault();

        const idForRemove = e.currentTarget.id;
        let fileMap = [];
        Object.keys(files).forEach(i => {
            if (files[i].id !== idForRemove) {
                fileMap.push(files[i]);
            }
        });
        setFiles(fileMap);

        let dt = new DataTransfer();
        let el = document.getElementById(inputId);
        Object.keys(el.files).forEach(i => {
            if (el.files[i].id !== idForRemove) {
                dt.items.add(el.files[i]);
            }
        });
        el.files = dt.files;

        props.hasOwnProperty('onChange') && props.onChange(e, fileMap);
    };

    const reset = () => {
        const el = document.getElementById(inputId);
        if (el) {
            let list = new DataTransfer();
            el.files = list.files;
            setFiles([]);
        }
    };

    const handleChooseFiles = (e) => {
        e.preventDefault();

        document.getElementById(inputId).click();
    };

    const getFileTypes = () => {
        let result;
        if (props.hasOwnProperty('custom') && props.custom.hasOwnProperty('allowTypes')) {
            result = '.' + props.custom.allowTypes.join(', .');
        }
        return result;
    };

    let filePreview = '';
    if (files.length > 0) {
        filePreview = files.map((file) => {
            return (
                <div key={file.id}>
                    <span className="FilePreview">
                        {file.name}
                    </span>
                    <span className="ControlClose">
                        <MdClose
                            id={file.id}
                            onClick={handleRemoveFile}
                        />
                    </span>
                </div>
            );
        });
    }

    if (
        props.hasOwnProperty('custom')
        && props.custom.hasOwnProperty('resetState')
        && props.custom.resetState === true
        && files.length !== 0
    ) {
        reset();
    }

    return (
        <div className="FileField">
            <Field
                {...props}
                id={inputId}
                type="file"
                onChange={handleChangeField}
            />
            <div className="FileFieldWrapper">
                <div className="ControlWrapper">
                    <Button
                        text={t('office.system.form.choose.files.button')}
                        onClick={handleChooseFiles}
                    />
                    <div className="ControlTips">
                        <div className="MaxFilesTip">
                            {props.custom.fileLimit &&
                                <span>
                                    Max <span className="TipsWrapper">{props.custom.fileLimit}</span>
                                </span>
                            }
                        </div>
                        <div className="FormatTip">
                            {props.custom.allowTypes &&
                                <span>
                                    Only <span className="TipsWrapper">{getFileTypes()}</span>
                                </span>
                            }
                        </div>
                    </div>
                </div>
                <div className="preview">
                    {filePreview}
                </div>
            </div>
            <div className="Error">
                {props.custom.error && <div>{props.custom.error}</div>}
            </div>
        </div>
    );
};

export default FileField;
