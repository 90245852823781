import React, {createRef} from "react";
import { withTranslation } from "react-i18next";

import EmailField from "../../field/email-field/EmailField";
import PasswordField from "../../field/password-field/PasswordField";
import Button from "../../../button/Button";
import Loader from "../../../loader/Loader";
import {FireNotification} from "../../../notification/Notification";
import withNavigate from "../../../wrappers/with-navigate/WithNavigate";
import {SetAuthToken} from "../../../storages/auth-token-storage/AuthTokenStorage";
import {SetAccInfo} from "../../../storages/account-info-storage/AccountInfoStorage";
import RegisterEmployee from "../../../api/client/auth/register/employee/RegisterEmployee";

const passwordMinLength = 8;
const passwordMaxLength = 64;

class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
            passwordError: "",
            confirmPasswordError: "",
            message: "",
            isSuccess: null,
            loader: null,
        };

        this.fakeFormRef = createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.message && prevState.message !== this.state.message) {
            FireNotification(this.state.message, this.state.isSuccess);
            this.setState({message: ''});
        }
    }

    afterRegistration = (auth) => {
        SetAuthToken(auth.token);
        SetAccInfo(auth.info);

        const { navigate } = this.props;
        navigate('/office/overview');
    };

    getPasswordError = password => {
        const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\s:])(\S)+$/;
        if (!passwordRegex.test(password)) {
            return this.props.t('register.invalid.password.field.message');
        }

        if (password.length < passwordMinLength) {
            return this.props.t('register.password.min.length.field.message', {minLength: passwordMinLength});
        }

        if (password.length > passwordMaxLength) {
            return this.props.t('register.password.max.length.field.message', {maxLength: passwordMaxLength});
        }

        return "";
    };

    checkEqualPasswords = (password, confirmPassword) => {
        return password === confirmPassword;
    };

    validatePassword = (value) => {
        const error = this.getPasswordError(value);
        this.setState({passwordError: error});

        return !error;
    };

    validateConfirmPassword = (value, password) => {
        let error = "";
        if (!this.checkEqualPasswords(password ? password : this.state.password, value)) {
            error = this.props.t('register.passwords.not.equals.field.message');
        }
        this.setState({confirmPasswordError: error});

        return !error;
    };

    onChangePassword = (event) => {
        this.setState({password: event.target.value});
        this.validatePassword(event.target.value);

        if (this.state.confirmPassword !== '') {
            this.validateConfirmPassword(this.state.confirmPassword, event.target.value);
        }
    };

    onChangeConfirmPassword = (event) => {
        this.setState({confirmPassword: event.target.value});
        this.validateConfirmPassword(event.target.value);
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (
            !this.validatePassword(this.state.password)
            || !this.validateConfirmPassword(this.state.confirmPassword)
        ) {
            return;
        }

        this.setState({loader: Loader()});
        RegisterEmployee({
            code: this.props.code,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
        })
            .then((response) => {
                if (response.data.code === 200 && response.data.result.id) {
                    e.target.reset();
                    this.fakeFormRef.current.submit();
                    this.setState({
                        message: this.props.t('register.success.message'),
                        isSuccess: true,
                        password: '',
                        confirmPassword: '',
                    });

                    this.afterRegistration(response.data.result.auth);
                } else if (response.data.code === 400) {
                    if (response.data.details.password) {
                        this.setState({
                            message: this.props.t(response.data.details.password),
                            isSuccess: false,
                        });
                    }
                } else {
                    this.setState({
                        message: this.props.t('something.went.wrong.error'),
                        isSuccess: false,
                    });
                }
            })
            .catch(error => {
                this.setState({
                    message: this.props.t('something.went.wrong.error'),
                    isSuccess: false,
                });
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    render() {
        return (
            <div className="RegisterForm">
                {this.state.loader && this.state.loader}

                <form onSubmit={this.onSubmit}>
                    <EmailField
                        id="email"
                        name="email"
                        autoComplete="username"
                        label={this.props.t('register.email.field.label')}
                        value={this.props.email}
                        disabled
                    />
                    <PasswordField
                        id="password"
                        name="password"
                        autoComplete="new-password"
                        label={this.props.t('register.password.field.label')}
                        value={this.state.password}
                        error={this.state.passwordError}
                        onChange={this.onChangePassword}
                        required
                    />
                    <PasswordField
                        id="confirmPassword"
                        name="confirmPassword"
                        autoComplete="new-password"
                        label={this.props.t('register.confirm.password.field.label')}
                        value={this.state.confirmPassword}
                        error={this.state.confirmPasswordError}
                        onChange={this.onChangeConfirmPassword}
                        required
                    />

                    <Button
                        text={this.props.t('office.system.form.submit.button')}
                        type="submit"
                        aria-label="Register"
                    />
                </form>
                <form
                    ref={this.fakeFormRef}
                    // eslint-disable-next-line no-script-url
                    action="javascript:void(0);"
                    method="POST"
                    style={{display: 'none'}}
                >
                    <input type="email" name="email" value={this.props.email} readOnly/>
                    <input type="password" name="password" value={this.state.password} readOnly/>
                    <button type="submit">Submit</button>
                </form>
            </div>
        );
    }
}

export default withTranslation()(withNavigate(RegisterForm));

