import React, {useState} from 'react';
import {Link} from "react-router-dom";

import PwaInstallManualPopup from "../../pwa-install-manual-popup/PwaInstallManualPopup";

const PwaInstallPopupLink = ({ text, icon, wrapperClass, iconWrapperClass, textWrapperClass }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <div className={wrapperClass}>
                <Link to='#' onClick={handleClick}>
                <span className={iconWrapperClass}>
                    {icon}
                </span>
                    <span className={textWrapperClass}>
                    {text}
                </span>
                </Link>
            </div>
            {isModalVisible && <PwaInstallManualPopup isManualOpen={true} onClose={handleModalClose} />}
        </>
    );
};

export default PwaInstallPopupLink;
