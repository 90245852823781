import React from "react";
import { Navigate } from "react-router";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import Button from "../../button/Button";
import WithdrawalCreate from "../../api/client/account/withdrawal/WithdrawalCreate";
import SelectField from "../field/select-field/SelectField";
import Loader from "../../loader/Loader";
import {AmountFormat} from "../../formatter/Formatter";
import {FireNotification} from "../../notification/Notification";

import './WithdrawalForm.css';

class WithdrawalForm extends React.Component {
    state = {
        wallet: "",
        walletError: "",
        amount: "",
        amountError: "",
        hasVerification: true,
        message: "",
        isSuccess: null,
        redirect: "",
        loader: null,
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.message && prevState.message !== this.state.message) {
            FireNotification(this.state.message, this.state.isSuccess);
            this.setState({message: ''});
        }
    };

    getAvailableWallets = (availableWallets) => {
        let map = [];
        if (!availableWallets) {
            return map;
        }

        for (let i = 0; i < availableWallets.length; i++) {
            map.push({value: availableWallets[i].id, label: availableWallets[i].name});
        }
        return map;
    };

    getWalletError = walletId => {
        if (!walletId) {
            return this.props.t('required.field.message');
        }

        let isCorrect = false;
        this.props.availableWallets.forEach((wallet) => {
            if (!isCorrect && wallet.id === walletId) {
                isCorrect = true;
            }
        });
        if (!isCorrect) {
            return this.props.t('office.account.withdrawal.form.incorrect.account.detail');
        }

        return '';
    };

    validateWallet = (value) => {
        const error = this.getWalletError(value);
        this.setState({walletError: error});

        return !error;
    };

    onChangeWallet = (data) => {
        this.setState({wallet: data});
        this.validateWallet(data.value);
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (!this.validateWallet(this.state.wallet.value) || !this.props.toWithdrawAmount) {
            return;
        }

        const defaultErr = this.props.t('something.went.wrong.error');
        this.setState({loader: Loader()});
        WithdrawalCreate({
            userAccountDetailId: this.state.wallet.value,
            amount: this.props.toWithdrawAmount,
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.result === true) {
                        e.target.reset();
                        this.setState({
                            message: this.props.t('office.account.withdrawal.success.message'),
                            isSuccess: true,
                            name: "",
                            redirect: "/office/overview",
                        });
                    } else if (response.data.details.length > 0) {
                        if (response.data.details.code === 'verification missing') {
                            this.setState({hasVerification: false});
                        } else if (response.data.details.code === 'insufficient amount limit') {
                            this.setState({
                                message: this.props.t('office.account.withdrawal.form.min.limit.message', {
                                    limit: AmountFormat(response.data.details.value, this.props.currency),
                                }),
                                isSuccess: false,
                            });
                        } else if (response.data.details.code === 'exceeded amount limit') {
                            this.setState({
                                message: this.props.t('office.account.withdrawal.form.max.limit.message', {
                                    limit: AmountFormat(response.data.details.value, this.props.currency),
                                }),
                                isSuccess: false,
                            });
                        } else if (response.data.details.code === 'insufficient funds') {
                            this.setState({
                                message: this.props.t('office.account.withdrawal.form.no.money.message', {
                                    limit: AmountFormat(response.data.details.value, this.props.currency),
                                }),
                                isSuccess: false,
                            });
                        } else {
                            this.setState({
                                message: defaultErr,
                                isSuccess: false,
                            });
                        }
                    } else {
                        this.setState({
                            message: defaultErr,
                            isSuccess: false,
                        });
                    }
                } else {
                    this.setState({
                        message: defaultErr,
                        isSuccess: false,
                    });
                }
            })
            .catch(error => {
                if (error) {
                    if (error.response?.status === 401) {
                        this.setState({redirect: "/auth/logout"})
                    }
                    this.setState({
                        message: defaultErr,
                        isSuccess: false,
                    });
                }
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    render() {
        const wallets = this.getAvailableWallets(this.props.availableWallets);
        const presetWallet = !this.state.wallet && wallets.length > 0 ? wallets[0] : this.state.wallet;

        if (presetWallet.value !== this.state.wallet.value) {
            this.setState({wallet: presetWallet});
        }

        return (
            <div className="WithdrawalForm">
                {this.state.loader && this.state.loader}
                {this.state.redirect && <Navigate to={this.state.redirect} />}

                <form
                    onSubmit={this.onSubmit}
                    autoComplete="off"
                >
                    <div className="AmountWrapper">
                        <div className="AmountSection">
                            <span>
                                {this.props.t('office.account.withdrawal.form.to.withdraw.text')}
                            </span>
                            <span>
                                {
                                    this.props.toWithdrawAmountIncludedFee
                                    && ` ${AmountFormat(this.props.toWithdrawAmountIncludedFee, this.props.currency)}`
                                }
                            </span>
                        </div>
                        <div className="FeeSection">
                            <span>
                                {
                                    this.props.fee && this.props.fee > 0
                                        ? this.props.t('office.account.withdrawal.form.included.fee.text', {
                                            amount: AmountFormat(this.props.fee, this.props.currency),
                                        }) : ''
                                }
                            </span>
                        </div>
                    </div>

                    <SelectField
                        name="wallet"
                        label={this.props.t('office.account.withdrawal.form.method.field.label')}
                        value={presetWallet}
                        error={this.state.walletError}
                        placeholder={this.props.t('office.account.withdrawal.form.method.field.placeholder')}
                        options={wallets}
                        onChange={this.onChangeWallet}
                    />
                    {wallets && wallets.length === 0 && <div className="PayoutCreateTips">
                        <span>
                            {this.props.t('office.account.withdrawal.form.payout.create.tips.text')}
                            &nbsp;
                        </span>
                        <Link to="/office/payout-method/create">
                            {this.props.t('office.account.withdrawal.form.payout.create.tips.link')}
                        </Link>
                    </div>}

                    <div className="FormButtonsWrapper">
                        <Button
                            text={this.props.t('office.system.form.submit.button')}
                            disabled={this.props.disabled}
                        />
                        <Button
                            text={this.props.t('office.system.form.cancel.button')}
                            onClick={this.props.onCancel}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(WithdrawalForm);
