import React, { useEffect, useState } from 'react';
import detectBrowserLanguage from 'detect-browser-language';
import Select from "react-select";
import i18n from '../../i18n/Translates/Translates';
import { LanguagesForSelect } from "../Languages/Languages";

import './LanguageSwitcher.css';

const storageKey = 'app-lang';

const LanguageSwitcher = () => {
    const languagesForSelect = LanguagesForSelect;
    const browserLanguage = detectBrowserLanguage();

    const defaultLanguage = languagesForSelect.find((lang) => lang.value === browserLanguage)
        ? browserLanguage
        : 'en';
    const savedLanguage = localStorage.getItem(storageKey);

    const [currentLanguage, setCurrentLanguage] = useState(
        savedLanguage && languagesForSelect.find((lang) => lang.value === savedLanguage)
            ? savedLanguage
            : defaultLanguage
    );
    localStorage.setItem(storageKey, currentLanguage);

    useEffect(() => {
        i18n.changeLanguage(currentLanguage);
    }, [currentLanguage]);

    const changeLanguage = (language) => {
        setCurrentLanguage(language.value);
        localStorage.setItem(storageKey, language.value);
    };

    const filteredOptions = languagesForSelect.filter((lang) => lang.value !== currentLanguage);

    return (
        <div className="lang-switcher">
            <Select
                options={filteredOptions}
                onChange={changeLanguage}
                value={languagesForSelect.find((lang) => lang.value === currentLanguage)}
                isSearchable={false}
                classNamePrefix="lang-select"
                components={{
                    Option: ({ innerProps, data }) => (
                        <div {...innerProps} className="my-option" style={{backgroundColor: '#1F1F1F', borderBottom: '1px solid black'}} title={data.label}>
                            <span className="flag">
                                {data.icon}
                            </span>
                            &nbsp;
                            <span className="code">
                                {data.value.toUpperCase()}
                            </span>
                        </div>
                    ),
                    SingleValue: ({ data, ...props }) => (
                        <div {...props} title={data.label}>
                            <span className="flag" title={data.label}>
                                {data.icon}
                            </span>
                            &nbsp;
                            <span className="code">
                                {data.value.toUpperCase()}
                            </span>
                        </div>
                    ),
                }}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        backgroundColor: '#1F1F1F',
                        border: 0,
                        borderRadius: '12px',
                        padding: '8px',
                        boxShadow: 'none',
                    }),
                    singleValue: () => ({
                        display: 'flex',
                        alignItems: 'center',
                        height: '24px',
                    }),
                    valueContainer: (provided) => ({
                        ...provided,
                        display: 'flex',
                        justifyContent: 'center',
                    }),
                    dropdownIndicator: () => ({
                        display: 'none',
                    }),
                    indicatorSeparator: () => ({
                        display: 'none',
                    }),
                }}
            />
        </div>
    );
};

const GetCurrentLanguage = () => {
    return localStorage.getItem(storageKey);
};

export { LanguageSwitcher, GetCurrentLanguage };
