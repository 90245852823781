import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import {FireNotification} from "../../../../components/notification/Notification";
import Loader from "../../../../components/loader/Loader";
import Button from "../../../../components/button/Button";
import PrevButton from "../../../../components/prev-button/PrevButton";
import BranchInviteList from "../../../../components/api/client/account/branch-invite/BranchInviteList";
import {MdOutlinePlace} from "react-icons/md";
import BranchInviteDecline from "../../../../components/api/client/account/branch-invite/BranchInviteDecline";
import BranchInviteAccept from "../../../../components/api/client/account/branch-invite/BranchInviteAccept";
import SystemPM from "../../../../components/system-pm/SystemPM";

import "./EmployeeInvite.css";

const perPage = 10;

let requestsState = [];

const EmployeeInvite = () => {
    const navigate = useNavigate();
    const listRequest = 'listRequest';
    const [invites, setInvites] = useState( {
        list: [],
        limit: perPage,
        offset: 0,
    });
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [message, setMessage] = useState( '');
    const [isSuccess, setIsSuccess] = useState( null);
    const [loader, setLoader] = useState(Loader());
    const { t } = useTranslation();

    const onAccept = (id) => {
        if (id) {
            setLoader(Loader());
            BranchInviteAccept(parseInt(id))
                .then((response) => {
                    if (response.status === 200 && response.data.result === true) {
                        fetch(1, true);
                        setIsSuccess(true);
                        setMessage(t('office.employee.invite.success.accepted.message'));
                    } else {
                        setIsSuccess(false);
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    }
                    setIsSuccess(false);
                    setMessage(t('something.went.wrong.error'));
                })
                .finally(() => {
                    setLoader(null);
                });
        }
    };

    const onDecline = (id) => {
        if (id) {
            setLoader(Loader());
            BranchInviteDecline(parseInt(id))
                .then((response) => {
                    if (response.status === 200 && response.data.result === true) {
                        fetch(1, true);
                        setIsSuccess(true);
                        setMessage(t('office.employee.invite.success.declined.message'));
                    } else {
                        setIsSuccess(false);
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    }
                    setIsSuccess(false);
                    setMessage(t('something.went.wrong.error'));
                })
                .finally(() => {
                    setLoader(null);
                });
        }
    };

    const fetch = async (page = null, isRefresh = false) => {
        if (!requestsState[listRequest]) {
            requestsState[listRequest] = true;
            BranchInviteList({limit: perPage, offset: (null !== page ? (perPage * (page - 1)) : (offset + perPage))})
                .then((response) => {
                    if (response.data.result.list) {
                        const newList = response.data.result.list;

                        if (isRefresh) {
                            setInvites(prevInvites => ({
                                ...prevInvites,
                                list: [...newList],
                            }));
                        } else {
                            setInvites(prevInvites => ({
                                ...prevInvites,
                                list: [...prevInvites.list, ...newList],
                            }));
                        }

                        setHasMore(newList.length === perPage);
                        setOffset(response.data.result.offset);
                    } else {
                        setMessage(t('something.went.wrong.error'));
                        setIsSuccess(false);
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    } else {
                        setMessage(t('something.went.wrong.error'));
                    }
                    setIsSuccess(false);
                })
                .finally(() => {
                    requestsState[listRequest] = false;
                });
        }
    };

    useEffect(() => {
        document.title = t('title.office.employee.invite');

        fetch(1);
        setLoader(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message) {
            FireNotification(message, isSuccess);
            setMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return (
        <div className="EmployeeInviteList">
            {loader && loader}
            <PrevButton route={`/office/qr-codes`} />
            <h1>{t('title.office.employee.invite')}</h1>
            <br/>

            <div className="EmployeeInviteListWrapper">
                <div className="EmployeeInviteListItems">
                    {invites && invites.list && (
                        <InfiniteScroll
                            dataLength={invites.list.length}
                            next={fetch}
                            hasMore={hasMore}
                            loader={<div><Loader fullScreen={false} /></div>}
                            endMessage={""}
                        >
                            <div className="EmployeeInviteListItemWrapper">
                                {invites.list.length > 0 ? (
                                    ((t) => {
                                        return (invites.list.map((invite) => {
                                            return (
                                                <div className="EmployeeInviteItem">
                                                    <div className="EmployeeInviteGeneral">
                                                        <div className="EmployeeInviteInfo">
                                                            <span className="EmployeeInviteName">
                                                                {invite.name}
                                                            </span>
                                                            <span className="EmployeeInviteAddress">
                                                                <MdOutlinePlace /> {invite.address}
                                                            </span>
                                                            {invite.isOtherBusiness && (
                                                                <div className="SystemNotifySection">
                                                                    <SystemPM
                                                                        html={`<span>${t('office.employee.invite.another.business.note.message')}</span>`}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="EmployeeInviteFooter">
                                                        <Button
                                                            text={t('office.content.control.accept.button')}
                                                            onClick={() => onAccept(invite.id)}
                                                        />
                                                        <Button
                                                            text={t('office.content.control.decline.button')}
                                                            onClick={() => onDecline(invite.id)}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }));
                                    })(t)
                                ) : (loader === null && invites.list !== 'undefined' && (
                                    <span className="NoData">
                                        {t('office.employee.invite.no.invites')}
                                    </span>
                                ))}
                            </div>
                        </InfiniteScroll>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmployeeInvite;
