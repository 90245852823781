import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdCelebration } from "react-icons/md";

import VerifyAccount from "../../../components/api/client/auth/verify-account/VerifyAccount";
import Loader from "../../../components/loader/Loader";
import Button from "../../../components/button/Button";

import './Verify.css';

let requestsState = [];
const activationCodeMinLength = 24;
const activationCodeMaxLength = 64;

const Verify = () => {
    const [message, setMessage] = useState( '');
    const [loader, setLoader] = useState(Loader());
    const { activationCode } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('title.verify');

        function redirectTo404() {
            navigate("/404");
        }

        function getValidateError(activationCode) {
            const activationCodeRegex = /^[a-z0-9]+$/

            if (
                !activationCodeRegex.test(activationCode)
                || activationCode.length < activationCodeMinLength
                || activationCode.length > activationCodeMaxLength
            ) {
                return t('verify.link.expired.message')
            }

            return "";
        }

        if (!requestsState[activationCode]) {
            requestsState[activationCode] = true;
            if (!getValidateError(activationCode)) {
                VerifyAccount({activationCode: activationCode})
                    .then((response) => {
                        if (response.status === 200 && response.data.result === true) {
                            setMessage(t('verify.success.message'));
                        } else {
                            redirectTo404();
                        }
                    })
                    .catch(error => {
                        redirectTo404();
                    })
                    .finally(() => {
                        requestsState[activationCode] = false;
                        setLoader(null);
                    });
            } else {
                redirectTo404();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onLogin = (e) => {
        e.preventDefault();

        navigate("/auth/login");
    };

    return (
        <div className="AccountVerification">
            {loader && loader}
            <h1>{t('title.verify')}</h1>
            {message && (
                <div>
                    <br/>
                    <div className="Info">
                        <MdCelebration className="Icon" />
                        <span>{message}</span>
                    </div>
                    <Button
                        text={t('verify.login.link')}
                        onClick={onLogin}
                    />
                </div>
            )}
        </div>
    );
}

export default Verify;
