import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import pako from "pako";

import Loader from "../../components/loader/Loader";
import PageView from "../../components/api/client/page/PageView";

import "./Static.css";

let requestsState = [];

const Static = (props) => {
    const pageRequest = 'pageRequest';
    const navigate = useNavigate();
    const [loader, setLoader] = useState(Loader());
    const [page, setPage] = useState('');

    useEffect(() => {
        function redirectTo404() {
            navigate("/404");
        }

        if (!requestsState[pageRequest]) {
            requestsState[pageRequest] = true;
            if (!!props.slug) {
                PageView(props.slug)
                    .then((response) => {
                        if (response.status === 200 && response.data.result) {
                            document.title = response.data.result.title;
                            setPage(response.data.result);
                        } else {
                            redirectTo404();
                        }
                    })
                    .catch(error => {
                        redirectTo404();
                    })
                    .finally(() => {
                        requestsState[pageRequest] = false;
                        setLoader(null);
                    });
            } else {
                redirectTo404();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function buildHtml(): string {
        if (!page || !page.content) {
            return '';
        }

        const compressedHtml = atob(page.content);
        const compressedArray = new Uint8Array([...compressedHtml].map(c => c.charCodeAt(0)));

        return pako.ungzip(compressedArray, { to: 'string' });
    }

    return (
        <div className="StaticPage">
            {loader && loader}
            <div className="StaticPageWrapper">
                <h1>{page && page.title}</h1>
                <div className="StaticPageContent" dangerouslySetInnerHTML={{ __html: buildHtml() }}>
                </div>
            </div>
        </div>
    );
};

export default Static;
