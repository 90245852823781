import {Checkbox as MaterialCheckbox, FormControlLabel as MaterialFormControlLabel} from '@mui/material';

import "./Checkbox.css";

const Checkbox = (props) => {
    return (
        <div className="CheckboxField">
            <MaterialFormControlLabel
                control={<MaterialCheckbox checked={props.checked} />}
                label={props.label}
                onChange={props.onChange}
                sx={{
                    '& .MuiCheckbox-root': {
                        color: 'grey',
                    },
                    '& .MuiCheckbox-root.Mui-checked': {
                        color: '#DBFC08',
                    },
                }}
            />
            {props.error && <div className="CheckboxErrorWrapper">
                <span>{props.error}</span>
            </div>}
        </div>
    );
};

export default Checkbox;
