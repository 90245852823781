import React from 'react';
import { Navigate } from "react-router";

import { GetAuthToken } from "../../storages/auth-token-storage/AuthTokenStorage";

const RouteGuard = ({element: Element}) => (
    !!GetAuthToken() ? <Element /> : <Navigate to='/auth/login' replace />
);

const RouteCommon = ({element: Element}) => (
    !!GetAuthToken() ? <Navigate to='/office/overview' replace/> : <Element/>
);

export { RouteGuard, RouteCommon };
