import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import ForgotPasswordForm from "../../../components/form/forgot-password-form/ForgotPasswordForm";

const ForgotPassword = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('title.forgot.password');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h1>{t('title.forgot.password')}</h1>
            <br/>
            <ForgotPasswordForm />
        </div>
    );
}

export default ForgotPassword;
