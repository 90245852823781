import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

import PayForm from "../../components/form/pay-form/PayForm";
import LandingView from "../../components/api/client/landing/LandingView";
import Loader from "../../components/loader/Loader";
import LandingHeaderTemplate from "../../components/template/landing-header/LandingHeaderTemplate";
import {FireNotification} from "../../components/notification/Notification";

import "./Pay.css";

let requestsState = [];

const uuidLength = 36;

const Pay = () => {
    const landingRequest = 'landingRequest';
    const navigate = useNavigate();
    const { uuid } = useParams();
    const [message, setMessage] = useState( '');
    const [landing, setLanding] = useState( null);
    const [loader, setLoader] = useState(Loader());
    const { t } = useTranslation();

    useEffect(() => {
        function redirectTo404() {
            navigate("/404");
        }

        function getLandingUuidError() {
            const uuidRegex = /^[a-zA-Z0-9-]+$/

            if (!uuidRegex.test(uuid) || uuid.length !== uuidLength) {
                return t('incorrect.landing.pay');
            }

            return "";
        }

        if (!requestsState[landingRequest]) {
            requestsState[landingRequest] = true;

            if (getLandingUuidError()) {
                redirectTo404();
            }

            LandingView(uuid)
                .then((response) => {
                    if (
                        response.status === 200
                        && !!response.data.result
                        && response.data.result.paymentMethods.length !== 0
                    ) {
                        setLanding(response.data.result);
                        document.title = t('title.landing.pay', {name: response.data.result.name});
                    } else {
                        redirectTo404();
                    }
                })
                .catch(error => {
                    if (error.response?.status === 404) {
                        redirectTo404();
                    } else {
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .finally(() => {
                    requestsState[landingRequest] = false;
                    setLoader(null);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message) {
            FireNotification(message, false);
            setMessage('');
        }
    }, [message]);

    return (
        <div className="PaySection">
            {loader && loader}
            <LandingHeaderTemplate
                name={!!landing && landing.name}
                subName={!!landing && landing.subName}
                logo={!!landing && landing.logo}
            />
            <br/>
            <PayForm {...landing} />
        </div>
    );
};

export default Pay;
