import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";

let lang = english;
let locale;

const getCountries = () => {
    if (!locale) {
        countries.registerLocale(lang);
        locale = lang.locale;
    }
    return countries;
};

export const GetCountryName = (countryCode) => {
    return getCountries().getName(countryCode, locale);
};
