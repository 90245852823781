import { MdInfoOutline } from "react-icons/md";

import './SystemPM.css';

const SystemPM = ({ html }) => {
    if (!html) {
        return '';
    }

    return (
        <div className="SystemPmContainer">
            <div className="SystemPmWrapper">
                <div className="SystemPmIcon">
                    <MdInfoOutline/>
                </div>
                <div className="SystemPmContent" dangerouslySetInnerHTML={{__html: html}}/>
            </div>
        </div>
    );
};

export default SystemPM;
