import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

import QrCodeForm from "../../../../components/form/qr-code-form/QrCodeForm";

const QrCodeCreate = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('title.office.qr.code.create');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCancel = () => {
        navigate('/office/qr-codes');
    };

    return (
        <div>
            <h1>{t('office.qr.code.create.subtitle')}</h1>
            <br/>
            <QrCodeForm onCancel={onCancel} />
        </div>
    );
};

export default QrCodeCreate;
