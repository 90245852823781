import Client from "../../Client";
import {GetAuthToken} from "../../../../storages/auth-token-storage/AuthTokenStorage";

const BranchInviteAccept = (id) => {
    return Client(GetAuthToken()).post(
        `/account/branch-invite/accept`,
        {
            inviteId: id,
        },
    );
}

export default BranchInviteAccept;
