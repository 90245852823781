import Client from "../../Client";
import {GetAuthToken} from "../../../../storages/auth-token-storage/AuthTokenStorage";

const VerificationCreate = ({name, country, data, documents}) => {
    return Client(GetAuthToken()).post(
        '/account/verification/create',
        {
            name: name,
            country: country,
            data: data,
            files: documents,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        },
    );
}

export default VerificationCreate;
