import Client from "../../Client";
import {GetAuthToken} from "../../../../storages/auth-token-storage/AuthTokenStorage";

const BranchUpdate = ({id, name, publicName, address = null, type = null}) => {
    return Client(GetAuthToken()).post(
        `/account/branch/update/${id}`,
        {
            name: name,
            publicName: publicName,
            address: address,
            type: type,
        },
    );
}

export default BranchUpdate;
