import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {useTranslation} from "react-i18next";
import {MdInfoOutline, MdOutlineSouthEast, MdPersonOutline} from "react-icons/md";
import {IconButton, Tooltip} from "@mui/material";

import Loader from "../../../../components/loader/Loader";
import PaymentList from "../../../../components/api/client/account/payment/PaymentList";
import {FireNotification} from "../../../../components/notification/Notification";
import {AmountFormat} from "../../../../components/formatter/Formatter";
import {GetCurrentLanguage} from "../../../../components/i18n/LanguageSwitcher/LanguageSwitcher";

import "./TransactionList.css";

const perPage = 10;

let requestsState = [];

const IncomeList = () => {
    const navigate = useNavigate();
    const paymentsRequest = 'paymentsRequest';
    const [title, setTitle] = useState('Income transactions');
    const [loader, setLoader] = useState(Loader({
        fullScreen: false,
        targetClass: 'IncomeWrapper',
    }));
    const [payments, setPayments] = useState({
        transactions: [],
        limit: perPage,
        offset: 0,
    });
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [message, setMessage] = useState('');
    const { t } = useTranslation();

    const fetch = async (page = null) => {
        if (!requestsState[paymentsRequest]) {
            requestsState[paymentsRequest] = true;
            PaymentList({limit: perPage, offset: (null !== page ? (perPage * (page - 1)) : (offset + perPage))})
                .then((response) => {
                    if (response.data.list) {
                        const newTransactions = response.data.list.transactions;

                        setPayments(prevPayments => ({
                            ...prevPayments,
                            transactions: [...prevPayments.transactions, ...newTransactions],
                        }));
                        setHasMore(newTransactions.length === perPage);
                        setOffset(response.data.list.offset);
                    } else {
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        navigate("/auth/logout");
                    } else {
                        setMessage(t('something.went.wrong.error'));
                    }
                })
                .finally(() => {
                    requestsState[paymentsRequest] = false;
                });
        }
    };

    useEffect(() => {
        setTitle(t('office.account.income.subtitle'));

        fetch(1);
        setLoader(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message) {
            FireNotification(message, false);
            setMessage('');
        }
    }, [message]);

    return (
        <div className="IncomeWrapper TransactionWrapper">
            {loader && loader}
            <h2 className="TabTitle">{title}</h2>
            <br/>
            {payments && payments.transactions && (
                <InfiniteScroll
                    dataLength={payments.transactions.length}
                    next={fetch}
                    hasMore={hasMore}
                    loader={<div><Loader fullScreen={false} /></div>}
                    endMessage={""}
                >
                    <div>
                        <div className="TransactionList">
                            {payments.transactions.length > 0 ? (
                                ((t) => {
                                    let lastDate = null;
                                    let currentLang = GetCurrentLanguage();
                                    return payments.transactions.map((transaction, index) => {
                                        const transactionDate = new Date(transaction.executedAt * 1000);
                                        const formattedDate = transactionDate.toLocaleDateString(currentLang);
                                        const showDate = formattedDate !== lastDate;
                                        lastDate = formattedDate;

                                        return (
                                            <>
                                                {showDate && (
                                                    <div className="DateHeader">
                                                        <div className="DateContent">
                                                            {formattedDate}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="TransactionItem">
                                                    {transaction.owner && (<div className="TransactionOwner">
                                                        <span className="TransactionOwnerLabel">
                                                            <MdPersonOutline />{transaction.owner.email}
                                                        </span>
                                                    </div>)}
                                                    <div className="TransactionGeneral">
                                                        <div className="TransactionDir">
                                                            {<MdOutlineSouthEast />}
                                                        </div>
                                                        <div className="TransactionInfo">
                                                            <span className="TransactionTitle">
                                                                {
                                                                    transaction.isTips
                                                                        ? t('office.account.income.type.tooltip')
                                                                        : t('office.account.income.type.other')
                                                                }
                                                            </span>
                                                            <span className="TransactionStatus">
                                                                {transaction.isPending && t('office.account.income.status.pending')}
                                                                {transaction.isDone && t('office.account.income.status.done')}
                                                                {transaction.isWaitConfirmation && t('office.account.income.status.wait.confirmation')}
                                                            </span>
                                                            <span className="TransactionDate">
                                                                {transactionDate.toLocaleTimeString(currentLang)}
                                                            </span>
                                                        </div>
                                                        <div className="TransactionTotal">
                                                            <span className="TransactionAmount">
                                                                {AmountFormat(transaction.amount, transaction.currency)}
                                                            </span>
                                                            <span className="TransactionFee">
                                                                {<Tooltip
                                                                    className="FeeTooltip"
                                                                    title={t('office.account.income.fee.tooltip')}
                                                                    enterTouchDelay={0}
                                                                    arrow={true}
                                                                    placement={'bottom-start'}
                                                                >
                                                                    <IconButton style={{
                                                                        padding: '0',
                                                                        marginTop: '2px',
                                                                        verticalAlign: 'top'
                                                                    }}>
                                                                        <MdInfoOutline/>
                                                                    </IconButton>
                                                                    <span className="FeeAmount">
                                                                        {AmountFormat(transaction.fee, transaction.currency)}
                                                                    </span>
                                                                </Tooltip>}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="TransactionMeta">
                                                        {<Tooltip
                                                            title={t('office.account.income.id.tooltip')}
                                                            enterTouchDelay={0}
                                                            arrow={true}
                                                            placement={'bottom-start'}
                                                        >
                                                            <span className="TransactionIdentifier">
                                                                {transaction.id}
                                                            </span>
                                                            <IconButton style={{padding: '0', verticalAlign: 'top'}}>
                                                                <MdInfoOutline />
                                                            </IconButton>
                                                        </Tooltip>}
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    });
                                })(t)
                            ) : (loader === null && payments.transactions.length === 0 && !hasMore && (
                                <span className="NoData">
                                    {t('office.dashboard.no.transactions')}
                                </span>
                            ))}
                        </div>
                    </div>
                </InfiniteScroll>
            )}
        </div>
    );
};

export default IncomeList;
