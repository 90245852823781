import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import LoginForm from "../../../components/form/login-form/LoginForm";

const Login = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('title.login');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h1>{t('title.login')}</h1>
            <br/>
            <LoginForm />
        </div>
    );
}

export default Login;
