import React from "react";
import {useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";

import TextField from "../field/text-field/TextField";
import Loader from "../../loader/Loader";
import ProfileUpdate from "../../api/client/account/profile/ProfileUpdate";
import Button from "../../button/Button";
import {FireNotification} from "../../notification/Notification";

import './SettingsForm.css';

const fullNameMaxLength= 100;
const contactFullNameMaxLength = 100;
const contactMaxLength = 50;
const websiteMaxLength = 100;

function NavigateTo(url) {
    const navigate = useNavigate();
    navigate(url);
}

class SettingsForm extends React.Component {
    state = {
        fullName: this.props.profile.fullName ?? '',
        fullNameError: '',
        contactFullName: this.props.profile.fullName ?? '',
        contactFullNameError: '',
        contact: this.props.profile.contact ?? '',
        contactError: '',
        website: this.props.profile.website ?? '',
        websiteError: '',
        message: '',
        loader: null,
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.profile !== this.props.profile) {
            this.setState({
                fullName: this.props.profile.fullName ?? '',
                contactFullName: this.props.profile.contactFullName ?? '',
                contact: this.props.profile.contact ?? '',
                website: this.props.profile.website ?? '',
            });
        }

        if (this.state.message && prevState.message !== this.state.message) {
            FireNotification(this.state.message, false);
            this.setState({message: ''});
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (
            !this.validateContact(this.state.contact)
            || !this.validateWebsite(this.state.website)
            || !this.validateFullName(this.state.fullName)
            || !this.validateContactFullName(this.state.contactFullName)
        ) {
            return;
        }

        this.setState({loader: Loader()});
        ProfileUpdate({
            contact: this.state.contact,
            contactFullName: this.state.contactFullName,
            website: this.state.website,
            fullName: this.state.fullName,
        })
            .then((response) => {
                if (response.status === 200 && response.data.result === true) {
                    e.target.reset();
                    FireNotification(this.props.t('office.system.form.success.saved.message'))
                    this.setState({message: ''});
                } else {
                    this.setState({message: this.props.t('something.went.wrong.error')});
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    NavigateTo('/auth/logout');
                }
                this.setState({message: this.props.t('something.went.wrong.error')});
            })
            .finally(() => {
                this.setState({loader: null});
            });
    };

    onChangeFullName = (event) => {
        this.setState({fullName: event.target.value})
        this.validateFullName(event.target.value);
    };

    onChangeContactFullName = (event) => {
        this.setState({contactFullName: event.target.value})
        this.validateContactFullName(event.target.value);
    };

    onChangeContact = (event) => {
        this.setState({contact: event.target.value})
        this.validateContact(event.target.value);
    };

    onChangeWebsite = (event) => {
        this.setState({website: event.target.value})
        this.validateWebsite(event.target.value);
    };

    validateFullName = (value) => {
        const error = this.getFullNameError(value);
        this.setState({fullNameError: error});

        return !error;
    };

    validateContactFullName = (value) => {
        const error = this.getContactFullNameError(value);
        this.setState({contactFullNameError: error});

        return !error;
    };

    validateContact = (value) => {
        const error = this.getContactError(value);
        this.setState({contactError: error});

        return !error;
    };

    validateWebsite = (value) => {
        const error = this.getWebsiteError(value);
        this.setState({websiteError: error});

        return !error;
    };

    getFullNameError = fullName => {
        if (fullName.length === 0) {
            return '';
        }

        const valueRegex = /^[A-Za-z'\-.,() ]+?$/;
        if (!valueRegex.test(fullName)) {
            return this.props.t('office.settings.form.invalid.fullName');
        }

        if (fullName.length > fullNameMaxLength) {
            return this.props.t('office.settings.form.fullName.max.length.field.message', {
                maxLength: fullNameMaxLength,
            });
        }

        return '';
    };

    getContactFullNameError = contactFullName => {
        if (contactFullName.length === 0) {
            return '';
        }

        const valueRegex = /^[A-Za-z'\-.,() ]+?$/;
        if (!valueRegex.test(contactFullName)) {
            return this.props.t('office.settings.form.invalid.contactFullName');
        }

        if (contactFullName.length > contactFullNameMaxLength) {
            return this.props.t('office.settings.form.contactFullName.max.length.field.message', {
                maxLength: contactFullNameMaxLength,
            });
        }

        return '';
    };

    getContactError = contact => {
        if (contact.length === 0) {
            return '';
        }

        const contactRegex = /^[a-zA-Z0-9-.+() ]+?$/;
        if (!contactRegex.test(contact)) {
            return this.props.t('office.settings.form.invalid.contact');
        }

        if (contact.length > contactMaxLength) {
            return this.props.t('office.settings.form.contact.max.length.field.message', {
                maxLength: contactMaxLength,
            });
        }

        return '';
    };

    getWebsiteError = website => {
        if (website.length === 0) {
            return '';
        }

        const websiteRegex = /^(https?:\/\/)?([a-zA-Z\d-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z\d#]+\/?)*$/;
        if (!websiteRegex.test(website)) {
            return this.props.t('office.settings.form.invalid.website');
        }

        if (website.length > websiteMaxLength) {
            return this.props.t('office.settings.form.website.max.length.field.message', {
                maxLength: websiteMaxLength,
            });
        }

        return '';
    };

    render() {
        return (
            <div className="ProfileForm">
                {this.state.loader && this.state.loader}

                <form
                    onSubmit={this.onSubmit}
                    autoComplete="off"
                >
                    {this.props.profile && this.props.profile.isEmployeeAccount && (
                        <TextField
                            name="fullName"
                            label={this.props.t('office.settings.form.fullName.field.label')}
                            value={this.state.fullName}
                            error={this.state.fullNameError}
                            onChange={this.onChangeFullName}
                        />
                    )}
                    {this.props.profile && this.props.profile.isBusinessAccount && (
                        <TextField
                            name="contactFullName"
                            label={this.props.t('office.settings.form.contactFullName.field.label')}
                            value={this.state.contactFullName}
                            error={this.state.contactFullNameError}
                            onChange={this.onChangeContactFullName}
                        />
                    )}
                    <TextField
                        name="contact"
                        label={this.props.t('office.settings.form.contact.field.label')}
                        value={this.state.contact}
                        error={this.state.contactError}
                        onChange={this.onChangeContact}
                    />
                    {this.props.profile && this.props.profile.isBusinessAccount && (
                        <TextField
                            name="website"
                            label={this.props.t('office.settings.form.website.field.label')}
                            value={this.state.website}
                            error={this.state.websiteError}
                            onChange={this.onChangeWebsite}
                        />
                    )}
                    <Button text={this.props.t('office.system.form.submit.button')}/>
                </form>
            </div>
        );
    }
}

export default withTranslation()(SettingsForm);
