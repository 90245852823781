import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {useTranslation} from "react-i18next";

import { GetAuthToken } from "../storages/auth-token-storage/AuthTokenStorage";
import { LanguageSwitcher } from "../i18n/LanguageSwitcher/LanguageSwitcher";

import './Navigation.css';

const Navigation = (props) => {
    const [openState, setOpenState] = useState( false);
    const [menuVisibleState, setMenuVisibleState] = useState( true);
    const [menuList, setMenuList] = useState( []);
    const menuRef = useRef(null);
    const location = useLocation();
    const { t } = useTranslation();

    const onBurgerClick = e => {
        e.preventDefault();
        setOpenState(!openState);
    };

    const closeMenu = e => {
        if (menuRef.current && menuRef.current.contains(e.target)) {
            return;
        }
        setOpenState(false);
    };

    const onLinkClick = e => {
        setOpenState(false);
    };

    const updateMenuList = () => {
        const actualMenuList = !!GetAuthToken() ? props.authorizedContent : props.guestContent;
        if (menuList !== actualMenuList) {
            setMenuList(!!GetAuthToken() ? props.authorizedContent : props.guestContent);
        }
    };

    const updateMenuVisible = () => {
        let result = menuVisibleState;
        let newState = true;
        if (!!props.excludeRoutesRegex) {
            props.excludeRoutesRegex.forEach((routeRegex) => {
                if (routeRegex.test(location.pathname)) {
                    newState = false;
                }
            });

            if (menuVisibleState !== newState) {
                setMenuVisibleState(result = newState);
            }
        }

        return result;
    };

    useEffect(() => {
        document.addEventListener("mousedown", closeMenu);
        return () => document.removeEventListener("mousedown", closeMenu);
    }, []);

    updateMenuVisible() && updateMenuList();

    if (!menuVisibleState) {
        return;
    }

    return (
        <nav>
            <div className="NavbarControl">
                <button onClick={onBurgerClick}>
                    <div className="NavbarControlLines" />
                </button>
            </div>

            <div
                className={`NavbarWrapper${openState ? ' open' : ''}`}
                ref={menuRef}
            >
                <ul>
                    <li className="NavLangSwitcher">
                        <LanguageSwitcher />
                    </li>
                    {!!menuList && menuList.map((item) => (
                        <li>
                            <NavLink
                                to={item.route}
                                onClick={onLinkClick}
                            >
                                {t(item.text)}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    );
};

export default Navigation;
